<template>
  <v-row class="no-gutters elevation-0">
    <v-col
        cols="auto"
        class="flex-grow-1 flex-shrink-0"
    >
      <v-responsive class="overflow-y-hidden fill-height">
        <v-card
            elevation="0"
            class="d-flex flex-column fill-height message-card-chat rounded-0"
            color="transparent"
            height="calc(100vh - 120px)"
        >
          <v-card-text
              id="messages"
              ref="messages"
              class="flex-grow-1 overflow-y-auto pb-2 pr-1 pt-2"
              :class="{'elevation-top': offsetTop !== 0}"
          >
            <div v-for="(msg, index) in messages" :key="index" v-scroll:#messages="onScroll" class="pr-1">
              <div :class="{'d-flex justify-end': isMyMessage(msg.createdByUserId) }">
                <div class="mt-4"
                     :class="{'mr-10': !isMyMessage(msg.createdByUserId),
                              'ml-10': isMyMessage(msg.createdByUserId)}"
                >
                  <v-card
                      elevation="0"
                      :color="isMyMessage(msg.createdByUserId) ? 'primary' : 'white'"
                      dark
                      style="height:auto; white-space: normal;"
                      class="pa-0 rounded-xl"
                      width="fit-content"
                  >
                    <v-card-text class="pa-2 pl-3 pr-3 with-linebreak"
                                 :class="{'primary--text': !isMyMessage(msg.createdByUserId) }"
                    >
                      {{ msg.message }}
                      <div v-for="(file, index) in msg.files" :key="index" class="mt-2">
                        <div class="mb-1"
                             :class="{'primary--text': !isMyMessage(msg.createdByUserId) }"
                        >
                          <span class="text-decoration-underline ">{{ file.name }}</span>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                  <div class="ml-4 "
                       style="font-size: 0.69rem;"
                       :class="{
                         'text-right': isMyMessage(msg.createdByUserId),
                         'text-left': isMyMessage(msg.createdByUserId)
                       }"
                  >
                    {{ getFormattedDate(msg.time) }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <div class="d-flex justify-start align-center">
            <v-card class="mr-1" width="100%">
              <v-card-text class="flex-shrink-1 pa-0">
                <div class="d-flex justify-space-between ma-0 mr-2">
                  <v-textarea
                      id="chat-message-type-textarea"
                      v-model="messageForm.message"
                      :label="$t('allPrivatePages.messages.chat.writeCommentHere')"
                      :rules="rules.contentShouldNotBeLonger"
                      style="max-height: 115px !important; overflow-y: auto !important;"
                      :counter="200"
                      class="mt-1 mb-1"
                      solo
                      flat
                      auto-grow
                      autofocus
                      rows="1"
                      @keydown.enter="addMessage()"
                  />

                  <div class="d-flex align-end">
                    <div class="mb-3 c-point" @click="addMessage()">
                      <v-icon color="icons" size="25">mdi-send</v-icon>
                    </div>
                  </div>
                </div>
              </v-card-text>

              <div class="d-flex align-start ml-2 flex-wrap" :class="{'d-none': files.length > 0}">
                <v-chip
                    v-for="(file, index) in files"
                    :key="index"
                    close
                    close-icon="mdi-delete"
                    class="mb-1 mr-1"
                    filter
                    style="min-width: 100px"
                    pill
                    @click:close="removeFromFiles(index)"
                >
                  {{ file.name }}
                </v-chip>
              </div>
            </v-card>
            <v-btn fab class="c-point" color="primary" elevation="0" small @click="$refs.fileInput.click()">
              <v-icon color="white" size="25">mdi-paperclip</v-icon>
            </v-btn>
            <input ref="fileInput" type="file" multiple class="d-none" :accept="allowedExtensions()" @change="setFiles">
          </div>
        </v-card>
      </v-responsive>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {contentShouldNotBeLonger} from '@/utils/rules/inputRules';
import {allowedExtensions} from '@/utils/fileCategory';
import {scrollBottomToId} from '@/utils/scrollUtils';

export default {
  name: 'ChatMessages',
  data() {
    return {
      rules: {
        contentShouldNotBeLonger
      },
      offsetTop: 0,
      activeChat: 1,
      messages: [],
      files: [],
      messageForm: {
        createdByUserId: '',
        message: '',
        time: new Date()
      }
    };
  },
  computed: {
    ...mapGetters('authCase', ['getCaseNumber', 'getCasePayload', 'getCaseMessages']),
  },
  watch: {
    'getCaseMessages': {
      handler() {
        this.messages = [...this.getCaseMessages];
        this.scrollBottom();
      },
      deep: true,
    },
  },
  mounted() {
    this.scrollBottom();
    this.retrieveCaseMessages();
  },
  methods: {
    ...mapActions('authCase', ['sendMessage', 'retrieveCaseMessages']),

    getFormattedDate(created) {
      if (created) {
        return this.$moment(created).fromNow();
      }
    },

    isMyMessage(createdByUserId) {
      return createdByUserId === this.getCaseNumber;
    },

    resetFrom() {
      this.messageForm = {
        createdByUserId: '',
        message: '',
        type: 'STANDARD',
        time: new Date()
      };
      this.files = [];
    },

    setFiles(event) {
      this.files = Object.values(event.target.files).slice(0, 5);
    },
    allowedExtensions,
    addMessage() {
      this.messageForm.message = this.messageForm.message.trim();
      if (this.messageForm.message !== '' || this.files.length > 0) {
        this.messageForm.createdByUserId = this.getCaseNumber;

        this.sendMessage({message: this.messageForm, files: this.files});
        this.messages.push({...this.messageForm, files: this.files});
        this.resetFrom();
        this.scrollBottom();
      }
    },

    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },

    scrollBottom() {
      this.$nextTick(() => scrollBottomToId('messages'));
    },

    removeFromFiles(index) {
      this.files.splice(index, 1);
    }
  }
};
</script>

<style scoped lang="scss">

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #44588BFF;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #44588BFF;
}

.elevation-top {
  box-shadow: inset 0 13px 14px -17px rgb(50, 50, 50, 0.75);
}

</style>
