<template>
  <div class="mt-15">
    <DataWillBeDiscarded
        :discard-users-dialog="discardChangesDialog"
        @cancelUserDiscard="cancelUserDiscard()"
        @confirmUserDiscard="confirmUserDiscard()"
    />
    <v-card
        id="stepFromReportRelated"
        class="mx-auto card-border-bottom"
        min-height="270px"
        max-width="100%"
        rounded
        elevation="2"
    >
      <div class="card-inner-padding">
        <div>
          <slot name="card-title"/>
        </div>
        <v-row class="d-flex justify-space-between mt-0">
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-card
                outlined
                width="100%"
                min-height="70px"
                max-height="85px"
                :class="{'border-all-2 selected-option': (hasWhistleblowerChooseAnOption && !isCompanySelected)}"
                elevation="0"
                @click="changeSelectedReportedEntity(false)"
            >
              <div class="d-flex justify-center mt-3">
                <div class="d-flex">
                  <v-icon size="33" class="icon-select-option" color="icons">mdi-account-group</v-icon>
                  <div class="d-flex justify-center align-center">
                    <div class="pa-3 text-report-related word-brake">
                      <div class="d-flex text-center justify-center">
                        <span>{{ $t('step.reportRelated.selectPersonsInCompany') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" lg="6" md="6" sm="12">
            <v-card
                outlined
                width="100%"
                min-height="70px"
                max-height="85px"
                elevation="0"
                :class="{'border-all-2 selected-option': (hasWhistleblowerChooseAnOption && isCompanySelected)}"
                @click="changeSelectedReportedEntity(true)"
            >
              <div class="d-flex justify-center mt-3">
                <div class="d-flex">
                  <div class="d-flex justify-center align-center">
                    <v-icon size="33" class="icon-select-option" color="icons">mdi-office-building</v-icon>
                    <div class="pa-3 text-report-related word-brake">
                      <div class="d-flex text-center justify-center">
                        <span>{{ $t('step.reportRelated.company') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <div v-if="hasWhistleblowerChooseAnOption">
          <div v-if="!isCompanySelected">
            <v-row class="mt-6">
              <v-card
                  v-for="(item, index) in reportedPersons" :key="index"
                  outlined
                  width="100%"
                  class="ma-3 pa-2 mb-2 border-all-1"
              >
                <div class="d-flex justify-space-between align-center">
                  <div>
                    {{ getFormattedUserReported(item) }}
                  </div>
                  <div>
                    <v-btn icon color="primary" @click="removeReportedPerson(index)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-row>
            <div>
              <v-row class="d-flex justify-space-between mt-7">
                <v-col cols="12" lg="6" xl="6" md="12" sm="12">
                  <v-text-field
                      v-model="person.name"
                      :placeholder="$t('step.reportRelated.firstName')"
                      :error="displayFromValidationError && isFalsy(person.name)"
                      :rules="rules.fieldRequiredRules"
                      outlined
                      @input="setDefaultUser()"
                  />
                </v-col>
                <v-col cols="12" lg="6" xl="6" md="12" sm="12">
                  <v-text-field
                      v-model="person.jobTitle"
                      :placeholder="$t('step.reportRelated.position')"
                      :error="displayFromValidationError && isFalsy(person.jobTitle)"
                      :rules="rules.fieldRequiredRules"
                      validate-on-blur
                      outlined
                      @input="setDefaultUser()"
                  />
                </v-col>
              </v-row>

              <v-row class="d-flex justify-space-between mt-0">
                <v-col cols="12" lg="6" xl="6" md="12" sm="12">
                  <v-text-field
                      v-model="person.phoneOrEmail"
                      :placeholder="$t('step.reportRelated.phoneNrOrEmail')"
                      :error="displayFromValidationError && isFalsy(person.phoneOrEmail)"
                      :rules="rules.fieldRequiredRules"
                      outlined
                      @input="setDefaultUser()"
                  />
                </v-col>
                <v-col cols="12" lg="6" xl="6" md="12" sm="12">
                  <v-btn
                      color="primary"
                      class="text-none d-flex justify-start"
                      width="100%"
                      height="55px"
                      :disabled="enableAddNewPersons"
                      @click="addNewPerson()"
                  >
                    <v-icon large>mdi-plus</v-icon>
                    <span class="pl-2">{{ $t('step.reportRelated.addNewPerson') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-else>
            <v-row class="d-flex justify-end mt-7">
              <v-col cols="12" lg="6" xl="6" md="12" sm="12">
                <v-text-field
                    v-model="reportedUnit"
                    :placeholder="$t('step.reportRelated.unit')"
                    outlined
                    @input="emitReportRelatedUnit()"
                />
              </v-col>
            </v-row>
          </div>
        </div>

        <div v-if="showErrorMessageMustSelectAnOption" class="pt-6 d-flex justify-center">
          <div>
            <span class="error--text">{{ $t('step.reportRelated.youNeedToSelectAnOption') }}</span>
          </div>
        </div>
        <StepInformationFooter :text="$t('tooltips.reportRelated.text')"/>
      </div>
    </v-card>
  </div>
</template>

<script>
import StepInformationFooter from '@/components/tooltip/StepInformationTooltip';
import {fieldPhoneNumberOrEmail, fieldRequiredRules} from '@/utils/rules/inputRules';
import {mapGetters, mapMutations} from 'vuex';
import {isFalsy, isNotFalsy} from '@/utils/guards';
import DataWillBeDiscarded from '@/components/modal/DataWillBeDiscarded';

export default {
  name: 'StepReportRelated',
  props: {
    persons: {
      type: Array,
      default: null,
    },
    unit: {
      type: String,
      default: null,
    }
  },
  components: {
    DataWillBeDiscarded,
    StepInformationFooter
  },
  data() {
    return {
      rules: {fieldRequiredRules, fieldPhoneNumberOrEmail},
      discardChangesDialog: false,
      isCompanySelected: null,
      validFieldPhoneNrEmail: {
        key: 1,
        showError: false,
      },
      person: {name: null, phoneOrEmail: null, jobTitle: null},
      reportedUnit: null,
      reportedPersons: [],
      temporaryWrittenReportedPersons: [],
    };
  },
  computed: {
    ...mapGetters('onlineForm', ['getShowFormValidationErrorDialog', 'getFormValidation']),
    displayFromValidationError() {
      if (this.getShowFormValidationErrorDialog) {
        return !this.getFormValidation.stepFromReportRelated.isValid;
      }
      return false;
    },
    hasUserTypedAPerson() {
      return isNotFalsy(this.person?.name);
    },
    enableAddNewPersons() {
      return isFalsy(this.person.name);
    },
    hasWhistleblowerChooseAnOption() {
      return this.isCompanySelected !== null;
    },
    showErrorMessageMustSelectAnOption() {
      return this.displayFromValidationError && this.isCompanySelected === null;
    }
  },

  watch: {
    'person': {
      handler() {
        let isValid = this.reportedPersons.length > 0 || this.hasUserTypedAPerson;
        this.setValidFromStep({validStep: 'stepFromReportRelated', isValid});
      },
      deep: true,
    },
    'isCompanySelected': {
      handler() {
        if (this.isCompanySelected) {
          this.setValidFromStep({validStep: 'stepFromReportRelated', isValid: true});
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initReportedPersons();
  },
  methods: {
    ...mapMutations('onlineForm', ['setValidFromStep']),
    initReportedPersons() {
      if (isNotFalsy(this.unit)) {
        this.reportedUnit = this.unit;
        this.changeSelectedReportedEntity(true);
      }

      if (this.persons?.length > 0) {
        this.reportedPersons = this.persons;
        this.changeSelectedReportedEntity(false);
      }
    },

    setDefaultUser() {
      if (this.reportedPersons.length === 0) {
        this.temporaryWrittenReportedPersons[0] = this.person;
        this.emitChangesReportedPerson(this.temporaryWrittenReportedPersons);
      } else {
        this.emitChangesReportedPerson(this.reportedPersons);
      }
    },

    changeSelectedReportedEntity(value) {
      this.isCompanySelected = value;
      if (this.isCompanySelected) {
        this.checkFromEnteredInfoPersonRelated();
      } else {
        this.discardChangesDialog = isNotFalsy(this.reportedUnit);
      }
    },
    checkFromEnteredInfoPersonRelated() {
      let hasUserEnterMessage = this.reportedPersons.length > 0 || this.hasUserTypedAPerson;
      this.discardChangesDialog = (this.isCompanySelected && hasUserEnterMessage);

      if (!this.hasUserTypedAPerson) {
        this.discardChanges();
      }
    },
    removeReportedPerson(index) {
      this.reportedPersons.splice(index, 1);
      this.emitChangesReportedPerson(this.reportedPersons);
    },
    addNewPerson() {
      if (this.hasUserTypedAPerson) {
        this.reportedPersons.push(this.person);
        this.resetUserModel();
        this.emitChangesReportedPerson(this.reportedPersons);
      }
    },

    confirmUserDiscard() {
      this.discardChangesDialog = false;
      if (this.isCompanySelected) {
        this.discardChanges();
      } else {
        this.reportedUnit = null;
        this.emitReportRelatedUnit();
      }
    },
    discardChanges() {
      this.reportedPersons = [];
      this.temporaryWrittenReportedPersons = [];
      this.resetUserModel();
      this.emitChangesReportedPerson([]);
    },
    cancelUserDiscard() {
      this.discardChangesDialog = false;
      this.isCompanySelected = !this.isCompanySelected;
    },
    resetUserModel() {
      this.person = {name: null, phoneOrEmail: null, jobTitle: null};
    },

    emitChangesReportedPerson(reportedPersons) {
      this.$root.$emit('ReportedPersons', reportedPersons);
    },

    getFormattedUserReported(userReported) {
      let tempArr = [];
      Object.keys(userReported).forEach(key => {
        if (isNotFalsy(userReported[key])) {
          tempArr.push(userReported[key]);
        }
      });
      return tempArr.join(', ');
    },

    emitReportRelatedUnit() {
      this.$root.$emit('ReportedUnit', this.reportedUnit);
    },
    isFalsy
  }
};
</script>

<style scoped></style>
