import {fileSize} from '@/utils/fileSize';

export const DOCUMENT = [
    'docx',
    'csv',
    'pdf',
    'txt',
    'xlsx',
    'xls',
];

export const PICTURES = [
    'jpg',
    'jpeg',
    'png',
];

export const VIDEO = [
    'mp4',
    'mov',
    'avi',
    'wmv'
];

export const ALLOWED_MIME_TYPES = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'application/pdf',
    'text/plain',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/x-msvideo',
    'video/quicktime',
    'video/x-ms-wmv',
];

export const setFilesForCategory = (category, files) => {
    if (category === null) {
        return calculateForOtherCategory(files);
    }
    let fileCount = 0;
    let size = 0;
    let filesPerCategory = [];
    files
        .filter(file => category.includes(file.mimeType.toLowerCase()))
        .forEach(file => {
        fileCount = ++fileCount;
        size += file.size;
        filesPerCategory.push(file);
    });

    return {fileCount, fileSize: fileSize(size), filesPerCategory};
};

const calculateForOtherCategory = (files) => {
    let fileCount = 0;
    let size = 0;
    let filesPerCategory = [];
    files.filter(file =>
        !DOCUMENT.includes(file.mimeType.toLowerCase())
        && !PICTURES.includes(file.mimeType.toLowerCase())
        && !VIDEO.includes(file.mimeType.toLowerCase()))
        .forEach(file => {
            fileCount = ++fileCount;
            size += file.size;
            filesPerCategory.push(file);
        });

    return {fileCount, fileSize: fileSize(size), filesPerCategory};
};


export const allowedExtensions = () => [...DOCUMENT.map(el => `.${el}`), ...VIDEO.map(el => `.${el}`), ...PICTURES.map(el => `.${el}`)].join(',');
