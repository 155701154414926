export const allPrivatePages = {
    caseStatus: {
        statusOfCaseNumber: 'Ärendestatus #',
        caseWillBeInitiated: 'Ärendet initieras när du kontaktat kontaktpersonen på det telefonnummer du fick vid upprättandet.',
        provideFeedback: 'Vi inväntar svar från dig',
        goToMessages: 'Gå till meddelande',
        provideFeedbackTillDate: 'Mottagit feedback',
        provideConfirmationDate: 'Bekräftelse mottagen',
    },
    messages: {
        title: 'Meddelande',
        nav: {
            sharedFiles: 'Delade filer',
            allFiles: 'Alla filer',
            filterFiles: 'Filer',
            files: 'filer',
            document: 'Dokument',
            pictures: 'Bilder',
            video: 'Video',
            other: 'Annat',
        },
        chat: {
            writeCommentHere: 'Lämna din kommentar angående ärendet här...',
            addToCase: 'Lägg till i ärendet',
        }
    },
    seeReport: {
        submitComments: 'Lämna kommentarer',
        approve: 'OK',

        commentsOnReport: {
            title: 'Kommentarer till din rapport',
            comment: 'Skriv dina kommentarer till rapporten och övriga upplysningar här…',
            send: 'Skicka',
        },
        approveDialog: {
            title: 'Bekräfta innehållet i din rapport',
            description: 'Är anmälan korrekt beskriven? Du kan fortsatt skicka meddelanden gällande ärendet under ”Meddelanden”',
            cancel: 'Ta bort',
            confirm: 'Godkänd',
        }
    },
    yourInfo: {
        title: 'Om du inte tidigare angivit din identitet, har du möjlighet att göra det på denna sida',
        save: 'Spara',
        cancel: 'Ta bort',
        errorMessage: 'Angiven identitet är inte korrekt',
    },
    changePassword: {
        title: 'Ändra ditt lösenord till ärendet',
        description: 'Om du önskar ändra ditt lösenord till ärendet, ska du trycka ”Ändra lösenord” nedanför:',
        changePassword: 'Ändra lösenord',
        dialog: {
            title: 'Ändra ditt lösenord för ärendet',
            whatShouldYouDo: 'Du måste nu göra följande:',
            step1: 'Logga ut via rutan nedan',
            step2: 'Logga in på ditt ärende',
            step3: 'Ange ditt nya lösenord',
            descText1: 'För att byta lösenord ska du av säkerhetmässiga skäl först logga ut och sedan ändra ditt lösenord.',
            descText2: 'Bekräfta att du vill ändra ditt lösenord via rutan nedan, du blir då automatiskt utloggad.',
            btnApprove: 'Bekräfta och logga ut',
        }
    },

};
