import i18n from '@/i18n';

export const AboutPageType = {
    ANONYMITY: 'ANONYMITY',
    PERSONAL_DATA: 'PERSONAL_DATA',
    CASE_MANAGEMENT: 'CASE_MANAGEMENT',
};

export const getNavPagesFromSiteLookAndFeel = () => [
    {text: i18n.t('publicNavPages.home.title'), link: '/', icon: '', children: []},
    {text: i18n.t('publicNavPages.howToReport.title'), link: '/how-to-report', icon: '', children: []},
    {
        text: i18n.t('publicNavPages.aboutWhistleBlowing.title'), link: '/about-whistle-blowing', icon: '',
        children: [
            {
                text: i18n.t('publicNavPages.aboutWhistleBlowing.subPage.whistleblowerPolicy'),
                link: '/about-whistleblowing/personal-data',
                pageType: AboutPageType.PERSONAL_DATA,
            },
            {
                text: i18n.t('publicNavPages.aboutWhistleBlowing.subPage.yourProtection'),
                link: '/about-whistleblowing/anonymity',
                pageType: AboutPageType.ANONYMITY,
            },
            {
                text: i18n.t('publicNavPages.aboutWhistleBlowing.subPage.caseProcessing'),
                link: '/about-whistleblowing/case-process',
                pageType: AboutPageType.CASE_MANAGEMENT,
            },
        ]
    },
    {
        text: i18n.t('publicNavPages.faq.title'),
        link: '/faq',
        icon: '',
        children: []
    },
];
