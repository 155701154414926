export const landingPage = {
    actionCards: {
        title: 'W jaki sposób chcesz zgłosić?',
        onlineForm: {
            title: 'Zgłoś online',
        },
        oralReport: {
            title: 'Zgłoś telefonicznie',
        },
        bookMeeting: {
            title: 'Zgłoś na spotkaniu',
        },
        askQuestions: {
            title: 'Zadaj pytanie',
        },
    }
};
