export const privateNavPages = {
    status: {
        title: 'Status',
    },
    messages: {
        title: 'Messages',
    },
    yourReport: {
        title: 'Your report',
    },
    yourIdentity: {
        title: 'Your information',
    },
};
