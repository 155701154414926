<template>
  <div id="suggest-changes">
    <v-dialog
        v-model="openSuggestChanges"
        content-class="elevation-0"
        transition="dialog-top-transition"
        max-width="1216"
        @click:outside="closeSuggestChangesDialog()"
        @keydown.esc="closeSuggestChangesDialog()"
    >
      <v-card
          flat
          max-width="1216"
          min-height="721"
      >
        <div class="d-inline">
          <v-card elevation="0" color="primary" height="93" class="rounded-b-0">
            <div class="pa-4">
              <div class="d-flex justify-end" style="height: 18px">
                <v-icon class="c-point" color="white" @click="closeSuggestChangesDialog()">mdi-close</v-icon>
              </div>
              <div class="d-flex justify-center align-start mt-n2">
                <span class="white--text font-size-20">
                  {{ $t('allPrivatePages.seeReport.commentsOnReport.title') }}
                </span>
              </div>
            </div>
          </v-card>
        </div>


        <div class="d-flex justify-center mt-n7">
          <div class="white rounded-pill d-flex justify-center align-center"
               style="width: 55px; height: 55px; z-index: 0"
          >
            <v-icon size="35" color="icons">mdi-pencil-outline</v-icon>
          </div>
        </div>

        <v-card elevation="0" class="card-inner-padding">
          <v-textarea
              v-model="messageForm.message"
              :rules="rules.fieldRequiredRules"
              rows="16"
              outlined
              autofocus
              color="primary"
              :error="error.showError"
              :placeholder="$t('allPrivatePages.seeReport.commentsOnReport.comment')"
              @input="error.showError = false"
          />
        </v-card>
      </v-card>
      <div class="d-flex justify-center align-center mt-n8">
        <v-btn
            color="primary"
            height="51"
            elevation="0"
            width="160"
            class="text-none mt-5-rem"
            @click="sendComment"
        >
          <span class="font-size-18">{{ $t('allPrivatePages.seeReport.commentsOnReport.send') }}</span>
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {fieldRequiredRules} from '@/utils/rules/inputRules';
import {isFalsy} from '@/utils/guards';

export default {
  name: 'SuggestChangesDialog',
  data() {
    return {
      rules: {
        fieldRequiredRules
      },
      error: {
        showError: false,
        errorMessage: null,
      },
      openSuggestChanges: false,
      messageForm: {
        message: '',
      },
    };
  },
  methods: {
    ...mapActions('authCase', ['sendMessage']),
    closeSuggestChangesDialog() {
      this.openSuggestChanges = false;
      this.messageForm = {message: null};
      this.error = {showError: false, errorMessage: null};
    },

    openSuggestChangesDialog() {
      this.openSuggestChanges = true;
    },

    async sendComment() {
      if (isFalsy(this.messageForm.message)) {
        this.error.showError = true;
      } else {
        await this.sendMessage({message: this.messageForm, files: []});

        this.closeSuggestChangesDialog();
      }
    },
  }
};
</script>

<style scoped>


</style>
