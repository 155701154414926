import axios from 'axios';
import {backendUrl} from '@/clients/baseUrl';
import applicationVersionStorage from '@/provider/ApplicationVersionStorage';

class ConfigurationService {
    async getBackendUrlFromConfiguration() {
        try {
            let config = await axios({method: 'GET', url: '/config/config.json'});
            if (config.status === 200) {
                let {proto, api, port} = config.data.config.api;

                applicationVersionStorage.setAppVersion(config.data.config.version);

                return `${proto}${api}:${port}`;
            } else {
                return backendUrl;
            }
        } catch {
            return backendUrl;
        }
    }

    async getIdFromConfiguration() {
        let defaultIdConfig = {
            url: process.env.VUE_APP_KEYCLOAK_URL,
            realm: process.env.VUE_APP_KEYCLOAK_REALM,
            clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
            onLoad: process.env.VUE_APP_KEYCLOAK_ON_LOAD_ACTION,
        };

        try {
            let config = await axios({method: 'GET', url: '/config/config.json'});
            if (config.status === 200) {
                let {id} = config.data.config;
                return id;
            } else {
                return defaultIdConfig;
            }
        } catch {
            return defaultIdConfig;
        }
    }
}

export default new ConfigurationService();
