export const publicNavPages = {
    home: {title: 'Home',},
    howToReport: {title: 'How to report',},
    aboutWhistleBlowing: {
        title: 'Whistleblower scheme',
        subPage: {
            whistleblowerPolicy: 'Whistleblower Policy',
            yourProtection: 'Your Protection',
            caseProcessing: 'Case management',
        },
    },
    faq: {title: 'FAQ',}
};
