import {
    getCaseUsingGET,
    getDomain,
    newCaseByMeetingUsingPOST,
    newCaseByPhoneUsingPOST,
    requestPasswordUpdateForWhistleblowerUsingPUT,
    setApprovedAtUsingPUT,
    updateWhistleblowerUsingPOST
} from '@/clients/wbBack/wbBack.api';
import axios from 'axios';
import {authHeaders} from '@/clients/headers/authHeader';
import keyCloakService from '@/services/auth/KeyCloakService';
import KeyCloakService from '@/services/auth/KeyCloakService';
import {companyNameHeader} from '@/clients/headers/commonHeaders';


class CaseService {

    async getCaseWithAuthToken() {
        try {
            let token = await keyCloakService.getAuthToken();
            let caseCreated = await getCaseUsingGET({
                $config: authHeaders(token)
            });
            return caseCreated?.data;
        } catch {
            return null;
        }
    }

    async updateWhistleBlower(whistleblowerDto) {
        try {
            let authToken = await keyCloakService.getAuthToken();
            let updateResponse = await updateWhistleblowerUsingPOST({
                whistleblowerDto,
                $config: {
                    headers: {
                        ...authHeaders(authToken).headers,
                        'Content-Type': 'application/json'
                    }
                }
            });

            return updateResponse?.data;
        } catch {
            return null;
        }
    }

    async uploadOnlineFormCase(caseDto, files) {
        try {
            const formData = this.createFromDataCasePayload(caseDto, files);
            let domain = getDomain();
            let caseCreated = await axios({
                method: 'post',
                url: `${domain}/api/public/case/new/by-form`,
                data: formData,
                headers: companyNameHeader.headers
            });

            return caseCreated?.data;
        } catch {
            return null;
        }
    }

    async uploadOralFormCase(submissionByPhoneDto) {
        try {
            let newCase = await newCaseByPhoneUsingPOST({
                submissionByPhoneDto,
                $config: companyNameHeader
            });
            return newCase?.data;
        } catch {
            return null;
        }
    }

    async uploadBookMeetingFormCase(submissionByMeetingDto) {
        try {
            let newCase = await newCaseByMeetingUsingPOST({
                submissionByMeetingDto,
                $config: companyNameHeader
            });
            return newCase?.data;
        } catch (error) {
            return null;
        }
    }

    async approveChanges() {
        try {
            let authToken = await keyCloakService.getAuthToken();
            let newCase = await setApprovedAtUsingPUT({
                $config: authHeaders(authToken)
            });
            return newCase?.data;
        } catch {
            return null;
        }
    }

    async requestPasswordUpdate() {
        try {
            let authToken = await keyCloakService.getAuthToken();
            let requestPass = await requestPasswordUpdateForWhistleblowerUsingPUT({
                $config: authHeaders(authToken)
            });

            if (requestPass?.status === 200) {
                await KeyCloakService.logoutUser();
            }
        } catch {
            // do nothing
        }
    }

    createFromDataCasePayload(caseDto, files) {
        const formData = new FormData();
        formData.append('caseDto', new Blob([JSON.stringify(caseDto)], {type: 'application/json'}));

        if (files.length > 0) {
            files.forEach(file => {
                formData.append('files', new Blob([file], {type: 'multipart/form-data'}), file.name);
            });
        }
        return formData;
    }
}


export default new CaseService();
