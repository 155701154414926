<template>
  <v-card color="white" min-height="300px" class="pb-6">
    <v-card color="primary" class="pl-8 pr-8 rounded-b-0" min-height="55px">
      <v-row justify="space-between" align="center">
        <v-col cols="12" lg="6" md="8" sm="12">
          <div class="text-h6 white--text">
            {{ $t('report.reportDescription.caseNumber') }} #{{ getCaseNumber }}
          </div>
        </v-col>
        <v-col cols="12" lg="6" md="4" sm="12">
          <div class="white--text d-flex justify-end w-100-p">
            <span class="word-brake">
              <span>{{ $t('report.reportDescription.received') }}</span>
              <span class="pl-1">{{ getDateFormatted() }} </span>
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <div class="ma-8">
      <div>
        <div class="text-h6 primary--text">
          {{ $t('report.reportDescription.incidentReported') }}
        </div>
        <div class="custom-divider"/>
        <div>
          <div class="mt-n3 primary--text">
            {{ getCasePayload.description }}
          </div>
        </div>
      </div>

      <v-row class="mt-6">
        <v-col cols="12" >
          <div>
            <div class="text-h6 primary--text">
              {{ $t('report.reportDescription.isIncidentRepeated') }}
            </div>
            <div class="custom-divider"/>

            <div v-if="getCasePayload.repeatedViolations" class="mt-n3">
              {{ $t('report.reportDescription.yes') }}
            </div>
            <div v-else class="mt-n3">
              {{ $t('report.reportDescription.no') }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex';
import moment from 'moment';

export default {
  name: 'ReportDescriptionInfoCard',
  data() {
    return {
      isRepeated: false,
    };
  },
  computed: {
    ...mapGetters('authCase', ['getCaseNumber', 'getCasePayload']),
  },
  methods: {
    getDateFormatted() {
      let dateFormatted = moment(this.getCasePayload.created).format('dddd ### DD-MM-YYYY, HH:mm');
      return dateFormatted.replace('###', 'd.');
    }
  }
};
</script>

<style scoped>

</style>
