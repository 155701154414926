import i18n from '@/i18n';

export const privateNavPages = [
    {
        text: 'privateNavPages.status.title',
        link: '/private/status',
        icon: 'mdi-home-variant-outline',
        children: [],
        badgeGetterName: ''
    },
    {
        text: 'privateNavPages.messages.title',
        link: '/private/messages',
        icon: 'mdi-forum',
        children: [],
        badgeGetterName: ''
    },
    {
        text: 'privateNavPages.yourReport.title',
        link: '/private/report',
        icon: 'mdi-email-newsletter',
        children: [],
        badgeGetterName: ''
    },
    {
        text: 'privateNavPages.yourIdentity.title',
        link: '/private/info',
        icon: 'mdi-information-outline',
        children: [],
        badgeGetterName: ''
    }
];
