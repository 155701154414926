export const modal = {
    newCaseCreated: {
        whatShouldYouDo: 'Du skal nu gøre følgende:',
        thankYouSubmitting: 'Vielen Dank für die Einreichung Ihres Hinweises',
        caseNumber: 'Fallnummer #',
        reportReceived: 'Din indberetning er modtaget',
        saveCaseNumberAndLogWithIt: 'Gem nedenstående sagsnummer for at logge ind og følge din indberetning. Du logger ind på forsiden.',
        readMoreHere: 'Lesen Sie mehr darüber, wie wir ',
        readMoreHere1: 'Ihren Hinweis behandeln',
        weFollowUpWithin7Days: 'Vi følger op på din indberetning inden for 7 dage.',
        noNeedToDoMoreThings: 'Es ist ',
        noNeedToDoMoreThings1: 'wichtig',
        noNeedToDoMoreThings2: ', dass Sie Ihre Fallnummer und Ihr selbstgewähltes Passwort aufbewahren.',
        caseWillBeHandledByCompanyWbUnit: ' Sie benötigen diese Angaben, um sich einzuloggen und Ihren Fall zu verfolgen.',
        loginVerify: 'Einloggen und Ihr Konto verifizieren',
        callUs: 'Angegebene Telefonnummer anrufen',
        logInAgainOn7Days: 'In 7 Tagen erneut einloggen, um Ihre Bestätigung zu sehen',
        loginInContinuously: 'Kontinuierlich einloggen, um Ihren Fall zu verfolgen',
        arrangeTime: 'Zeit und Ort vereinbaren',

        youHaveChooseToNotFollow: 'Sie haben sich dafür entschieden, Ihrem Hinweis nicht zu folgen.',
        weCanotContactYou: 'Sofern Sie keine Angaben über Ihre Identität gemacht haben, können wir uns nicht mit Ihnen in Verbindung setzen und Sie nicht über die Fallbearbeitung informieren.',
        forOtherThingsYouShouldCreateANewReport: 'Sofern Sie Ihre Meldung zu einem späteren Zeitpunkt ergänzen möchten, müssen Sie eine neue Meldung einreichen.',
        saveTheNumberJustInCase: 'Es ist ',
        saveTheNumberJustInCase1: 'wichtig',
        saveTheNumberJustInCase2: ', Ihre Fallnummer aufzubewahren. Sie stellt die Dokumentation für Ihre Meldung dar.',

    },
    errorDialog: {
        unableToCreateCase: 'Fall kann nicht erstellt werden. Füllen Sie das Formular aus und versuchen Sie es erneut.',
        close: 'OK',
    },
    validationDialog: {
        title: 'Angaben fehlen',
        completeInformationMissing: 'Füllen Sie das Formular mit allen erforderlichen Informationen aus.',
        ok: 'OK',
    },
    dataWillBeDiscardedDialog: {
        title: 'Wenn Sie fortfahren, werden die eingegebenen Daten verworfen',
        ok: 'Weiter',
        close: 'Löschen',
    },
    caseNumber: 'Fallnummer',
    toTheFront: 'Zur Startseite',
    login: 'Einloggen',
};
