<template>
  <v-dialog
      v-model="getSuccessDialog"
      transition="dialog-top-transition"
      :max-width="isMobile ? '100%' : '69%'"
      :retain-focus="false"
      content-class="elevation-0"
      @click:outside="closeSuccessDialog()"
      @keydown.esc="closeSuccessDialog()"
  >
    <v-card flat>
      <div class="pb-6">
        <div class="report-received-wrapper">
          <v-card color="warningCustom" height="55" elevation="0" class="d-flex justify-center align-center">
            <div>
              <span class="primary--text font-size-24 font-weight-bold">
                {{ $t('modal.newCaseCreated.caseNumber') }}
              </span>
              <span class="primary--text font-size-24 font-weight-bold">{{ getCaseNumber }}</span>
            </div>
          </v-card>
        </div>

        <div v-if="getCaseAccountFollow" class="pa-4">
          <div class="d-flex justify-center mb-10 mt-10">
            <div class="report-received text-center" style="width: 65%">
              <span class="primary--text font-size-24 font-weight-bold">
                {{ $t('modal.newCaseCreated.whatShouldYouDo') }}
              </span>
            </div>
          </div>
          <div class="d-flex justify-center">
            <div class="d-flex ma-8 flex-wrap"
                 :class="{'justify-center': isMobile, 'justify-space-between': !isMobile}" style="width: 100%"
            >
              <div class="pt-4 pb-4" style="max-width: 180px">
                <div class="d-flex justify-center">
                  <v-icon color="icons" size="65">mdi-phone-in-talk-outline</v-icon>
                </div>
                <div class="d-flex justify-center mt-2">
                  <span class="text-center primary--text">{{ $t('modal.newCaseCreated.callUs') }}</span>
                </div>
              </div>
              <v-divider v-if="!isMobile" class="primary mt-12"/>
              <div class="pt-4 pb-4" style="max-width: 180px">
                <div class="d-flex justify-center">
                  <v-icon color="icons" size="65">mdi-calendar-month-outline</v-icon>
                </div>
                <div class="d-flex justify-center mt-2">
                  <span class="text-center primary--text">{{ $t('modal.newCaseCreated.arrangeTime') }}</span>
                </div>
              </div>
              <v-divider v-if="!isMobile" class="primary mt-12"/>
              <div class="pt-4 pb-4" style="max-width: 150px">
                <div class="d-flex justify-center">
                  <v-icon color="icons" size="65">mdi-shield-lock-outline</v-icon>
                </div>
                <div class="d-flex justify-center mt-2">
                  <span class="text-center primary--text">
                    {{ $t('modal.newCaseCreated.loginVerify') }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-center align-center">
            <span class="text-sm-h3 text-decoration-underline primary--text font-weight-bold">
              {{ getCaseProtectionOfficer.phone }}
            </span>
          </div>
          <div class="d-flex justify-center align-center pt-3 mb-10 lawyer-name">
            {{ getProtectionOfficerAssign }}
          </div>
          <div class="case-number">
            <div class="d-flex justify-center">
              <v-tooltip
                  v-model="showTextCopied"
                  top
                  left
                  :disabled="!showTextCopied"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                      v-if="getCaseAccountFollow"
                      class="mt-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    {{ $t('modal.caseNumber') }}. #{{ getCaseNumber }}
                  </div>
                </template>
                <span>Text Copied</span>
              </v-tooltip>

              <v-btn icon class="ml-2" @click="copyCaseNumber()">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex justify-center mt-4 pl-4 pr-4">
            <div class="follow-case-by-clicking">
              <span class="font-size-18 font-weight-regular">
                {{ $t('modal.newCaseCreated.noNeedToDoMoreThings') }}
              </span>
              <span class="font-size-18 font-weight-bold text-decoration-underline">
                {{ $t('modal.newCaseCreated.noNeedToDoMoreThings1') }}
              </span>
              <span class="font-size-18 font-weight-regular">
                {{ $t('modal.newCaseCreated.noNeedToDoMoreThings2') }}
              </span>
            </div>
          </div>
          <div class="d-flex justify-center mt-2 pl-4 pr-4">
            <div class="font-size-18 font-weight-regular primary--text">
              {{ $t('modal.newCaseCreated.caseWillBeHandledByCompanyWbUnit') }}
            </div>
          </div>
        </div>
        <div v-else class="pa-4">
          <CaseNotFollowed/>
        </div>
      </div>
    </v-card>
    <div class="d-flex justify-center align-center">
      <v-btn
          color="primary"
          height="61"
          elevation="0"
          width="160"
          class="text-none mt-n8"
          @click="closeDialog()"
      >
        {{ getCaseAccountFollow ? $t('modal.login') : $t('modal.toTheFront') }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import KeyCloakService from '@/services/auth/KeyCloakService';
import CaseNotFollowed from '@/components/card/infoCard/CaseNotFollowed';

export default {
  name: 'NewCaseCreatedOral',
  components: {CaseNotFollowed},
  data() {
    return {
      showTextCopied: false,
    };
  },
  computed: {
    ...mapGetters('onlineForm', [
      'getSuccessDialog',
      'getSuccessDialogData',
      'getCaseAccountFollow',
      'getCaseProtectionOfficer',
    ]),
    getCaseNumber() {
      return this.getSuccessDialogData?.caseDto?.caseNumber;
    },
    getProtectionOfficerAssign() {
      return this.getCaseProtectionOfficer?.name + ', ' + this.getCaseProtectionOfficer?.jobTitle + ', ' + this.getCaseProtectionOfficer?.company;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 1111;
    }
  },
  methods: {
    ...mapActions('onlineForm', ['closeSuccessDialog']),
    copyCaseNumber() {
      this.$copyText(this.getCaseNumber);
      this.showTextCopied = true;
      setTimeout(() => {
        this.showTextCopied = false;
      }, 2000);
    },
    async closeDialog() {
      if (this.getCaseAccountFollow) {
        await KeyCloakService.loginUser();
      } else {
        await this.closeSuccessDialog();
      }
    }
  }
};
</script>

<style scoped>

</style>
