export const report = {
    reportDescription: {
        caseNumber: 'Case number',
        attachIfIsNecessary: 'Attached evidence',
        received: 'Received',
        incidentReported: 'Description of the reported incident',
        rulesViolated: 'Rules that are presumed violated',
        isIncidentRepeated: 'The incident is recurrent or repeated',
        yes: 'Yes',
        no: 'No',
    },
    reportRelate: {
        reportRelateTo: 'Persons / departments covered by the report',
        personsInCompany: 'Navngivne personer i virksomheden:',
        company: 'Company',
    }
};
