export const privateNavPages = {
    status: {
        title: 'Status',
    },
    messages: {
        title: 'Meldinger',
    },
    yourReport: {
        title: 'Din rapport',
    },
    yourIdentity: {
        title: 'Din informasjon',
    },
};
