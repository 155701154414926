export const privateNavPages = {
    status: {
        title: 'Status',
    },
    messages: {
        title: 'Mitteilungen',
    },
    yourReport: {
        title: 'Ihre Meldung',
    },
    yourIdentity: {
        title: 'Ihre Angaben',
    },
};
