import Vue from 'vue';
import {frontEndUrl} from '@/clients/baseUrl';

class KeyCloakService {
    async loginUser() {
        return await Vue.$keycloak.login({
            redirectUri: frontEndUrl + '/private/status'
        });
    }

    async logoutUser() {
        await Vue.$keycloak.logout({
            redirectUri: frontEndUrl
        });
    }

    async isUserAuthenticated() {
        return Vue.$keycloak.authenticated;
    }

    async getAuthToken() {
        return await Vue.$keycloak.token;
    }

    async refreshToken() {
        await Vue.$keycloak.updateToken(70);
    }

    async keyCloakInstance() {
        return await Vue.$keycloak;
    }
}

export default new KeyCloakService();
