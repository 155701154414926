export const modal = {
    newCaseCreated: {
        whatShouldYouDo: 'You now need to do the following:',
        thankYouSubmitting: 'Takk for at du sendte inn denne rapporten',
        caseNumber: 'Sak nummer #',
        reportReceived: 'Din indberetning er modtaget',
        saveCaseNumberAndLogWithIt: 'Gem nedenstående sagsnummer for at logge ind og følge din indberetning. Du logger ind på forsiden.',
        readMoreHere: 'Les mer om hvordan vi ',
        readMoreHere1: 'behandler din rapport',
        weFollowUpWithin7Days: 'Vi følger op på din indberetning inden for 7 dage.',
        noNeedToDoMoreThings: 'Det er ',
        noNeedToDoMoreThings1: 'viktig',
        noNeedToDoMoreThings2: ' at du lagrer saksnummeret og passordet ditt. Du vil trenge denne informasjonen til innlogging og for å følge saken din.',
        caseWillBeHandledByCompanyWbUnit: 'You will need this information to log in and follow your case.',
        loginVerify: 'Logg inn og verifiser brukeren din',
        callUs: 'Ring det oppgitte telefonnummeret',
        logInAgainOn7Days: 'Logg inn igjen om 7 dager for å se din bekreftelse',
        loginInContinuously: 'Logg inn fortløpende for å følge saken',
        arrangeTime: 'Arrange time and place',

        youHaveChooseToNotFollow: 'Du har valgt å ikke følge rapporten din.',
        weCanotContactYou: 'Hvis du ikke har informert om din identitet kan vi ikke kontakte deg, og du vil ikke' +
            ' høre fra oss vedrørende saksbehandlingen.',
        forOtherThingsYouShouldCreateANewReport: 'Hvis du på et senere tidspunkt skulle ønske å supplementere' +
            ' rapporten, må du lage en ny rapport.',
        saveTheNumberJustInCase: 'Det er ',
        saveTheNumberJustInCase1: 'svært viktig',
        saveTheNumberJustInCase2: ' at du lagrer saksnummeret. Dette er din dokumentasjon på rapporten din.',

    },
    errorDialog: {
        unableToCreateCase: 'Can not submit case. Complete the form and try again.',
        close: 'Close',
    },
    validationDialog: {
        title: 'Informasjon mangler',
        completeInformationMissing: 'Fullfør skjemaet med all nødvendig informasjon.',
        ok: 'OK',
    },
    dataWillBeDiscardedDialog: {
        title: 'Hvis du fortsetter, vil de oppgitte dataene bli slettet.',
        ok: 'Fortsett',
        close: 'Tilbake',
    },
    caseNumber: 'Sak nummer',
    toTheFront: 'Til forsiden',
    login: 'Logg inn',
};
