const langSupported ={
    DA: 'da',
    EN: 'en',
    PL: 'pl',
    SE: 'se',
    ES: 'es',
};

export const phoneCodeMappings = {
    [langSupported.DA]: 'DK',
    [langSupported.EN]: 'GB',
    [langSupported.PL]: 'PL',
    [langSupported.SE]: 'SE',
    [langSupported.ES]: 'ES',
};

export const browserLanguageCodeMappings = {
    'pl': langSupported.PL,
    'da': langSupported.DA,
    'en': langSupported.EN,
    'en-US': langSupported.EN,
    'en-EG': langSupported.EN,
    'en-AU': langSupported.EN,
    'en-GB': langSupported.EN,
    'en-CA': langSupported.EN,
    'en-NZ': langSupported.EN,
    'en-IE': langSupported.EN,
    'en-ZA': langSupported.EN,
    'sv': langSupported.SE,
    'sv-FI': langSupported.SE,
    'es': langSupported.ES,
};
