<template>
  <div class="mt-15">
    <v-card
        class="mx-auto card-border-bottom"
        min-height="610px"
        max-width="100%"
        rounded
        elevation="2"
    >
      <div class="card-inner-padding">
        <slot name="card-title"/>

        <FileUpload :files="uploadedFiles"/>

        <div class="mt-2">
          <div>
            <span class="word-brake error--text">
              {{ $t('step.documentUpload.max') }} 2 GB {{ $t('step.documentUpload.perFile') }}
            </span>
          </div>
        </div>

        <StepInformationFooter :text="$t('tooltips.documentUpload.text')"/>
      </div>
    </v-card>
  </div>
</template>

<script>
import StepInformationFooter from '@/components/tooltip/StepInformationTooltip';
import FileUpload from '@/components/card/inputCards/FileUpload';

export default {
  name: 'StepDocumentUpload',
  props: {
    uploadedFiles: {
      type: Array,
      default: null
    }
  },
  components: {
    StepInformationFooter,
    FileUpload
  }
};
</script>
