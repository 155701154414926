import Vue from 'vue';
import AsyncComputed from 'vue-async-computed';
import VueClipboard from 'vue-clipboard2';

import '@/assets/css/app.scss';
import '@/assets/css/card.scss';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import vue_moment from 'vue-moment';
import moment from 'moment';

import '@/initApplication';

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
VueClipboard.config.autoSetContainer = true;

Vue.use(vue_moment);
Vue.use(AsyncComputed);
Vue.use(VueClipboard);
