export const bookMeetingPage = {
    of: ' af ',
    title: 'Book a meeting to report your suspicions or knowledge - read more about how we ',
    link: 'protect your identity',
    stepSubsidiaryCompany: {
        title: 'Which company does the report relate to?',
        subtitle: ' - Please choose which company in the group your report relates to.',
    },
    stepProtectionOfficer: {
        title: 'Who should receive your report at a physical meeting?',
        subtitle: ' - Click on the person or unit you want to receive your report.',
        oneProtectionOfficerTitle: 'Who should receive your report at a physical meeting?',
        oneProtectionOfficerSubtitle: ' - You will be able to book a meeting with the following person',
    },
    stepIdentity: {
        title: 'Do you want to reveal your identity?',
        subtitle: ' - It is optional if you want to reveal your identity. Please select your choice below.',
    },
    stepWhistleblowerRelation: {
        title: 'What is your relationship to the organisation?',
        subtitle: ' - Describe your relationship to the organisation to whom your report relates. For example, that you are employed.',
        placeholder: 'Enter relation...*',
    },
    stepFollowReport: {
        title: 'Choose a password to follow up on your meeting booking',
        subtitle: ' - You need to create a password so you can log in and follow up on your meeting booking.',
        sendFormButton: 'Get telephone number',
    },
};
