<template>
  <v-navigation-drawer
      v-model="openDrawer"
      fixed
      app
      permanent
      hide-overlay
      width="250"
      class="elevation-1"
      mini-variant-width="60"
      style="z-index: 5"
      :mini-variant.sync="mini"
  >
    <v-card
        width="280"
        min-height="100vh"
        elevation="0"
        class="rounded-0 d-flex justify-space-between flex-column"
    >
      <div>
        <v-list-item :class="{'mt-7': showBurgerIcon}">
          <div v-if="showBurgerIcon" class="d-flex justify-start align-start">
            <div class="mr-4">
              <div v-if="mini" class="ml-4 mt-4">
                <v-icon size="30" @click="mini = !mini">mdi-forwardburger</v-icon>
              </div>
              <div v-else class="mb-3">
                <v-icon size="30" @click="mini = !mini">mdi-backburger</v-icon>
              </div>
            </div>
          </div>

          <div class="ma-4 ml-0">
            <img
                v-if="!mini"
                width="160"
                :src="getLogo"
                height="45"
                alt="Logo"
                @click="$router.push('/private/status').catch(e=>{})"
            >
          </div>
        </v-list-item>

        <v-list>
          <v-list-item-group v-model="selectedItem">
            <v-list-item
                v-for="(item, index) in privateNavPages"
                :key="index"
                link
                :to="item.link"
            >
              <v-list-item-icon>
                <v-icon size="28" color="icons">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="primary--text font-weight-bold">{{ $t(item.text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>

      <div class="mb-16 fixed-bottom-0">
        <v-btn v-if="!mini" class="text-none ml-12 mr-12" width="180px" outlined color="primary" @click="logOutUser()">
          {{ $t('misc.buttons.logout') }}
        </v-btn>
        <div v-else class="pl-4">
          <v-icon size="28" color="icons" @click="logOutUser()">mdi-logout-variant</v-icon>
        </div>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import {privateNavPages} from '@/model/privateNavPages';
import keyCloakService from '@/services/auth/KeyCloakService';
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'PrivateNavigationDrawer',
  data() {
    return {
      privateNavPages,
      openDrawer: true,
      showBurgerIcon: false,
      mini: false,
      selectedItem: null,
    };
  },
  watch: {
    '$vuetify.breakpoint.width': {
      handler() {
        this.shrinkViewOnMobile();
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters('authCase', ['getCasePayload']),
    ...mapGetters('lookAndFeel', ['getLogo']),
    isMini() {
      return this.$vuetify.breakpoint.width < 1000;
    },
  },
  mounted() {
    this.shrinkViewOnMobile();
  },
  methods: {
    ...mapMutations('event', ['setLoadingState']),
    async logOutUser() {
      this.setLoadingState(true);
      await keyCloakService.logoutUser();
      this.setLoadingState(false);
    },
    shrinkViewOnMobile() {
      if (this.isMini) {
        this.mini = true;
        this.showBurgerIcon = true;
      } else {
        this.mini = false;
        this.showBurgerIcon = false;
      }
    }
  }
};
</script>

<style scoped>

.fixed-bottom-0 {
  position: fixed;
  bottom: 0;
}

</style>
