<template>
  <v-dialog
      v-model="getFailureDialog"
      transition="dialog-top-transition"
      max-width="600"
  >
    <v-card class="cannot-create-case">
      <v-card-text>
        <div class="text-h5 pt-5">
          <span class="white--text">
            {{ $t('modal.errorDialog.unableToCreateCase') }}
          </span>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
            text
            class="text-none"
            @click="failureDialog(false)"
        >
          <span class="primary--text">
            {{ $t('modal.errorDialog.close') }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'CannotCreateCase',
  computed: {
    ...mapGetters('onlineForm', ['getFailureDialog'])
  },
  methods: {
    ...mapMutations('onlineForm', ['failureDialog'])
  }
};
</script>

<style scoped>

.cannot-create-case {
  background-color: var(--v-error-lighten3);
}

</style>
