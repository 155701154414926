<template>
  <AuthLayout class="mr-12">
    <PrivateCustomHeaderTitle :title="$t('allPrivatePages.messages.title')"/>

    <ChatMessages :class="{'mr-20': toggleChat}"/>

    <MessageFilesNavDrawer @toggleFileDrawer="toggleFileDrawer"/>
  </AuthLayout>
</template>

<script>
import PrivateCustomHeaderTitle from '@/components/headers/PrivateCustomHeaderTitle';
import ChatMessages from '@/components/card/chat/ChatMessages';
import MessageFilesNavDrawer from '@/components/bars/private/MessageFilesNavDrawer';
import AuthLayout from '@/components/layout/AuthLayout';

export default {
  name: 'Messages',
  components: {AuthLayout, MessageFilesNavDrawer, ChatMessages, PrivateCustomHeaderTitle},
  data() {
    return {
      toggleChat: false
    };
  },
  methods: {
    toggleFileDrawer(mini) {
      this.toggleChat = !mini;
    },
  }
};
</script>

<style scoped>

.mr-20 {
  margin-right: 200px;
}
</style>



