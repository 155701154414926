<template>
  <MainLayout>
    <PublicPageTitle :header-name="$t('publicNavPages.faq.title')"/>
    <div>
      <v-expansion-panels flat accordion>
        <v-expansion-panel
            v-for="(faq, index) in faqs"
            :key="index"
            class="border-all-1 rounded ma-4"
        >
          <v-expansion-panel-header class="border-bottom-1">{{ faq.heading }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="pa-4 with-linebreak">{{ faq.paragraph }}</div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layout/MainLayout';
import PublicPageTitle from '@/components/headers/PublicPageTitle';
import {mapGetters} from 'vuex';

export default {
  name: 'Faq',
  components: {
    MainLayout,
    PublicPageTitle
  },
  computed: {
    ...mapGetters('lookAndFeel', ['getSiteFrequentlyAskedQuestions']),
    faqs() {
      return this.getSiteFrequentlyAskedQuestions?.faqs;
    }
  },
};
</script>

<style scoped lang="scss">

.faq-title {
  font-size: 30px;
  line-height: 34px;
  color: var(--v-primary-base);
}

.border-bottom-1 {
  border-bottom: 1px solid var(--v-primary-base);
}

.v-expansion-panel-header {
  min-height: 34px !important;
  padding: 9px 25px !important;
}

</style>
