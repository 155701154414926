export const oralReportPage = {
    of: ' av ',
    title: 'Rapportera misstänkt missförhållande via telefon – läs mer om hur vi ',
    link: 'skyddar din identitet',
    stepProtectionOfficer: {
        title: 'Vem ska ta emot din rapport?',
        subtitle: ' - Ange den person eller enhet som du vill ska ta emot din rapport.',
        oneProtectionOfficerTitle: 'Vem tar emot din rapport?',
        oneProtectionOfficerSubtitle: ' - Din rapport kommer att skickas till följande person',
    },
    stepSubsidiaryCompany: {
        title: 'Vilket bolag gäller din rapport?',
        subtitle: ' - Ange det bolag inom koncernen som din rapport avser',
    },
    stepWhistleblowerRelation: {
        title: 'Vad är din relation till organisationen?',
        subtitle: 'Beskriv din relation till organisationen som din rapport handlar om. Till exempel att du är anställd.',
        placeholder: 'Ange din relation...*',
    },
    stepIdentity: {
        title: 'Vill du uppge din identitet?',
        subtitle: ' - Det är frivilligt för dig att uppge din identitet.',
    },
    stepFollowReport: {
        title: 'Välj lösenord för att logga in på din rapport',
        subtitle: ' - Skapa ett lösenord så att du kan logga in och kommentera, kontroller eller godkänna din rapport.',
        sendReportButton: 'Visa telefonnummer',
    },
};
