<template>
  <div>
    <v-dialog
        v-model="discardUsersDialog"
        width="550"
    >
      <v-card>
        <v-card-title class="primary lighten-3">
          <span class="white--text">
            {{ $t('modal.dataWillBeDiscardedDialog.title') }}
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="primary"
              text
              class="text-none"
              @click="$emit('cancelUserDiscard')"
          >
            {{ $t('modal.dataWillBeDiscardedDialog.close') }}
          </v-btn>
          <v-btn
              color="primary"
              text
              class="text-none"
              @click="$emit('confirmUserDiscard')"
          >
            {{ $t('modal.dataWillBeDiscardedDialog.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DataWillBeDiscarded',
  props: {
    discardUsersDialog: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>

</style>
