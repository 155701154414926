<template>
  <v-card
      id="stepFromDescribeProblem"
      class="mx-auto card-border-bottom"
      min-height="550"
      max-width="100%"
      rounded
      elevation="2"
  >
    <div class="card-inner-padding">
      <div>
        <slot name="card-title"/>
        <v-textarea
            v-model="describeProblem.description"
            :rules="inputRules.fieldRequiredRules"
            :error="displayFormValidationError && isFalsy(describeProblem.description)"
            outlined
            auto-grow
            rows="8"
            name="input-7-4"
            :placeholder="$t('step.describeProblem.describe')"
            @input="setStepProblemDescriptionValues()"
        />
      </div>

      <v-row class="d-flex justify-space-between">

        <v-col cols="12" lg="6" xl="6" md="12" sm="12">
          <div>{{ $t('step.describeProblem.isProblemRepeated') }}</div>
          <v-radio-group
              v-model="describeProblem.repeatedViolations"
              class="mt-4"
              :error="displayFormValidationError && isFalsy(describeProblem.repeatedViolations)"
              style="width: 100%"
              @change="setStepProblemDescriptionValues()"
          >
            <div class="d-flex justify-space-between">
              <div
                  class="pa-4 border-all-1"
                  :class="{'border-all-error-1': displayFormValidationError && isFalsy(describeProblem.repeatedViolations)}"
                  style="width: 49%; height: 56px"
              >
                <v-radio
                    :label="$t('step.describeProblem.yes')"
                    :value="true"
                />
              </div>

              <div
                  class="pa-4 border-all-1"
                  :class="{'border-all-error-1': displayFormValidationError && isFalsy(describeProblem.repeatedViolations)}"
                  style="width: 49%; height: 56px"
              >
                <v-radio
                    :label="$t('step.describeProblem.no')"
                    :value="false"
                />
              </div>
            </div>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row v-if="describeProblem.repeatedViolations" class="d-flex justify-start mt-n3 pb-4" no-gutters>
        <v-col cols="12" lg="6" md="8" sm="12">
          <div class="w-100-p d-flex">
            <span class="warning-text">
              <v-icon class="mb-1" size="25" color="error">mdi-information-outline</v-icon>
              <span class="pl-2">{{ $t('step.describeProblem.howOftenIsRepeated') }}</span>
            </span>
          </div>
        </v-col>
      </v-row>
      <StepInformationFooter :text="$t('tooltips.describeProblem.text')"/>
    </div>
  </v-card>
</template>

<script>
import StepInformationFooter from '@/components/tooltip/StepInformationTooltip';
import {fieldRequiredRules} from '@/utils/rules/inputRules';
import {mapGetters, mapMutations} from 'vuex';
import {isFalsy, isNotFalsy} from '@/utils/guards';

export default {
  name: 'StepDescribeProblem',
  props: {
    problem: {
      type: Object,
      default: null,
    }
  },
  components: {
    StepInformationFooter
  },
  data() {
    return {
      inputRules: {
        fieldRequiredRules
      },
      describeProblem: {
        description: null,
        violation: null,
        repeatedViolations: null
      },
    };
  },
  computed: {
    ...mapGetters('onlineForm', ['getShowFormValidationErrorDialog', 'getFormValidation']),
    displayFormValidationError: {
      get() {
        if (this.getShowFormValidationErrorDialog) {
          return !this.getFormValidation.stepFromDescribeProblem.isValid;
        }
        return false;
      }
    },
  },
  watch: {
    'describeProblem': {
      handler() {
        let isValid = isNotFalsy(this.describeProblem.description)
            && isNotFalsy(this.describeProblem.repeatedViolations);
        this.setValidFromStep({validStep: 'stepFromDescribeProblem', isValid});
      },
      deep: true
    }
  },
  mounted() {
    this.initProblemDescriptionData();
  },
  methods: {
    ...mapMutations('onlineForm', ['setValidFromStep']),
    setStepProblemDescriptionValues() {
      this.$root.$emit('ProblemDescription', this.describeProblem);
    },
    initProblemDescriptionData() {
      if (isNotFalsy(this.problem)) {
        this.describeProblem = this.problem;
      }
    },
    isFalsy,

  }
};
</script>

<style scoped>

</style>
