export const bookMeetingPage = {
    of: ' af ',
    title: 'Book et møde for at indberette din mistanke eller viden - læs mere om, hvordan vi ',
    link: 'beskytter din identitet',
    stepSubsidiaryCompany: {
        title: 'Hvilket selskab vedrører indberetningen?',
        subtitle: ' - Angiv hvilket selskab i koncernen din indberetning vedrører.',
    },
    stepWhistleblowerRelation: {
        title: 'Hvad er din relation til organisationen?',
        subtitle: 'Beskriv hvilken relation, du har til organisationen som din indberetning vedrører. F.eks. at du er' +
            ' ansat.',
        placeholder: 'Angiv relation...*',
    },
    stepProtectionOfficer: {
        title: 'Hvem skal modtage din indberetning på et fysisk møde?',
        subtitle: ' - Klik på den person eller enhed, du ønsker skal modtage din indberetning.',
    },
    stepIdentity: {
        title: 'Vil du oplyse din identitet?',
        subtitle: ' - Det er frivilligt om du vil oplyse din identitet.',
    },
    stepFollowReport: {
        title: 'Vælg kodeord for at følge op på din mødebooking',
        subtitle: '- Du skal oprette et kodeord, så du kan logge ind og følge op på din mødebooking.',
        sendFormButton: 'Få oplyst telefonummer',
    },
};
