import messageService from '@/services/auth/MessageService';
import caseService from '@/services/CaseService';
import {caseCreating, casePayloadResponse} from '@/model/casePayload';
import {caseStatus} from '@/model/caseStatusEvents';

const state = {
    casePayload: casePayloadResponse,
    updateCasePayload: casePayloadResponse,
    caseMessages: [],

    formDialogs: {
        successDialog: false,
        successDialogData: {},
        failureDialog: false,
    },
};

const getters = {
    getCasePayload: state => state.casePayload,
    getCaseNumber: state => state.casePayload?.caseNumber,
    getCaseMessages: state => state.caseMessages,
    getCaseFeedBackMessage: state => state.casePayload?.messages?.filter(el => el.type === 'FEEDBACK'),
    getCaseConfirmationMessage: state => state.casePayload?.messages?.filter(el => el.type === 'CONFIRMATION'),
    getCaseReceivedStatus: state => (state.casePayload?.caseStatusHistory.filter(el => el.status === caseStatus.RECEIVED)),
    getCaseEvents: state => state.casePayload?.caseStatusHistory,
    getHasStatusPendingWhistleblower: state =>
        (state.casePayload?.caseStatusHistory?.length === 1)
        && (state.casePayload?.caseStatusHistory[0].status === caseStatus.PENDING_WHISTLEBLOWER_CONTACT),

    getCanCaseBeModified: state => state?.casePayload?.submission !== caseCreating.ONLINE_FORM && state.casePayload.approvedAt === null,
    getProblemDescription: state => ({
        description: state?.casePayload?.description,
        violation: state?.casePayload?.violation,
        repeatedViolations: state?.casePayload?.repeatedViolations
    }),

    getFailureDialog: state => state.formDialogs.failureDialog,
};

const actions = {
    async getCaseWithAuthToken({commit, dispatch}) {
        dispatch('event/startLoading', null, {root: true});
        let caseCreated = await caseService.getCaseWithAuthToken();
        if (caseCreated != null) {
            commit('setCasePayload', caseCreated);
        }
        dispatch('event/stopLoading', null, {root: true});
    },

    async sendMessage({dispatch}, {message, files}) {
        dispatch('event/startLoading', null, {root: true});
        await messageService.sendMessage(message, files);
        dispatch('getCaseWithAuthToken');
        dispatch('event/stopLoading', null, {root: true});
    },

    async approveChanges({dispatch}) {
        dispatch('event/startLoading', null, {root: true});
        await caseService.approveChanges();
        dispatch('event/stopLoading', null, {root: true});
        await dispatch('getCaseWithAuthToken');
    },

    async updateWhistleBlowerIdentity({dispatch}, {whistleBlowerDto}) {
        dispatch('event/startLoading', null, {root: true});
        await caseService.updateWhistleBlower(whistleBlowerDto);
        dispatch('getCaseWithAuthToken');
        dispatch('event/stopLoading', null, {root: true});
    },

    async requestPasswordUpdate({dispatch}) {
        dispatch('event/startLoading', null, {root: true});
        await caseService.requestPasswordUpdate();
        dispatch('event/stopLoading', null, {root: true});
    },

    async retrieveCaseMessages({commit}) {
        let messages = await messageService.getMessages();
        commit('setCaseMessages', messages);
    },
};

const mutations = {
    setCasePayload(state, casePayload) {
        state.casePayload = casePayload;
        state.updateCasePayload = casePayload;
    },

    setCaseMessages: (state, messages) => state.caseMessages = messages,

    setSelectedProtectionOfficer: (state, protectionOfficer) => state.updateCasePayload.protectionOfficer = protectionOfficer,
    setWhistleblowerRelation: (state, relation) => state.updateCasePayload.whistleblowerRelation = relation,
    setProblemDescription: (state, problemDescription) => state.updateCasePayload = {...state.updateCasePayload, ...problemDescription,},
    setReportedPersons: (state, reportedPersons) => state.updateCasePayload.reportedPersons = reportedPersons,
    setFiles: (state, files) => state.updateCasePayload.files = files,
    setWhistleblowerIdentity: (state, identity) => state.updateCasePayload.whistleblower = identity,
};

export const authCase = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

