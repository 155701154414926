export const onlineFormPage = {
    of: ' av ',
    title: 'Rapporter mistanken din på nett. Les mer om hvordan vi ',
    link: 'beskytter identiteten din',
    stepProtectionOfficer: {
        title: 'Hvem skal motta rapporten din?',
        subtitle: ' - Trykk på personen eller enheten du vil at skal motta rapporten din.',
        oneProtectionOfficerTitle: 'Hvem skal motta rapporten din?',
        oneProtectionOfficerSubtitle: ' - Rapporten din vil bli sendt til følgende person',
    },
    stepWhistleblowerRelation: {
        title: 'Hva er din relasjon til organisasjonen?',
        subtitle: ' - Beskriv forholdet ditt til organisasjonen rapporten relaterer seg til, for eksempel at du er ansatt.',
        placeholder: 'Fyll inn relasjon...*',
    },
    stepDescribeProblem: {
        title: 'Hva vil du rapportere?',
        subtitle: ' - Du må beskrive hendelsen du vil rapportere. Vennligst gi så mange detaljer som mulig.',
    },
    stepSubsidiaryCompany: {
        title: 'Hvilket selskap gjelder varselet?',
        subtitle: ' - Vennligst velg hvilket selskap i konsernet varselet gjelder.',
        placeholder: 'Velg enhet...*',
    },
    stepReportRelated: {
        title: 'Hvem vil du rapportere?',
        subtitle: ' - Her må du angi hvem rapporten handler om',
    },
    stepDocumentUpload: {
        title: 'Legg ved relevant dokumentasjon i din besittelse',
        subtitle: ' - Du har anledning til å legge ved fler som dokumenterer den rapporterte hendelsen.',
    },
    stepIdentity: {
        title: 'Vil du avsløre identiteten din?',
        subtitle: ' - Det er valgfritt om du vil avsløre identiteten din. Vennligst angi valget ditt under.',
    },
    stepFollowReport: {
        title: 'Ønsker du å følge rapporten din?',
        subtitle: ' – Vennligst velg om du vil bli informert om saken og potensielt bidra med ytterligere informasjon. Du kan følge saken selv om du er anonym.',
        sendReportButton: 'Send',
    },
    save: 'Lagre'
};
