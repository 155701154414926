export const onlineFormPage = {
    of: ' av ',
    title: 'Rapportera misstänkt missförhålland via onlineformuläret – läs mer om hur vi ',
    link: ' skyddar din identitet',
    stepProtectionOfficer: {
        title: 'Vem ska ta emot din rapport?',
        subtitle: ' - Ange den person eller enhet som du vill ska ta emot din rapport.',
        oneProtectionOfficerTitle: 'Vem tar emot din rapport?',
        oneProtectionOfficerSubtitle: ' - Din rapport kommer att tas emot av följande person.',
    },
    stepWhistleblowerRelation: {
        title: 'Vad är din relation till organisationen?',
        subtitle: ' - Beskriv din relation till organisationen som din rapport handlar om. Till exempel att du är anställd.',
        placeholder: 'Ange din relation...*',
    },
    stepDescribeProblem: {
        title: 'Vad vill du rapportera?',
        subtitle: ' - Nedan beskriver du den situation som du önskar rapportera. Ge så många detaljer som möjligt.',
    },
    stepSubsidiaryCompany: {
        title: 'Vilket bolag gäller din rapport?',
        subtitle: ' - Ange det bolag inom koncernen som din rapport avser',
        placeholder: 'Välj verksamhet...*',
    },
    stepReportRelated: {
        title: 'Vem handlar rapporten om?',
        subtitle: ' - Här kan du ange vem rapporten handlar om.',
    },
    stepDocumentUpload: {
        title: 'Bifoga eventuell dokumentation för händelsen',
        subtitle: ' - Här har du möjlighet att bifoga filer som dokumenterar den aktuella händelsen.',
    },
    stepIdentity: {
        title: 'Vill du uppge din identitet?',
        subtitle: ' - Det är frivilligt för dig att uppge din identitet. Välj nedan.',
    },
    stepFollowReport: {
        title: 'Vill du följa din rapport?',
        subtitle: ' - Välj om du vill följa ärendet och eventuellt bidra med ytterligare  upplysningar. Du kan följa ärendet även om du har valt att vara anonym.',
        sendReportButton: 'Skicka',
    },
    save: 'Spara',
};
