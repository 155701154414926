<template>
  <div class="d-flex justify-center">
    <v-card elevation="0" width="80%">
      <div class="d-flex justify-center mt-8 mb-8">
        <span class="font-size-24 font-weight-bold">{{ $t('modal.newCaseCreated.thankYouSubmitting') }}</span>
      </div>
      <div class="d-flex justify-center mt-8 mb-8">
        <v-icon color="icons" size="50">mdi-check-circle-outline</v-icon>
      </div>
      <div class="d-flex justify-center mt-8 mb-8">
        <span class="font-size-18 font-weight-regular text-center">{{ $t('modal.newCaseCreated.thankYouSubmitting') }}</span>
      </div>
      <div class="d-flex justify-center mt-8 mb-8">
        <span class="font-size-18 font-weight-regular text-center">{{ $t('modal.newCaseCreated.youHaveChooseToNotFollow') }}</span>
      </div>
      <div class="d-flex justify-center mt-8 mb-8">
        <span class="font-size-18 font-weight-regular text-center">{{ $t('modal.newCaseCreated.weCanotContactYou') }}</span>
      </div>
      <div class="d-flex justify-center mt-8 mb-8">
        <span class="font-size-18 font-weight-regular text-center">{{ $t('modal.newCaseCreated.forOtherThingsYouShouldCreateANewReport') }}</span>
      </div>
      <div class="d-flex justify-center mt-8 mb-8">
        <div class="text-center">
          <span class="font-size-18 font-weight-regular">{{ $t('modal.newCaseCreated.saveTheNumberJustInCase') }}</span>
          <span class="font-size-18 font-weight-bold text-decoration-underline">{{ $t('modal.newCaseCreated.saveTheNumberJustInCase1') }}</span>
          <span class="font-size-18 font-weight-regular">{{ $t('modal.newCaseCreated.saveTheNumberJustInCase2') }}</span>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'CaseNotFollowed'
};
</script>

<style scoped>

</style>
