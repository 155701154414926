import SiteLookAndFeelService from '@/services/SiteLookAndFeelService';
import {AboutPageType, getNavPagesFromSiteLookAndFeel} from '@/model/getNavPages';
import {phoneCodeMappings} from '@/model/language/phoneCodeMappings';


const state = {
    lookAndFeel: {},
    logo: null
};

const getters = {
    getLogo: state => state.logo,
    getSiteColors: state => state.lookAndFeel?.siteColors,

    getLanguageCode: state => state.lookAndFeel.languageCode,
    getPhoneCodeMapping: state => phoneCodeMappings[state.lookAndFeel.languageCode],
    getSupportedLanguages: state => state.lookAndFeel.supportLanguages,

    getContactPhone: state => state.lookAndFeel.contactPhone,
    getContactPerson: state => state.lookAndFeel.contactPerson,
    getContactDetails: state => state.lookAndFeel.contactDetails,

    getSiteFrequentlyAskedQuestions: state => state.lookAndFeel.siteFAQPage,
    getSiteHowToReport: state => state.lookAndFeel.siteHowToReportPage,
    getSiteFrontPage: state => state.lookAndFeel.siteFrontPage,
    getNavPagesFromSiteLookAndFeel: state => getNavPagesFromSiteLookAndFeel(state.lookAndFeel),

    getSiteAboutPages: state => state.lookAndFeel.siteAboutPages,
    getSiteAboutPagesMyAnonymity: state => state.lookAndFeel.siteAboutPages?.filter(el => el.aboutPageType === AboutPageType.ANONYMITY)[0],
    getSiteAboutPagesCaseManagement: state => state.lookAndFeel.siteAboutPages?.filter(el => el.aboutPageType === AboutPageType.CASE_MANAGEMENT)[0],
    getSiteAboutPagesPersonalData: state => state.lookAndFeel.siteAboutPages?.filter(el => el.aboutPageType === AboutPageType.PERSONAL_DATA)[0],
};

const actions = {
    async retrieveSiteLookAndFeelWithCode({commit, dispatch}, {languageCode}) {
        let lookAndFeel = await SiteLookAndFeelService.getSiteLookAndFeelWithCode(languageCode);
        await commit('setSiteLookAndFeel', lookAndFeel);
        await dispatch('retrieveLogo', {fileName: lookAndFeel.logoFileName});
    },

    async retrieveLogo({commit}, {fileName}) {
        let logo = await SiteLookAndFeelService.getLogo(fileName);
        await commit('setLogo', logo);
    },
};

const mutations = {
    setSiteLookAndFeel: (state, lookAndFeel) => state.lookAndFeel = lookAndFeel,
    setLogo: (state, logo) => state.logo = logo,
};

export const lookAndFeel = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

