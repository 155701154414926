export const onlineFormPage = {
    of: ' of ',
    title: 'Report your suspicion online read more about how we ',
    link: ' protect your identity',
    stepProtectionOfficer: {
        title: 'Who should receive your report?',
        subtitle: ' - Click on the person or unit you want to receive your report.',
        oneProtectionOfficerTitle: 'Who should receive your report?',
        oneProtectionOfficerSubtitle: ' - Your report will  be sent to the following person',
    },
    stepWhistleblowerRelation: {
        title: 'What is your relationship to the organisation?',
        subtitle: ' - Describe your relationship to the organisation to whom your report relates. For example, that you are employed.',
        placeholder: 'Enter relation...*',
    },
    stepDescribeProblem: {
        title: 'What would you like to report?',
        subtitle: ' - You must describe the incident you want to report. Please give as many details as possible.',
    },
    stepSubsidiaryCompany: {
        title: 'Which company does the report relate to?',
        subtitle: ' - Please choose which company in the group your report relates to.',
        placeholder: 'Select entity...*',
    },
    stepReportRelated: {
        title: 'Who do you want to report?',
        subtitle: ' - You must now indicate who the report is about',
    },
    stepDocumentUpload: {
        title: 'Attach any relevant documentation in your possession',
        subtitle: ' - You have the option to attach files that document the reported incident.',
    },
    stepIdentity: {
        title: 'Do you want to reveal your identity?',
        subtitle: ' - It is optional if you want to reveal your identity. Please select your choice below.',
    },
    stepFollowReport: {
        title: 'Do you want to follow your report?',
        subtitle: ' – Please choose whether you want to be informed about the case and possible contribute with additional information. You can follow the case even if you are anonymous.',
        sendReportButton: 'Send',
    },
    save: 'Save'
};
