export const step = {
    describeProblem: {
        describe: 'Enter description*',
        choose: 'Choose... *',
        rulesViolated: 'What rules do you suspect have been violated?',
        isProblemRepeated: 'Has the incident happened several times?',
        howOftenIsRepeated: 'Please indicate in the description how often the incident has occurred',
        yes: 'Yes',
        no: 'No',
    },
    protectionOfficer: {
        'Ekstern': 'External',
        'Intern': 'Internal',
    },
    documentUpload: {
        max: 'Max',
        perFile: ' per file',
    },

    followReport: {
        yesFollowReport: 'Yes, I would like to follow the case',
        yesFollowTheCaseWithPassword: 'You will have the option to submit additional information and receive feedback via case no. and self-selected password',
        noDontFollowReport: 'No, I do not want to follow the case',
        noDontWantToHearFromCase: 'You will not receive feedback and information about the case after you have submitted your report',
        enterPassword: 'Choose a password*',
        confirmPassword: 'Re-enter password*',
        mustConfirmPassword: 'You must confirm the password',
        iAcceptTheTerms: 'I have read the ',
        iAcceptTheTerms1: 'whistleblower policy',
        logInIn10Days: 'Attention: ',
        logInIn10Days1: 'For security reasons, you must log-in within 10 days, or you user account will be deleted.',
    },

    howYouWantToMeet: {
        phone: 'Telefonisk',
        appointment: 'Fortael hvilke tidspunkter der passer dig et fysisk mode *',
    },

    identity: {
        yesRevealIdentity: 'Yes, I would like to disclose my identity',
        enterIdentity: 'Your identity is treated confidentially and only revealed to those who need to know it.',
        noDontRevealIdentity: 'No, I do not want to disclose my identity',
        stayAnonym: 'You are basically anonymous, but your report may reveal your possible identity. In that case, we treat it with confidentially',
        firstName: 'First and last name*',
        email: 'Email Address*',
        phoneNr: 'Phone Number',
        examplePhoneNr: 'Example Phone Number',
        wantToReceive: 'I want to receive email notifications',
        youNeedToSelectAnOption: '*Field required. Select an option.',
    },

    makeQuestions: {
        title: 'Ask questions about the use of the whistleblower scheme - read more about the ',
        whistleblowing: 'whistleblower scheme',
        phone: 'Phone',
        online: 'Online',
        callThisNumber: 'The phone is open every weekday 08.00-17.00.',
        defaultPhoneNumber: '+45 88 91 92 45',
        defaultContactPerson: 'Søren Wolder, Advokat, DAHL Advokatpartnerselskab',
        defaultContactInfo: 'The phone is open every weekday 08.00-17.00.',
    },

    reportRelated: {
        selectPersonsInCompany: 'Named persons',
        company: 'No named persons',
        youNeedToSelectAnOption: '*Field required. Select an option.',
        firstName: 'First and last name*',
        position: 'Job title',
        phoneNrOrEmail: 'Telephone number/email address ',
        addNewPerson: 'Add more persons',
        unit:'Department'
    }
};
