<template>
  <MainLayout>
    <div class="d-flex justify-center">
      <span class="primary--text font-size-24">{{ $t('misc.errorPage.somethingWentWrong.title') }}</span>
    </div>
    <div class="d-flex justify-center mt-6">
      <span class="primary--text font-size-24">{{ $t('misc.errorPage.somethingWentWrong.weAreWorkingOnIt') }}</span>
    </div>
    <div class="d-flex justify-center mt-6">
      <span class="font-size-20 primary--text">{{ $t('misc.errorPage.somethingWentWrong.tryAgain') }}</span>
      <span class="font-size-20 pl-1 text-decoration-underline c-point primary--text"
            @click="$router.push('/')"
      >{{ $t('misc.errorPage.somethingWentWrong.later') }}</span>
    </div>
    <IllustrationSmthWentWrong/>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layout/MainLayout';
import IllustrationSmthWentWrong from '@/components/illustration/IllustrationSmthWentWrong';

export default {
  name: 'SomethingWentWrong',
  components: {IllustrationSmthWentWrong, MainLayout}
};
</script>

<style scoped>

</style>
