import i18n from '@/i18n';

export const fileCategories = {
    document: {
        title: i18n.t('allPrivatePages.messages.nav.document'),
        icon: 'mdi-file',
        files: {fileCount: 0, fileSize: '', filesPerCategory: [],},
    },
    pictures: {
        title: i18n.t('allPrivatePages.messages.nav.pictures'),
        icon: 'mdi-image',
        files: {fileCount: 0, fileSize: '', filesPerCategory: [],},
    },
    video: {
        title: i18n.t('allPrivatePages.messages.nav.video'),
        icon: 'mdi-video',
        files: {fileCount: 0, fileSize: '', filesPerCategory: [],},
    },
    other: {
        title: i18n.t('allPrivatePages.messages.nav.other'),
        icon: 'mdi-file-multiple',
        files: {fileCount: 0, fileSize: '', filesPerCategory: [],},
    },
};
