<template>
  <div>
    <div class="private-page-title">{{ title }}</div>
    <div v-if="hasDivider" class="custom-divider"/>
  </div>
</template>

<script>
export default {
  name: 'PageHeaderTitle',
  props: {
    title: {
      type: String
    },
    hasDivider: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>

.private-page-title {
  font-size: 25px;
  line-height: 29px;
  color: var(--v-primary-base);
}

</style>
