import Vue from 'vue';
import VueRouter from 'vue-router';
import publicRoutes from './publicRoutes';
import privateRoutes from './privateRoutes';


Vue.use(VueRouter);

let routes = [
    ...privateRoutes,
    ...publicRoutes,
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes
});

export default router;
