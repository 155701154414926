export const landingPage = {
    actionCards: {
        title: 'Wie möchten Sie einen Hinweis melden?',
        onlineForm: {
            title: 'Online melden',
        },
        oralReport: {
            title: 'Telefonisch melden',
        },
        bookMeeting: {
            title: 'In einem Meeting melden',
        },
        askQuestions: {
            title: 'Fragen stellen',
        },
    }
};
