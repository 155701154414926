<template>
  <MainLayout>
    <StepMakeQuestion>
      <div slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('askQuestionsPage.stepMakeQuestion.title') }}
        </v-card-title>

        <v-card-subtitle class="word-brake ma-0 pa-0 mt-6">
          <span>{{ $t('askQuestionsPage.stepMakeQuestion.subtitleInfo1') }}</span>
          <span class="text-decoration-underline c-point" @click="openInNewTab">{{ $t('askQuestionsPage.stepMakeQuestion.faq') }}</span>
          <span>{{ $t('askQuestionsPage.stepMakeQuestion.subtitleInfo2') }}</span>
          <span>{{ $t('askQuestionsPage.stepMakeQuestion.subtitleInfo3') }}</span>
        </v-card-subtitle>
      </div>
    </StepMakeQuestion>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layout/MainLayout';
import StepMakeQuestion from '@/components/card/stepForm/StepMakeQuestion';

export default {
  name: 'AskQuestions',
  components: {
    StepMakeQuestion,
    MainLayout
  },
  methods:{
    openInNewTab() {
      let routeData = this.$router.resolve({name: 'FAQ'});
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style scoped>

</style>
