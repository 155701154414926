export const modal = {
    newCaseCreated: {
        whatShouldYouDo: 'Du måste nu göra följande:',
        thankYouSubmitting: 'Tack för din rapport!',
        caseNumber: 'Ärendenummer #',
        reportReceived: 'Din rapport är mottagen',
        saveCaseNumberAndLogWithIt: 'Spara nedanstående ärendenummer för att logga in och följa din anmälan.',
        readMoreHere: 'Läs mer om hur vi ',
        readMoreHere1: 'hanterar din rapport',
        weFollowUpWithin7Days: 'Vi bekräftar mottagande av din anmälan inom 7 dagar.',
        noNeedToDoMoreThings: 'Det är ',
        noNeedToDoMoreThings1: 'viktigt',
        noNeedToDoMoreThings2: ', att du sparar ditt ärendenummer och ditt lösenord.',
        caseWillBeHandledByCompanyWbUnit: 'Du måste ange dessa för att kunna logga in och följa ditt ärende.',
        loginVerify: 'Logga in och verifiera ditt konto.',
        callUs: 'Ring telefonnumret du fick.',
        logInAgainOn7Days: 'Logga in igen om 7 dagar för att se din bekräftelse.',
        loginInContinuously: 'Logga in löpande för att följa ditt ärende.',
        arrangeTime: 'Boka tid och plats.',

        youHaveChooseToNotFollow: 'Du har valt att inte följa din anmälan.',
        weCanotContactYou: 'Om du inte har lämnat uppgift om din identitet kan vi inte kontakta dig och du kommer inte höra från oss gällande ärendets handläggning.',
        forOtherThingsYouShouldCreateANewReport: 'Om du vid ett senare tillfälle vill komplettera din rapport ska du upprätta en ny rapport.',
        saveTheNumberJustInCase: 'Det är ',
        saveTheNumberJustInCase1: 'viktigt',
        saveTheNumberJustInCase2: ' att du sparar ditt ärendenummer. Detta är din dokumentation för din rapport.',

    },
    errorDialog: {
        unableToCreateCase: 'Kan inte upprätta rapport. Fyll i de nödvändiga uppgifterna och försök igen.',
        close: 'Stäng',
    },
    validationDialog: {
        title: 'Information saknas',
        completeInformationMissing: 'Fyll i formuläret med all nödvändig information.',
        ok: 'OK',
    },
    dataWillBeDiscardedDialog: {
        title: ' Om du fortsätter kommer den information som har lämnats att raderas.',
        ok: 'Fortsätt',
        close: 'Ångra',
    },
    caseNumber: 'Ärendenr',
    toTheFront: 'Till förstasidan',
    login: 'Logga in',
};
