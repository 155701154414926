export const bookMeetingPage = {
    of: ' av ',
    title: 'Avtal et møte for å rapportere mistanke eller kunnskap - les mer om hvordan vi ',
    link: 'beskytter din identitet',
    stepSubsidiaryCompany: {
        title: 'Hvilket selskap gjelder rapporten?',
        subtitle: ' - Velg hvilket selskap i gruppen rapporten din gjelder.',
        placeholder: 'Velg enhet...*',
    },
    stepProtectionOfficer: {
        title: 'Hvem skal motta rapporten din på et fysisk møte?',
        subtitle: ' - Trykk på personen eller enheten du vil at skal motta rapporten din.',
        oneProtectionOfficerTitle: 'Hvem skal motta rapporten din på et fysisk møte?',
        oneProtectionOfficerSubtitle: ' - Du vil kunne bestille et møte med følgende person',
    },
    stepIdentity: {
        title: 'Do you want to reveal your identity?',
        subtitle: ' - It is optional if you want to reveal your identity. Please select your choice below.',
    },
    stepWhistleblowerRelation: {
        title: 'Hva er din relasjon til organisasjonen?',
        subtitle: ' - Beskriv forholdet ditt til organisasjonen rapporten relaterer seg til, for eksempel at du er ansatt.',
        placeholder: 'Fyll inn relasjon...*',
    },
    stepFollowReport: {
        title: 'Velg et passord for å følge opp møtebestillingen din',
        subtitle: ' - Du må opprette et passord slik at du kan logge inn og følge opp møtebestillingen din.',
        sendFormButton: 'Få telefonnummer',
    },
};
