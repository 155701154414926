<template>
  <MainLayout>
    <PublicPageTitle :header-name="$t('publicNavPages.aboutWhistleBlowing.subPage.yourProtection')"/>
    <div>
      <div v-for="(item, index) in textSections" :key="index" class="mt-4">
        <div class="font-weight-bold">{{ item.heading }}</div>
        <div class="mt-2 with-linebreak">{{ item.paragraph }}</div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layout/MainLayout';
import PublicPageTitle from '@/components/headers/PublicPageTitle';
import {mapGetters} from 'vuex';
export default {
  name: 'Anonymity',
  components: {PublicPageTitle, MainLayout},
  computed: {
    ...mapGetters('lookAndFeel', ['getSiteAboutPagesMyAnonymity']),
    textSections() {
      return this.getSiteAboutPagesMyAnonymity?.textSections;
    }
  }
};
</script>

<style scoped>

</style>
