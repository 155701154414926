
import axios from 'axios';
import router from '@/router';

export const axiosAuthenticationInterceptor = () => {
    axios.interceptors.response.use(function (response) {
        return response;
    }, async function (error) {
        if (403 === error?.response?.status) {
            await router.push('/');
        }
        return error;
    });
};

export const axiosAuthorizationInterceptor = () => {
    axios.interceptors.response.use(function (response) {
        return response;
    }, async function (error) {
        if (401 === error?.response?.status) {
            await router.push('/');
        }
        return error;
    });
};
