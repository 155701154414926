export const modal = {
    newCaseCreated: {
        whatShouldYouDo: 'Du skal nu gøre følgende:',
        thankYouSubmitting: 'Tak for din indberetning',
        caseNumber: 'Sagsnummer #',
        reportReceived: 'Din indberetning er modtaget',
        saveCaseNumberAndLogWithIt: 'Gem nedenstående sagsnummer for at logge ind og følge din indberetning. Du logger ind på forsiden.',
        readMoreHere: 'Læs mere om hvordan vi ',
        readMoreHere1: 'behandler din indberetning',
        weFollowUpWithin7Days: 'Vi følger op på din indberetning inden for 7 dage.',
        noNeedToDoMoreThings: 'Det er ',
        noNeedToDoMoreThings1: 'vigtigt',
        noNeedToDoMoreThings2: ', at du gemmer dit sagsnummer og dit selvvalgte kodeord.',
        caseWillBeHandledByCompanyWbUnit: 'Du skal bruge oplysningerne for at kunne logge ind og følge din sag.',
        loginVerify: 'Log ind og verificere din konto',
        callUs: 'Ring til det oplyste telefonnummer',
        logInAgainOn7Days: 'Log ind igen om 7 dage for at se din bekræftelse',
        loginInContinuously: 'Log ind løbende for at følge sagen',
        arrangeTime: 'Aftal tid og sted',

        youHaveChooseToNotFollow: 'Du har valgt ikke at følge din indberetning.',
        weCanotContactYou: 'Hvis du ikke har oplyst din identitet, kan vi ikke kontakte dig, og du vil ikke høre fra os i forhold til sagens behandling.',
        forOtherThingsYouShouldCreateANewReport: 'Hvis du på et senere tidspunkt vil supplere din indberetning, skal du foretage en ny indberetning.',
        saveTheNumberJustInCase: 'Det er ',
        saveTheNumberJustInCase1: 'vigtigt',
        saveTheNumberJustInCase2: ', at du gemmer dit sagsnummer. Dette er din dokumentation for din indberetning. ',

    },
    errorDialog: {
        unableToCreateCase: 'Kan ikke oprette indberetningen. Udfyld de nødvendige oplysinger og prøv igen.',
        close: 'Luk',
    },
    validationDialog: {
        title: 'Oplysninger mangler',
        completeInformationMissing: 'Udfyld formularen med alle nødvendige oplysninger.',
        ok: 'Ok',
    },
    dataWillBeDiscardedDialog: {
        title: 'Hvis du fortsætter, vil de indtastede oplysninger blive kasseret',
        ok: 'Fortsæt',
        close: 'Fortryd',
    },
    caseNumber: 'Sagsnr',
    toTheFront: 'Til forsiden',
    login: 'Log ind',
};
