import KeyCloakService from '@/services/auth/KeyCloakService';
import {authHeaders} from '@/clients/headers/authHeader';
import {verifyTokenUsingGET} from '@/clients/wbBack/wbBack.api';


let verifyAndNavigate = async (next, token) => {
    let tokenVerification = await verifyTokenUsingGET({$config: authHeaders(token)});
    if (tokenVerification?.status === 200) {
        next();
    } else {
        next('/something-went-wrong');
    }
};

export const validatePrivateRoute = async (to, from, next) => {
    let isAuth = await KeyCloakService.isUserAuthenticated();

    let token = await KeyCloakService.getAuthToken();
    if (!isAuth) {
        try {
            await KeyCloakService.loginUser();
            await verifyAndNavigate(next, token);
        } catch (err) {
            next('/');
        }
    } else {
        await verifyAndNavigate(next, token);
    }
};

