<template>
  <v-card width="100%" min-height="60" class="mt-2 mb-0" elevation="0">
    <div class="d-flex justify-space-between align-center h-100-p">
      <div class="file-inputted-type-icon" style="width: 10%;">
        <v-icon size="35">mdi-image-area</v-icon>
      </div>
      <div style="width: 80%;" class="pr-3">
        <div class="d-flex justify-space-between">
          <span>{{ fileData.name }}</span>
          <span>{{ getSizeInMB() }}</span>
        </div>
      </div>
      <div style="width: 10%;">
        <div
            style="height: 23px; width: 23px"
            class="rounded-pill background d-flex justify-center align-center c-point"
            @click="$emit('removeFile', index)"
        >
          <v-icon size="19" color="icons">mdi-close</v-icon>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import {fileSize} from '@/utils/fileSize';

export default {
  name: 'FileDescriptionCard',
  props: {
    fileData: {
      type: [File, Object]
    },
    index: {
      type: Number
    }
  },
  methods: {
    getSizeInMB() {
      let bytes = this.fileData.size;
      return fileSize(bytes);
    }
  }
};
</script>

<style scoped></style>
