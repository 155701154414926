<template>
  <div class="text-center">
    <v-dialog
        v-model="getValidationErrorDialog"
        width="500"
        @click:outside="closeFormValidationError()"
    >
      <v-card>
        <v-card-title class="text-h5 primary lighten-3">
          <span class="white--text">{{ $t('modal.validationDialog.title') }}</span>
        </v-card-title>
        <v-card-text class="pa-4">
          {{ $t('modal.validationDialog.completeInformationMissing') }}
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="primary"
              text
              @click="closeFormValidationError()"
          >
            {{ $t('modal.validationDialog.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'FormValidationError',
  computed: {
    ...mapGetters('onlineForm', ['getValidationErrorDialog'])
  },
  methods: {
    ...mapActions('onlineForm', ['closeFormValidationError'])
  }
};
</script>

<style scoped>

</style>
