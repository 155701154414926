export const report = {
    reportDescription: {
        attachIfIsNecessary: 'Angehängter Beweis',
        caseNumber: 'Fallnummer',
        received: 'Erhalten',
        incidentReported: 'Beschreibung des gemeldeten Vorfalls',
        rulesViolated: 'Vermutlich verletzte Vorschriften',
        isIncidentRepeated: 'Der Vorfall ist wiederkehrend oder wiederholt',
        yes: 'Ja',
        no: 'Nein',
    },
    reportRelate: {
        reportRelateTo: 'Von der Meldung erfasste Personen / Abteilungen',
        personsInCompany: 'Benannte Personen:',
        company: 'Abteilungen',
    }
};
