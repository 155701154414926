export const publicNavPages = {
    home: {title: 'Strona główna',},
    howToReport: {title: 'Tak zgłaszasz',},
    aboutWhistleBlowing: {
        title: 'System informowania',
        subPage: {
            whistleblowerPolicy: 'Polityka informowania',
            yourProtection: 'Twoja ochrona',
            caseProcessing: 'Rozpatrywanie sprawy',
        },
    },
    faq: {title: 'FAQ',}
};
