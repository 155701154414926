import Vue from 'vue';
import Vuex from 'vuex';
import {onlineForm} from '@/store/modules/onlineForm.module';
import {event} from '@/store/modules/event.module';
import {protectionOfficer} from '@/store/modules/protectionOfficer.module';
import {authCase} from '@/store/modules/authCase.module';
import {lookAndFeel} from '@/store/modules/lookAndFeel.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        onlineForm,
        event,
        protectionOfficer,
        authCase,
        lookAndFeel,
    },
});
