export const publicNavPages = {
    home: {
        title: 'Hem',
    },
    howToReport: {
        title: 'Så här rapporterar du',
    },
    aboutWhistleBlowing: {
        title: 'Visselblåsarsystemet',
        subPage: {
            whistleblowerPolicy: 'Visselblåsarpolicy',
            yourProtection: 'Ditt skydd',
            caseProcessing: 'Ärendehantering',
        },
    },
    faq: {
        title: 'FAQ',
    }
};
