export const report = {
    reportDescription: {
        attachIfIsNecessary: 'Tożsamość zgłaszającego',
        caseNumber: 'Numer sprawy',
        received: 'Otrzymana',
        incidentReported: 'Opis wydarzenia',
        rulesViolated: 'Zasady, co do których istnieje podejrzenie, że zostały naruszone ',
        isIncidentRepeated: 'Wydarzenie miało miejsce wielokrotnie',
        yes: 'Tak',
        no: 'Nie',
    },
    reportRelate: {
        reportRelateTo: 'Osoby/jednostki ujęte w zgłoszeniu',
        personsInCompany: 'Imię i nazwisko osoby w firmie:',
        company: 'Firma',
    }
};
