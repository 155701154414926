export const tooltips = {
    whyIsInfoNeeded: {
        title: 'Hvorfor er denne informasjonen nødvendig?',
        tooltipInfo: 'Information',
    },
    subsidiary: {
        text: 'Vi må vite hvilket selskap i konsernet rapporten din gjelder, for å undersøke saken best mulig.'
    },
    whistleblowerRelation: {
        text: 'Varslerordningen kan kun brukes av gruppen personer som er spesifisert i retningslinjene for varsling.'
    },
    describeProblem: {
        text: 'For at vi skal kunne foreta en grundig etterforskning av rapporten din, er det viktig at vi har så mye og så detaljert informasjon som mulig. Du må derfor beskrive hva du rapporterer, når det skjedde, hvor det skjedde, hvem som var involvert, hva det involverer, om det er noen som har vært vitne til hendelsen, også videre.'
    },

    reportRelated: {
        text:'Vår etterforskning av rapporten din kan bli mye mer presist rettet hvis vi kjenner identiteten til personen eller personene som har utført de rapporterte handlingene. Hvis du ikke kjenner identiteten, men for eksempel vet at rapporten knytter seg til en spesifikk avdeling, er dette også verdifull kunnskap for oss når vi skal planlegge vår etterforskning.'
    },

    documentUpload: {
        text: 'Hvis du har bevis i tilknytning til rapporten din, er det av stor verdi for oss og vår etterforskning. Bevis kan være bilder, kopi av dokumenter, også videre.',
    },
    identity: {
        text: 'Våre forutsetninger for å etterforske rapporten din er generelt best hvis vi kjenner identiteten din. Vi håper at du vil avsløre identiteten din til oss, men det er valgfritt, og vi vil etterforske rapporten din uansett om vi kjenner identiteten din eller ikke.',
    }

};
