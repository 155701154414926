export const landingPage = {
    actionCards: {
        title: 'Hvordan vil du rapportere?',
        onlineForm: {
            title: 'Rapporter på nett',
        },
        oralReport: {
            title: 'Rapporter via telefon',
        },
        bookMeeting: {
            title: 'Rapporter via et møte',
        },
        askQuestions: {
            title: 'Still et spørsmål',
        },
    }
};
