<template>
  <v-app-bar
      app
      color="white"
      height="83"
      fixed
      elevate-on-scroll
  >
    <div class="toolbar-wrapper">
      <div class="d-flex justify-space-between">
        <div class="c-point mt-3">
          <img
              width="160"
              :src="getLogo"
              height="45"
              alt="Logo"
              @click="goToPage('/')"
          >
        </div>
        <div class="ml-2 d-flex justify-space-between">
          <div
              v-for="(page, index) in getNavPagesFromSiteLookAndFeel"
              :key="index"
              class="app-bar-text-menu truncate d-flex justify-center align-center"
          >
            <div v-if="page.children.length === 0" class="truncate" @click="goToPage(page.link)">
              {{ page.text }}
            </div>
            <v-menu
                v-else
                transition="slide-y-transition"
                bottom
                nudge-top="-52"
                content-class="elevation-2 rounded-t-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                    class="truncate"
                    v-bind="attrs"
                    v-on="on"
                >
                  {{ page.text }}
                  <v-icon>mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-list>
                <div
                    v-for="(child, index) in page.children"
                    :key="index"
                >
                  <v-list-item
                      v-if=" isVisible(child.pageType)"
                      class="c-point"
                      @click="goToPage(child.link)"
                  >
                    <v-list-item-title>{{ child.text }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
          </div>
        </div>

        <div class="d-flex align-center justify-center lang-select-width">
          <div class="d-flex align-center justify-space-between">
            <LanguageSelectForPublicPages class="mr-2 ml-2"/>

            <LoginButton/>
          </div>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import LoginButton from '@/components/button/LoginButton';
import {mapGetters} from 'vuex';
import LanguageSelectForPublicPages from "@/components/select/LanguageSelectForPublicPages.vue";

export default {
  name: 'AppBar',
  components: {
    LanguageSelectForPublicPages,
    LoginButton,
  },
  computed: {
    ...mapGetters('lookAndFeel', ['getSiteAboutPages', 'getLogo', 'getNavPagesFromSiteLookAndFeel']),
  },
  methods: {
    goToPage(link) {
      this.$router.push(link).catch(_ => _);
    },
    isVisible(type) {
      return this.getSiteAboutPages?.filter(el => el.aboutPageType === type)[0].visible;
    },
  }
};
</script>

<style scoped></style>
