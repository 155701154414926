<template>
  <div>
    <v-app-bar elevate-on-scroll class="mb-12 ">
      <v-spacer/>
      <LanguageSelectForPrivatePages class="language-dropdown"/>
    </v-app-bar>
    <div class="auth-layout-left-right-padding mb-12">
      <slot/>
    </div>
  </div>
</template>

<script>
import LanguageSelectForPrivatePages from "@/components/select/LanguageSelectForPrivatePages.vue";

export default {
  name: 'AuthLayout',
  components: {LanguageSelectForPrivatePages}
};
</script>

<style scoped>

v-app-bar {
  display: flex;
  justify-content: end;
}
.language-dropdown{

  max-width: 250px;
}

</style>
