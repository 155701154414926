<template>
  <div class="navigation-bar-wrapper">
    <v-app-bar
        height="83"
        color="white"
        fixed
        elevation="0"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <div class="mr-2 c-point mt-3">
        <img
            width="160"
            height="45"
            :src="getLogo"
            alt="Logo"
            @click="goToPage('/')"
        >
      </div>

      <v-spacer/>

      <div class="lang-select-width">
        <LoginButton/>
      </div>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        temporary
        app
        fixed
        width="280px"
    >
      <v-list dense style="position: fixed" width="280px">
        <div
            v-for="(page, index) in getNavPagesFromSiteLookAndFeel"
            :key="index"
        >
          <v-list-item
              v-if="page.children.length === 0"
              class="mt-2"
              @click="goToPage(page.link)"
          >
            <v-list-item-title>
              <span class="text-sm-h6">{{ page.text }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-group
              v-else
              :prepend-icon="page.icon"
              class="mt-2"
          >
            <template v-slot:activator>
              <v-list-item-title>
                <span class="text-sm-h6">
                  {{ page.text }}
                </span>
              </v-list-item-title>
            </template>

            <div
                v-for="(child, index) in page.children"
                :key="index"
            >
              <v-list-item
                  v-if="isVisible(child.pageType)"
                  @click="goToPage(child.link)"
              >
                <v-list-item-title class="pl-4">{{ child.text }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
        <v-list-item class="mt-2">
          <LanguageSelectForPublicPages/>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import LoginButton from '@/components/button/LoginButton';

import {mapGetters} from 'vuex';
import LanguageSelectForPublicPages from "@/components/select/LanguageSelectForPublicPages.vue";

export default {
  name: 'NavigationBar',
  components: {
    LanguageSelectForPublicPages,
    LoginButton
  },
  data: () => ({
    drawer: false,
    showAppBar: true,
  }),
  computed: {
    ...mapGetters('lookAndFeel', ['getSiteAboutPages', 'getLogo', 'getNavPagesFromSiteLookAndFeel']),
    isTabletOrMobile: {
      get() {
        return this.$vuetify.breakpoint.width < 1111;
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.handleScroll();
  },
  methods: {
    handleScroll() {
      if (this.isTabletOrMobile) {
        this.showAppBar = (window.scrollY > 0);
      } else {
        this.showAppBar = false;
      }
    },
    goToPage(link) {
      this.$router.push(link).catch(() => {
      });
    },
    isVisible(type) {
      return this.getSiteAboutPages?.filter(el => el.aboutPageType === type)[0].visible;
    }
  }
};
</script>

<style scoped>

</style>
