export const oralReportPage = {
    of: ' av ',
    title: 'Rapporter din mistanke eller kunnskap via telefon - les mer om hvordan vi ',
    link: 'beskytter din identitet',
    stepProtectionOfficer: {
        title: 'Hvem skal motta rapporten din?',
        subtitle: ' - Trykk på personen eller enheten du vil at skal motta rapporten din.',
        oneProtectionOfficerTitle: 'Hvem skal motta rapporten din?',
        oneProtectionOfficerSubtitle: ' - Rapporten din vil bli sendt til følgende person',
    },
    stepWhistleblowerRelation: {
        title: 'Hva er din relasjon til organisasjonen?',
        subtitle: ' - Beskriv forholdet ditt til organisasjonen rapporten relaterer seg til, for eksempel at du er ansatt.',
        placeholder: 'Fyll inn relasjon...*',
    },
    stepSubsidiaryCompany: {
        title: 'Hvilket selskap gjelder rapporten?',
        subtitle: ' - Velg hvilket selskap i gruppen rapporten din gjelder.',
        placeholder: 'Velg enhet...*',
    },
    stepIdentity: {
        title: 'Do you want to reveal your identity?',
        subtitle: ' - It is optional if you want to reveal your identity. Please select your choice below.',
    },
    stepFollowReport: {
        title: 'Velg passord for å logge på for å se rapporten din og for å følge saken',
        subtitle: ' - Du må velge et passord for å logge inn og kommentere, verifisere eller bekrefte din rapport.',
        sendReportButton: 'Få telefonnummer',
    },
};
