export const landingPage = {
    actionCards: {
        title: 'Hur vill du rapportera?',
        onlineForm: {
            title: 'Rapportera online',
        },
        oralReport: {
            title: 'Rapportera via telefon',
        },
        bookMeeting: {
            title: 'Rapportera på ett möte',
        },
        askQuestions: {
            title: 'Ställ en fråga',
        },
    }
};
