export const step = {
    describeProblem: {
        describe: 'Beskrivning*',
        choose: 'Välj... *',
        rulesViolated: 'Vilka regler misstänker du har överträtts?',
        isProblemRepeated: 'Har det skett flera gånger?',
        howOftenIsRepeated: 'Uppge i din beskrivning hur ofta missförhållandet ägt rum',
        yes: 'Ja',
        no: 'Nej',
    },
    protectionOfficer: {
        'Ekstern': 'Externt',
        'Intern': 'Internt',
    },
    documentUpload: {
        max: 'Max',
        perFile: ' pr. fil',
    },

    followReport: {
        yesFollowReport: 'Ja, jag vill gärna följa ärendet',
        yesFollowTheCaseWithPassword: 'Du får möjlighet att lämna in ytterligare information samt motta feedback via' +
            ' ärendenummer och självvalt lösenord.',
        noDontFollowReport: 'Nej, jag vill inte följa ärendet',
        noDontWantToHearFromCase: 'Du vill inte motta feedback och information om ärendet efter att du skickat in' +
            ' din rapport.',
        enterPassword: 'Välj lösenord*',
        confirmPassword: 'Upprepa lösenord*',
        mustConfirmPassword: 'Du måste upprepa kodordet',
        iAcceptTheTerms: 'Jag har läst och är bekant med ',
        iAcceptTheTerms1: 'visselblåsarpolicyn',
        logInIn10Days: 'OBS: ',
        logInIn10Days1: 'Av säkerhetsmässiga skäl måste du logga in inom 10 dagar, annars raderas din profil',
    },

    howYouWantToMeet: {
        phone: 'Via telefon',
        appointment: 'Ange vilka tidpunkter som passar dig för ett fysiskt möte *',
    },

    identity: {
        yesRevealIdentity: 'Ja, jag vill uppge min identitet',
        enterIdentity: 'Din identitet behandlas konfidentiellt och görs endast känd för dem som behöver veta den.',
        noDontRevealIdentity: 'Nej, jag vill inte uppge min identitet',
        stayAnonym: 'Du är som utgångspunkt anonym, men uppgifter i din rapport kan avslöja din identitet. I så fall' +
            ' behandlar vi den konfidentiellt.',
        firstName: 'För- och efternamn*',
        email: 'E-postadress*',
        phoneNr: 'Mobilnummer',
        examplePhoneNr: 'Exempel mobilnummer',
        wantToReceive: 'Ja, jag vill ha ett e-postmeddelande när det händer något i mitt ärende.',
        youNeedToSelectAnOption: '*Obligatoriskt fält. Du ska ange om du vill vara anonym eller om du vill uppge din identitet.',
    },

    makeQuestions: {
        title: 'Ställ frågor om användandet av visselblåsarsystemet – läs mer om ',
        whistleblowing: 'visselblåsarsystemet',
        phone: 'Via telefon',
        online: 'Online',
        callThisNumber: 'Telefontid vardagar 08.00-17.00.',
        defaultPhoneNumber: '+45 88 91 92 45',
        defaultContactPerson: 'Søren Wolder, Advokat, DAHL Advokatpartnerselskab',
        defaultContactInfo: 'Telefontid vardagar 08.00-17.00.',
    },

    reportRelated: {
        selectPersonsInCompany: 'Namngivna personer',
        company: 'Ej namngivna personer',
        youNeedToSelectAnOption: '*Obligatoriskt fält. Du ska ange om du vill vara anonym eller om du vill uppge din identitet.',
        firstName: 'För- och efternamn*',
        position: 'Arbetstitel',
        phoneNrOrEmail: 'Eventuellt telefonnummer/email till personen',
        addNewPerson: 'Lägg till person',
        unit: 'Avdelning',
    }
};
