export const report = {
    reportDescription: {
        attachIfIsNecessary: 'Bifogad dokumentation',
        caseNumber: 'Ärendenummer',
        received: 'Mottaget',
        incidentReported: 'Beskrivning av händelsen',
        rulesViolated: 'Regler som troligen har överträtts ',
        isIncidentRepeated: 'Händelsen har inträffat ett flertal gånger',
        yes: 'Ja',
        no: 'Nej',
    },
    reportRelate: {
        reportRelateTo: 'Personer/enheter som omfattas av rapporten',
        personsInCompany: 'Namngivna personer i verksamheten:',
        company: 'Verksamhet/enhet',
    }
};
