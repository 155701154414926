export const publicNavPages = {
    home: {title: 'Inicio',},
    howToReport: {title: 'Cómo presentar la denuncia',},
    aboutWhistleBlowing: {
        title: 'El sistema de denuncia de irregularidades',
        subPage: {
            whistleblowerPolicy: 'Política de denuncia de irregularidades',
            yourProtection: 'Tu protección',
            caseProcessing: 'Tramitación del expediente',
        },
    },
    faq: {title: 'Preguntas frecuentes',}
};
