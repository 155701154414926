export const rules = {
    input: {
        fieldIsRequired: '* felt påkrævet',
        validPhoneNumber: '* indtast gyldigt telefonnummer i format +45xxxxxxxx',
        validPhoneNumberOrEmail: 'Indtastede tekst skal være en e-mail eller et telefonnummer med format +45xxxxxxxx',
        email: 'E-mail skal være gyldig',
        min4Char: '* Dit kodeord skal være mindst 4 tegn',
        contentShouldNotBeLonger: 'Description must be 200 characters or less',
    }
};
