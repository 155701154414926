export const modal = {
    newCaseCreated: {
        whatShouldYouDo: 'Musisz teraz wykonać następujące czynności:',
        thankYouSubmitting: 'Dziękujemy za Twoje zgłoszenie',
        caseNumber: 'Numer sprawy #',
        reportReceived: 'Din indberetning er modtaget',
        saveCaseNumberAndLogWithIt: 'Gem nedenstående sagsnummer for at logge ind og følge din indberetning. Du logger ind på forsiden.',
        readMoreHere: 'Przeczytaj w jaki sposób ',
        readMoreHere1: 'rozpatrujemy Twoje zgłoszenie',
        weFollowUpWithin7Days: 'Vi følger op på din indberetning inden for 7 dage.',
        noNeedToDoMoreThings: 'Ważnym jest, że zachowasz numer Twojej sprawy i wybrany przez Ciebie kod.',
        noNeedToDoMoreThings1: '',
        noNeedToDoMoreThings2: '',
        caseWillBeHandledByCompanyWbUnit: 'Potrzebujesz tych informacji, aby się zalogować i śledzić Twoją sprawę. ',
        loginVerify: 'Zaloguj się i zweryfikuj swoje konto',
        callUs: 'Zadzwoń na uzyskany numer telefonu',
        logInAgainOn7Days: 'Zaloguj się ponownie za 7 dni aby zobaczyć potwierdzenie',
        loginInContinuously: 'Loguj się często aby śledzić sprawę',
        arrangeTime: 'Umów czas i miejsce',

        youHaveChooseToNotFollow: 'Zdecydowałeś, że nie chcesz śledzić swojego zgłoszenia.',
        weCanotContactYou: 'Jeśli nie podałeś swojej tożsamości, nie możemy się z Tobą skontaktować i nie otrzymasz od nas wiadomości z' +
            'przebiegu sprawy.',
        forOtherThingsYouShouldCreateANewReport: 'Jeśli chcesz uzupełnić swoje zgłoszenie w późniejszym terminie,' +
            ' musisz złożyć nowe zgłoszenie.',
        saveTheNumberJustInCase: 'Ważnym jest, abyś zachował numer sprawy. To jest Twoja dokumentacja zgłoszenia.',
        saveTheNumberJustInCase1: '',
        saveTheNumberJustInCase2: '',

    },
    errorDialog: {
        unableToCreateCase: 'Kan ikke oprette indberetningen. Udfyld de nødvendige oplysinger og prøv igen.',
        close: 'Luk',
    },
    validationDialog: {
        title: 'Oplysninger mangler',
        completeInformationMissing: 'Udfyld formularen med alle nødvendige oplysninger.',
        ok: 'Ok',
    },
    dataWillBeDiscardedDialog: {
        title: 'Hvis du fortsætter, vil de indtastede oplysninger blive kasseret',
        ok: 'Fortsæt',
        close: 'Fortryd',
    },
    caseNumber: 'Numer sprawy',
    toTheFront: 'Til forsiden',
    login: 'Logowanie',
};
