export const report = {
    reportDescription: {
        attachIfIsNecessary: 'Vedhæftet dokumentation',
        caseNumber: 'Sagsnummer',
        received: 'Modtaget',
        incidentReported: 'Beskrivelse af hændelsen',
        rulesViolated: 'Regler som formodes overtrådt',
        isIncidentRepeated: 'Hændelsen er sket flere gange',
        yes: 'Ja',
        no: 'Nej',
    },
    reportRelate: {
        reportRelateTo: 'Personer/enheder omfattet af indberetningen',
        personsInCompany: 'Navngivne personer i virksomheden:',
        company: 'Virksomheden',
    }
};
