export const landingPage = {
    actionCards: {
        title: 'How do you want to report?',
        onlineForm: {
            title: 'Report online',
        },
        oralReport: {
            title: 'Report by phone',
        },
        bookMeeting: {
            title: 'Report via a meeting',
        },
        askQuestions: {
            title: 'Ask a question',
        },
    }
};
