<template>
  <v-row class="d-flex justify-end mt-7" no-gutters>
    <div>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span
              class="information-text"
              v-bind="attrs"
              v-on="on"
          >
            {{ $t('tooltips.whyIsInfoNeeded.title') }}
            <v-icon class="mb-1" size="20" color="icons">mdi-help-circle-outline</v-icon>
          </span>
        </template>
        <span>
          <v-card
              class="mx-auto card-border-bottom"
              min-height="250"
              width="250"
              color="white"
              elevation="2"
              rounded
          >
            <v-system-bar height="35px" color="primary">
              <h4 style="color: white" class="word-brake">
                {{ $t('tooltips.whyIsInfoNeeded.tooltipInfo') }}
              </h4>
            </v-system-bar>
            <v-card-text class="mt-0">
              {{ text }}
            </v-card-text>
          </v-card>
        </span>
      </v-tooltip>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'StepInformationFooter',
  props: {
    title: {type: String},
    text: {type: String}
  }
};
</script>

<style scoped>

.v-tooltip__content {
  padding: 0 !important;
  opacity: 1 !important;
}

</style>
