export const rules = {
    input: {
        fieldIsRequired: '* felt påkrævet',
        validPhoneNumber: '* indtast gyldigt telefonnummer',
        validPhoneNumberOrEmail: 'Indtastede tekst skal være en e-mail eller et telefonnummer',
        email: 'E-mail skal være gyldig',
        min4Char: '* Tu contraseña debe contener al menos 4 caracteres',
        contentShouldNotBeLonger: 'Description must be 200 characters or less',
    }
};
