<template>
  <v-dialog
      v-model="getSuccessDialog"
      transition="dialog-top-transition"
      :retain-focus="false"
      :max-width="isMobile ? '80%' : '69%'"
      content-class="elevation-0"
      @click:outside="closeSuccessDialog()"
      @keydown.esc="closeSuccessDialog()"
  >
    <v-card flat class="pb-14">
      <div>
        <div class="report-received-wrapper">
          <v-card color="warningCustom" height="55" elevation="0" class="d-flex justify-center align-center">
            <div>
              <span class="primary--text font-size-24 font-weight-bold">
                {{ $t('modal.newCaseCreated.caseNumber') }}
              </span>
              <span class="primary--text font-size-24 font-weight-bold">{{ getCaseNumber }}</span>
            </div>
          </v-card>
        </div>
      </div>

      <div v-if="getCaseAccountFollow" class="pa-4 pb-0">
        <div class="d-flex justify-center mb-2">
          <div class="report-received text-center" style="width: 65%">
            <span class="primary--text font-weight-bold font-size-24">
              {{ $t('modal.newCaseCreated.thankYouSubmitting') }}
            </span>
          </div>
        </div>
        <div class="case-number">
          <div class="d-flex justify-center">
            <v-tooltip
                v-model="showTextCopied"
                top
                left
                :disabled="!showTextCopied"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-if="getCaseAccountFollow" class="mt-2"
                     v-bind="attrs"
                     v-on="on"
                >
                  {{ $t('modal.caseNumber') }}. #{{ getCaseNumber }}
                </div>
              </template>
              <span>Text Copied</span>
            </v-tooltip>

            <v-btn icon class="ml-2" @click="copyCaseNumber()">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="d-flex justify-center mt-4 pl-4 pr-4">
          <div class="follow-case-by-clicking">
            <span class="font-size-18 font-weight-regular">{{ $t('modal.newCaseCreated.noNeedToDoMoreThings') }}</span>
            <span class="font-size-18 font-weight-bold text-decoration-underline">{{ $t('modal.newCaseCreated.noNeedToDoMoreThings1') }}</span>
            <span class="font-size-18 font-weight-regular">{{ $t('modal.newCaseCreated.noNeedToDoMoreThings2') }}</span>
          </div>
        </div>
        <div class="d-flex justify-center mt-2 pl-4 pr-4">
          <div class="font-size-18 d-flex justify-center font-weight-regular primary--text">
            <span class="text-center">{{ $t('modal.newCaseCreated.caseWillBeHandledByCompanyWbUnit') }}</span>
          </div>
        </div>

        <div class="d-flex justify-center">
          <div class="d-flex ma-8 flex-wrap" :class="{'justify-center': isMobile, 'justify-space-between': !isMobile}"
               style="width: 80%"
          >
            <div class="pt-4 pb-4" style="max-width: 160px">
              <div class="d-flex justify-center">
                <v-icon color="icons" size="65">mdi-shield-lock-outline</v-icon>
              </div>
              <div class="d-flex justify-center mt-2">
                <span class="text-center primary--text">{{ $t('modal.newCaseCreated.loginVerify') }}</span>
              </div>
            </div>
            <v-divider v-if="!isMobile" class="primary mt-12"/>
            <div class="pt-4 pb-4" style="max-width: 180px">
              <div class="d-flex justify-center">
                <v-icon color="icons" size="65">mdi-email-open-outline</v-icon>
              </div>
              <div class="d-flex justify-center mt-2">
                <span class="text-center primary--text">{{ $t('modal.newCaseCreated.logInAgainOn7Days') }}</span>
              </div>
            </div>
            <v-divider v-if="!isMobile" class="primary mt-12"/>
            <div class="pt-4 pb-4" style="max-width: 150px">
              <div class="d-flex justify-center">
                <v-icon color="icons" size="65">mdi-magnify</v-icon>
              </div>
              <div class="d-flex justify-center mt-2">
                <span class="text-center primary--text">
                  {{ $t('modal.newCaseCreated.loginInContinuously') }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-center">
          <div class="follow-case-by-clicking">
            <span>{{ $t('modal.newCaseCreated.readMoreHere') }}</span>
            <span class="pl-1 text-decoration-underline c-point"
                  @click="openInNewTab"
            >
              {{ $t('modal.newCaseCreated.readMoreHere1') }}
            </span>
          </div>
        </div>
      </div>
      <div v-else>
        <CaseNotFollowed/>
      </div>
    </v-card>
    <div class="d-flex justify-center align-center">
      <v-btn
          color="primary"
          height="61"
          elevation="0"
          width="160"
          class="text-none mt-n8"
          @click="closeDialog()"
      >
        <span class="font-weight-bold font-size-16">
          {{ getCaseAccountFollow ? $t('modal.login') : $t('modal.toTheFront') }}
        </span>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import KeyCloakService from '@/services/auth/KeyCloakService';
import CaseNotFollowed from '@/components/card/infoCard/CaseNotFollowed';

export default {
  name: 'NewCaseCreated',
  components: {CaseNotFollowed},
  data() {
    return {
      showTextCopied: false,
    };
  },
  computed: {
    ...mapGetters('onlineForm', [
      'getSuccessDialog',
      'getSuccessDialogData',
      'getCaseAccountFollow',
      'getCaseProtectionOfficer',
    ]),
    getCaseNumber: {
      get() {
        return this.getSuccessDialogData?.caseDto?.caseNumber;
      }
    },
    isMobile: {
      get() {
        return this.$vuetify.breakpoint.width < 1111;
      }
    }
  },
  methods: {
    ...mapActions('onlineForm', ['closeSuccessDialog']),
    copyCaseNumber() {
      this.$copyText(this.getCaseNumber);
      this.showTextCopied = true;
      setTimeout(() => {
        this.showTextCopied = false;
      }, 2000);
    },
    async closeDialog() {
      if (this.getCaseAccountFollow) {
        await KeyCloakService.loginUser();
      } else {
        await this.closeSuccessDialog();
      }
    },
    openInNewTab() {
      let routeData = this.$router.resolve({name: 'CaseProcess'});
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style scoped></style>
