export const defaultLookAndFeel = {
    'languageCode': 'da',
    'languageName': 'Dansk',
    'logoFileName': null,
    'supportLanguages': [{'languageCode': 'da', 'languageName': 'Dansk'}],
    'siteColors': {'primaryColorCode': '#172a55', 'secondaryColorCode': '#ecf1f5', 'infoColorCode': '#ffffff'},
    'contactPhone': '',
    'contactPerson': '',
    'contactDetails': '',
    'siteHowToReportPage': {
        'heading': 'Sådan indberetter du',
        'textSections': [{'heading': null, 'paragraph': null}]
    },
    'siteFrontPage': {
        'heading': 'Whistleblowerordning',
        'subHeading': null,
        'paragraph': null,
        'showVerbalOption': true,
        'showBookingOption': true
    },
    'siteFAQPage': {
        'heading': 'Frequently Asked Questions',
        'faqs': [{'heading': null, 'paragraph': null}]
    },
    'siteAboutPages': [{
        'aboutPageType': 'PERSONAL_DATA',
        'heading': 'Persondata',
        'visible': true,
        'textSections': [{'heading': null, 'paragraph': null}]
    }, {
        'aboutPageType': 'ANONYMITY',
        'heading': 'Din anonymitet',
        'visible': true,
        'textSections': [{'heading': null, 'paragraph': null}]
    }, {
        'aboutPageType': 'CASE_MANAGEMENT',
        'heading': 'Sagsbehandling',
        'visible': true,
        'textSections': [{'heading': null, 'paragraph': null}]
    }]
};
