export const oralReportPage = {
    of: ' de ',
    title: 'Denuncia tu sospecha o conocimiento por teléfono –Obtén más información de cómo ',
    link: 'protegemos tu identidad',
    stepSubsidiaryCompany: {
        title: '¿A qué sociedad se refiere la denuncia?',
        subtitle: ' - Indica la sociedad del Grupo a la que se refiere tu denuncia.',
        placeholder:'Elige la sociedad...*',
    },
    stepProtectionOfficer: {
        title: '¿Quién deseas que reciba tu denuncia?',
        subtitle: ' - Haz clic en la persona o unidad que deseas que reciba tu denuncia.',
        oneProtectionOfficerTitle: '¿A quién va dirigida la denuncia?',
        oneProtectionOfficerSubtitle: ' - Haz clic en la persona o unidad que deseas que reciba tu denuncia.',
    },
    stepWhistleblowerRelation: {
        title: '¿Cuál es tu relación con la organización?',
        subtitle: 'Describe la relación que tienes con la organización a la que se refiere tu denuncia. Por ejemplo,' +
            ' si eres empleado.',
    },
    stepIdentity: {
        title: '¿Deseas dar a conocer tu identidad?',
        subtitle: ' - La revelación de tu identidad es voluntaria. Selecciona a continuación:',
    },
    stepFollowReport: {
        title: 'Elige tu contraseña para iniciar sesión en relación con tu denuncia',
        subtitle: ' - Elige tu contraseña para iniciar sesión en relación con tu denuncia.',
        sendReportButton: 'Pide un número de teléfono',
    },
};
