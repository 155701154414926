export const allPrivatePages = {
    caseStatus: {
        statusOfCaseNumber: 'Status på din sak ID #',
        caseWillBeInitiated: 'Follow-up wil be initiated when you make contact via the phone number provided to you during the submission.',
        provideFeedback: 'Vi afventer din tilbagemelding',
        goToMessages: 'Go to messages',
        provideFeedbackTillDate: 'Tilbakemelding',
        provideConfirmationDate: 'Kvittering',
    },
    messages: {
        title: 'Meldinger',
        nav: {
            sharedFiles: 'Delte filer',
            allFiles: 'Alle filer',
            filterFiles: 'Filer',
            files: 'filer',
            document: 'Documenter',
            pictures: 'Bilder',
            video: 'Video',
            other: 'Annet',
        },
        chat: {
            writeCommentHere: 'Skriv din kommentar her...',
            addToCase: 'Add to case',
        }
    },
    seeReport: {
        submitComments: 'Send inn kommentarer',
        approve: 'Enig',

        commentsOnReport: {
            title: 'Bemærkninger til din indberetning',
            comment: 'Skriv dine bemærkninger til indberetningen og øvrige oplysninger her…',
            send: 'Send',
        },
        approveDialog: {
            title: 'Bekreft innholdet i rapporten',
            description: 'Er rapporten korrekt beskrevet? Du kan fortsatt sende meldinger om saken under "Meldinger"',
            cancel: 'Avbryt',
            confirm: 'Bekreft',
        }
    },
    yourInfo: {
        title: 'Hvis du ikke tidligere har avslørt identiteten din, har du mulighet til det under',
        save: 'Lagre',
        cancel: 'Tilbake',
        errorMessage: 'Invalid information. Try again.',
    },
    changePassword: {
        title: 'Endre passord',
        description: 'Hvis du vil endre ditt passord, vennligst velg "endre passord":',
        changePassword: 'Endre passord',
        dialog: {
            title: 'Endre passord til saken din',
            whatShouldYouDo: 'Du må nå gjøre følgende:',
            step1: 'Logg ut med knappen under',
            step2: 'Logg inn på saken din',
            step3: 'Tast inn ditt nye passord',
            descText1: 'Av sikkerhetsgrunner må du logge ut for å endre ditt passord.',
            descText2: 'Bekreft at du ønsker å endre ditt passord, og logg ut med knappen under.',
            btnApprove: 'Bekreft og logg ut',
        }
    },

};
