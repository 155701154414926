import Keycloak from 'keycloak-js';
import Vue from 'vue';


export const registerKeyCloak = (initOptions) => {
    const _keycloak = Keycloak(initOptions);

    const KeyCloakPlugin = {
        install: Vue => {
            Vue.$keycloak = _keycloak;
        },
    };

    KeyCloakPlugin.install = Vue => {
        Vue.$keycloak = _keycloak;
        Object.defineProperties(Vue.prototype, {
            $keycloak: {
                get() {
                    return _keycloak;
                },
            },
        });
    };

    Vue.use(KeyCloakPlugin);

    return _keycloak;
};
