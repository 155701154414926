<template>
  <div class="mb-6"
       :class="{
         'mr-2 ml-2': $vuetify.breakpoint.mdAndDown,
         'mr-9': $vuetify.breakpoint.mdAndUp
       }"
  >
    <v-card
        width="280"
        max-height="150"
        min-height="50"
        :height="!isMobile ? '150': '90'"
        rounded="1"
        elevation="0"
        :to="path"
        class="card-border-bottom"
        :class="{'d-flex pa-1': isMobile, 'pa-3': !isMobile}"
    >
      <div class="pt-4 pl-4">
        <slot/>
      </div>
      <v-card-text class="pa-0 d-flex align-center">
        <v-card-title
            class="card-title mt-0 pt-2 primary--text truncate"
            :class="{'pa-0 pr-3 pl-4 small-card-text': isMobile}"
        >
          <span>{{ title }}</span>
        </v-card-title>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'GoToCaseCard',
  props: {
    path: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>

.small-card-text {
  font-size: 16px;
}
</style>
