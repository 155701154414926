export const step = {
    describeProblem: {
        describe: 'Fyll inn beskrivelse...*',
        choose: 'Velg... *',
        rulesViolated: 'Hvilke regler mistenker du at har blitt overtrådt?',
        isProblemRepeated: 'Har hendelsen skjedd flere ganger?',
        howOftenIsRepeated: 'Angi i beskrivelsen hvor ofte hendelsen har skjedd',
        yes: 'Ja',
        no: 'Nei',
    },
    protectionOfficer: {
        'Ekstern': 'Eksternt',
        'Intern': 'Internt',
    },
    documentUpload: {
        max: 'Max',
        perFile: ' per fil',
    },

    followReport: {
        yesFollowReport: 'Ja, jeg ønsker å følge saken',
        yesFollowTheCaseWithPassword: 'Du vil få muligheten til å legge til ytterligere informasjon og motta tilbakemelding via saksnummer og egetvalgt passord',
        noDontFollowReport: 'Nei, jeg ønsker ikke å følge saken',
        noDontWantToHearFromCase: 'Du vil ikke motta tilbakemelding og informasjon om saken etter at du har levert' +
            ' rapporten.',
        enterPassword: 'Velg passord*',
        confirmPassword: 'Skriv inn passord på nytt*',
        mustConfirmPassword: 'Du må bekrefte passordet',
        iAcceptTheTerms: 'Jeg har lest ',
        iAcceptTheTerms1: 'retningslinjene for varsling',
        logInIn10Days: 'Merk: ',
        logInIn10Days1: 'Av sikkerhetsgrunner må du logge inn innen 10 dager, ellers vil brukeren bli slettet.',
    },

    howYouWantToMeet: {
        phone: 'Telefonisk',
        appointment: 'Fortael hvilke tidspunkter der passer dig et fysisk mode *',
    },

    identity: {
        yesRevealIdentity: 'Ja, jeg vil avsløre identiteten min',
        enterIdentity: 'Identiteten din blir behandlet konfidensielt og blir kun avslørt til de som trenger å vite det.',
        noDontRevealIdentity: 'Nei, jeg vil ikke avsløre identiteten min',
        stayAnonym: 'Du er i utgangspunktet anonym, men rapporten vil kanskje avsløre din mulige identitet. I alle tilfeller behandler vi rapporten konfidensielt.',
        firstName: 'Fornavn og etternavn*',
        email: 'E-post adresse*',
        phoneNr: 'Telefonnummer',
        examplePhoneNr: 'Eksempel',
        wantToReceive: 'Jeg vil motta varslinger på e-post',
        youNeedToSelectAnOption: '*Obligatorisk. Velg et alternativ.',
    },

    makeQuestions: {
        title: 'Still spørsmål om varslerordningen - les mer om ',
        whistleblowing: 'varslerordningen',
        phone: 'Phone',
        online: 'Online',
        callThisNumber: 'The phone is open every weekday 08.00-17.00.',
        defaultPhoneNumber: '+45 88 91 92 45',
        defaultContactPerson: 'Søren Wolder, Advokat, DAHL Advokatpartnerselskab',
        defaultContactInfo: 'The phone is open every weekday 08.00-17.00.',
    },

    reportRelated: {
        selectPersonsInCompany: 'Navngitte personer',
        company: 'Ikke-navngitte personer',
        youNeedToSelectAnOption: '*Obligatorisk. Velg et alternativ.',
        firstName: 'Fornavn og etternavn*',
        position: 'Jobbtittel',
        phoneNrOrEmail: 'Telefonnummer/e-post adresse ',
        addNewPerson: 'Legg til flere personer',
        unit:'Avdeling'
    }
};
