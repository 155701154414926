import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#172A55',
                secondary: '#1F467B',
                lightPrimary: '#E5F1FC',
                selectedOptionColor: '#E5F1FC',
                accent: '#82B1FF',
                gray: '#EBF0F4',
                error: '#A30404',
                icons: '#2196F3',
                success: '#4CAF50',
                warning: '#FFE798',
                warningCustom: '#FFE798',
                background: '#ECF1F5',
                fontFamily: 'Roboto',
            }
        }
    },
});
