<template>
  <v-card
      id="stepWhistleblowerRelation"
      class="mb-15 mt-15 card-border-bottom"
      min-height="270px"
      max-width="100%"
      rounded
      elevation="2"
  >
    <div class="card-inner-padding">
      <div>
        <slot name="card-title"/>
        <v-text-field
            v-model="whistleblowerRelation"
            :rules="inputRules.fieldRequiredRules"
            :placeholder="$t('onlineFormPage.stepWhistleblowerRelation.placeholder')"
            :error="displayFormValidationError && isFalsy(whistleblowerRelation)"
            outlined
            @input="setWhistleblowerRelationValue()"
        />
      </div>
      <v-row class="d-flex justify-space-between mt-0">
        <v-col cols="12" lg="6" md="6" sm="12"/>
      </v-row>
      <StepInformationFooter :text="$t('tooltips.whistleblowerRelation.text')"/>
    </div>
  </v-card>
</template>

<script>
import StepInformationFooter from '@/components/tooltip/StepInformationTooltip';
import {fieldRequiredRules} from '@/utils/rules/inputRules';
import {mapGetters, mapMutations} from 'vuex';
import {isFalsy, isNotFalsy} from '@/utils/guards';

export default {
  name: 'StepWhistleblowerRelation',
  components: {StepInformationFooter},
  data() {
    return {
      whistleblowerRelation: null,
      inputRules: {
        fieldRequiredRules
      },
    };
  },
  computed: {
    ...mapGetters('onlineForm', ['getFormValidation', 'getShowFormValidationErrorDialog']),
    displayFormValidationError() {
      if (this.getShowFormValidationErrorDialog) {
        return !this.getFormValidation.stepWhistleblowerRelation.isValid;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations('onlineForm', ['setValidFromStep']),
    setWhistleblowerRelationValue() {
      this.setValidFromStep({validStep: 'stepWhistleblowerRelation', isValid: isNotFalsy(this.whistleblowerRelation)});
      this.$root.$emit('WhistleblowerRelation', this.whistleblowerRelation);
    },
    isFalsy
  },
};
</script>

<style scoped>

</style>
