<template>
  <AuthLayout class="mt-n1">
    <div>
      <SuggestChangesDialog ref="suggestChangesDialog"/>

      <ApproveCaseDialog ref="approveChangesDialog"/>
    </div>

    <ReportDescriptionInfoCard/>

    <UserReportedInfoCard/>

    <FilesEnteredInfoCard/>

    <YourIIdentityInfoCard/>

    <div v-if="getCanCaseBeModified" class="d-flex justify-end mt-14" style="width: 100%;">
      <div class="d-flex">
        <div class="mr-4 white">
          <v-btn class="text-none " outlined color="primary" elevation="0" height="45" min-width="250"
                 @click="openModal()"
          >
            <span style="font-size: 16px">{{ $t('allPrivatePages.seeReport.submitComments') }}</span>
          </v-btn>
        </div>
        <div>
          <v-btn class="text-none ml-4" color="primary" elevation="0" height="45" min-width="150"
                 @click="openApproveChangesDialog"
          >
            <span style="font-size: 16px">{{ $t('allPrivatePages.seeReport.approve') }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import ReportDescriptionInfoCard from '@/components/card/infoCard/ReportDescriptionInfoCard';
import UserReportedInfoCard from '@/components/card/infoCard/UserReportedInfoCard';
import AuthLayout from '@/components/layout/AuthLayout';
import FilesEnteredInfoCard from '@/components/card/infoCard/FilesEnteredInfoCard';
import YourIIdentityInfoCard from '@/components/card/infoCard/YourIIdentityInfoCard';
import SuggestChangesDialog from '@/components/modal/authCase/SuggestChangesDialog';
import ApproveCaseDialog from '@/components/modal/authCase/ApproveCaseDialog';


export default {
  name: 'SeeReport',
  components: {
    ApproveCaseDialog,
    SuggestChangesDialog,
    YourIIdentityInfoCard,
    FilesEnteredInfoCard,
    AuthLayout,
    UserReportedInfoCard,
    ReportDescriptionInfoCard,
  },
  computed: {
    ...mapGetters('authCase', ['getCanCaseBeModified', 'getCasePayload', 'getProblemDescription'])
  },
  mounted() {
    this.listenForValueChange();
  },
  methods: {
    ...mapMutations('authCase', [
      'setSelectedProtectionOfficer',
      'setProblemDescription',
      'setReportedPersons',
      'setFiles',
      'setWhistleblowerIdentity',
    ]),
    listenForValueChange() {
      this.$root.$on('SelectedChiefOfficer', this.setSelectedChiefOfficer);
      this.$root.$on('ProblemDescription', this.setProblemDescription);
      this.$root.$on('ReportedPersons', this.setReportedPersons);
      this.$root.$on('FileUploaded', this.setFiles);
      this.$root.$on('WhistleblowerIdentity', this.setWhistleblowerIdentity);
    },
    openModal() {
      this.$refs.suggestChangesDialog.openSuggestChangesDialog();
    },

    openApproveChangesDialog() {
      this.$refs.approveChangesDialog.openApproveDialog();
    }
  },
};
</script>

<style scoped></style>
