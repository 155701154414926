<template>
  <MainLayout>
    <PublicPageTitle :header-name="$t('publicNavPages.aboutWhistleBlowing.subPage.whistleblowerPolicy')"/>
    <div>
      <div v-for="(item, index) in textSections" :key="index" class="mt-4">
        <div class="font-weight-bold">{{ item.heading }}</div>
        <div class="mt-2 with-linebreak">{{ item.paragraph }}</div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import PublicPageTitle from '@/components/headers/PublicPageTitle';
import MainLayout from '@/components/layout/MainLayout';
import {mapGetters} from 'vuex';

export default {
  name: 'PersonalData',
  components: {MainLayout, PublicPageTitle},
  computed: {
    ...mapGetters('lookAndFeel', ['getSiteAboutPagesPersonalData']),
    textSections() {
      return this.getSiteAboutPagesPersonalData?.textSections;
    }
  },
};
</script>

<style scoped>

</style>
