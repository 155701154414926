import protectionOfficerService from '@/services/ProtectionOfficerService';

const state = {
    allProtectionOfficers: [],
    companyInfo: {}
};

const getters = {
    getAllProtectionOfficers: state => state.allProtectionOfficers,
    getCompanyInfo: state => state.companyInfo,
    hasSubsidiaries: state => state.companyInfo?.subsidiaries?.length > 0,
};

const actions = {
    async retrieveCompanyInfo({commit}) {
        let companyInfo = await protectionOfficerService.getCompanyInfo();
        commit('setAllProtectionOfficer', companyInfo?.protectionOfficers);
        commit('setCompanyInfo', companyInfo);
    }
};

const mutations = {
    setAllProtectionOfficer(state, protectionOfficers) {
        state.allProtectionOfficers = protectionOfficers;
    },

    setCompanyInfo(state, companyInfo) {
        state.companyInfo = companyInfo;
    }
};

export const protectionOfficer = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

