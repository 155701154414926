export const onlineFormPage = {
    of: ' z ',
    title: 'Zgłoś Twoje podejrzenia za pomocą formularza online – dowiedz się, jak ',
    link: 'chronimy Twoją tożsamość',
    stepProtectionOfficer: {
        title: 'Kto otrzyma Twoje zgłoszenie?',
        subtitle: ' - Kliknij na osobę lub jednostkę, która ma otrzymać Twoje zgłoszenie',
        overallDescription: ' Du er nu i gang med at indberette din mistanke via en online formular. Herunder skal du oplyse, om du vil opgive din indentiet og følge sagen mv.',
        oneProtectionOfficerTitle: 'Kto otrzyma Twoje zgłoszenie?',
        oneProtectionOfficerSubtitle: ' - Twoje zgłoszenie zostanie wysłane do następującej osoby',
    },
    stepWhistleblowerRelation: {
        title: 'Jakie jest Twoje powiązanie z organizacją?',
        subtitle: ' Opisz jakie masz powiązanie z organizacją, której Twoje zgłoszenie dotyczy. Np. że jesteś' +
            ' zatrudniony.',
        placeholder: 'Podaj powiązanie...*',
    },
    stepDescribeProblem: {
        title: 'Co chcesz zgłosić?',
        subtitle: ' -  Poniżej opisz sytuację, którą chcesz zgłosić. Podaj jak najwięcej szczegółów.',
    },
    stepSubsidiaryCompany: {
        title: 'Kogo dotyczy zgłoszenie?',
        subtitle: ' - Opisz sytuacje, które chcesz zgłosić. Podaj jak najwięcej szczegółów (kto, co, gdzie, kiedy).',
        placeholder:'Wybierz firmę ..*',
    },
    stepReportRelated: {
        title: 'Kogo dotyczy zgłoszenie?',
        subtitle: ' - Musisz teraz określić, kogo dotyczy zgłoszenie.',
    },
    stepDocumentUpload: {
        title: 'Załącz ewentualnie dokumentację powiązaną z wydarzeniem',
        subtitle: ' - Tutaj masz możliwość załączyć pliki dokumentujące opisane wydarzenie',
    },
    stepIdentity: {
        title: 'Czy chcesz ujawnić swoją tożsamość?',
        subtitle: ' - Ujawnienie tożsamości jest dobrowolne. Wybierz poniżej.',
    },
    stepFollowReport: {
        title: 'Czy chcesz śledzić Twoje zgłoszenie?',
        subtitle: ' - Wybierz czy chcesz być informowany o sprawie i ewentualnie podać dodatkowe informacje. Możesz' +
            ' śledzić też sprawę anonimowo.',
        sendReportButton: 'Wyślij',
    },

    save: 'Gem'
};
