export const bookMeetingPage = {
    of: ' de ',
    title: 'Solicita una reunión para denunciar tu sospecha o conocimiento –Obtén más información de cómo ',
    link: 'protegemos tu identidad',
    stepSubsidiaryCompany: {
        title: '¿A qué sociedad se refiere la denuncia?',
        subtitle: ' - Indica la sociedad del Grupo a la que se refiere tu denuncia.',
        placeholder:'Elige la sociedad...*',
    },
    stepWhistleblowerRelation: {
        title: '¿Cuál es tu relación con la organización?',
        subtitle: 'Describe la relación que tienes con la organización a la que se refiere tu denuncia. Por ejemplo,' +
            ' si eres empleado.',
    },
    stepProtectionOfficer: {
        title: '¿Quién deberá recibir tu denuncia en una reunión física?',
        subtitle: ' - Haz clic en la persona o unidad que deseas que reciba tu denuncia.',
    },
    stepIdentity: {
        title: '¿Deseas dar a conocer tu identidad?',
        subtitle: ' - La revelación de tu identidad es voluntaria. Selecciona a continuación:',
    },
    stepFollowReport: {
        title: 'Elige una contraseña para hacer un seguimiento de tu solicitud de mantener una reunión',
        subtitle: '- Debes crear una contraseña para poder iniciar sesión y hacer un seguimiento de tu solicitud de mantener una reunión.',
        sendFormButton: 'Pide un número de teléfono',
    },
};
