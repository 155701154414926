import i18n, {appLangCode} from '@/i18n';
import {browserLanguageCodeMappings} from '@/model/language/phoneCodeMappings';
import {isFalsy} from '@/utils/guards';

class LanguageStorageProvider {

    setLanguage(code) {
        i18n.locale = code;
        localStorage.setItem(appLangCode, code);
    }

    getLanguage(companyInfo) {
        let langCode = localStorage.getItem(appLangCode);
        if (isFalsy(langCode)) {
            langCode = this.getLanguageFromBrowserMapping(companyInfo);
            localStorage.setItem(appLangCode, langCode);
        }

        i18n.locale = langCode;
        return langCode;
    }

    getLanguageFromBrowserMapping(companyInfo) {
        const langCodeFromBrowserDefault = browserLanguageCodeMappings[navigator.language];
        let isLangSupported = companyInfo?.availableLanguages?.includes(langCodeFromBrowserDefault);
        if (isLangSupported) {
            return langCodeFromBrowserDefault;
        }
        return companyInfo.defaultLanguage;
    }
}

export default new LanguageStorageProvider();
