import axios from 'axios';
import keyCloakService from '@/services/auth/KeyCloakService';
import {authHeaders} from '@/clients/headers/authHeader';
import {getDomain, getMessagesUsingGET} from '@/clients/wbBack/wbBack.api';

class MessageService {
    async sendMessage(message, files) {
        try {
            let formData = this.createFromData(message, files);

            let token = await keyCloakService.getAuthToken();
            let {headers} = authHeaders(token);
            let domain = getDomain();
            let sendMessageRequest = await axios({
                method: 'post',
                url: `${domain}/api/private/case/messages/new`,
                data: formData,
                headers
            });
            return sendMessageRequest?.data;
        } catch {
            return null;
        }
    }

    async getMessages() {
        try {
            let authToken = await keyCloakService.getAuthToken();
            let allMessages = await getMessagesUsingGET({
                $config: authHeaders(authToken)
            });
            return allMessages?.data;
        } catch {
            return [];
        }
    }

    createFromData(messageDto, files) {
        const formData = new FormData();
        formData.append('messageDto', new Blob([JSON.stringify(messageDto)], {type: 'application/json'}));

        if (files.length > 0) {
            files.forEach(file => {
                formData.append('files', new Blob([file], {type: 'multipart/form-data'}), file.name);
            });
        }
        return formData;
    }
}

export default new MessageService();
