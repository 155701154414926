export const misc = {
    buttons: {
        login: 'Logowanie',
        logout: 'Wyloguj się'
    },
    fileInput: {
        dropFilesHere: 'Upuść pliki tutaj albo',
        browseFiles: 'Przeglądaj pliki',
    },
    caseStatus: {
        draft: 'Udkast',
        received: 'Otrzymana',
        beingInvestigated: 'W trakcie badania',
        underCompletion: 'W trakcie zakończenia',
        closed: 'Zamknięta',
    },
    caseStatusDesc: {
        draft: 'Udkast',
        received: 'Otrzymano potwierdzenie',
        initialAssessment: 'Indledende vurdering',
        followUp: 'Opfølgning',
        underCompletion: 'W trakcie zakończenia',
        closed: 'Sagen er lukket',
    },
    fieldIsRequired: '* pole wymagane',

    errorPage: {
        404: {
            title: 'Upss, page not found',
            takeMe: 'Take me',
            home: 'home',
        },
        somethingWentWrong: {
            title: 'Upss, something went wrong',
            weAreWorkingOnIt: 'We are working on it',
            tryAgain: 'Try again ',
            later: 'later',
        },
    },
    language:'Language'
};
