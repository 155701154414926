export const rules = {
    input: {
        fieldIsRequired: '* Field is required',
        validPhoneNumber: '* indtast gyldigt telefonnummer i format +45xxxxxxxx',
        validPhoneNumberOrEmail: 'Indtastede tekst skal være en e-mail eller et telefonnummer med format +45xxxxxxxx',
        email: 'E-mail must be valid',
        min4Char: '* Your password must be at least 4 characters long',
        contentShouldNotBeLonger: 'Description must be 200 characters or less',
    }
};
