export const casePayload = {
    whistleblowerRelation: null,
    description: null,
    violation: null,
    subsidiary: null,
    repeatedViolations: false,
    caseAccount: {
        follow: false,
        password: null,
    },
    whistleblower: null,
    reportedPersons: [],
    reportedUnit: null,
    protectionOfficer: {
        name: null,
        phone: null,
        jobTitle: null,
        company: null,
        logoUrl: null,
        relation: null,
    },
    messages: []
};

export const casePayloadResponse = {
    status: null,
    whistleblowerRelation: null,
    submission: null,
    caseNumber: null,
    description: null,
    violation: null,
    repeatedViolations: null,
    protectionOfficer: null,
    caseAccount: null,
    whistleblower: null,
    reportedPersons: [],
    files: [],
    messages: [],
    events: [],
};

export const caseCreating = {
    ONLINE_FORM: 'BY_ONLINE_FORM',
    PHONE: 'BY_PHONE',
};
