<template>
  <div class="d-flex justify-center mb-16">
    <div class="page-wrapper">
      <div class="mt-10">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainLayout'
};
</script>

<style scoped>

</style>
