const state = {
    event: {
        isLoading: false,
    }
};

const getters = {
    getIsLoading: state => state.event.isLoading,
};

const actions = {
    startLoading({commit}) {
        commit('setLoadingState', true);
    },

    stopLoading({commit}) {
        commit('setLoadingState', false);
    }
};

const mutations = {
    setLoadingState(state, loading) {
        state.event.isLoading = loading;
    },
};


export const event = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
