<template>
  <v-card class="mt-10">
    <div class="pa-8">
      <div class="text-h6 primary--text">
        {{ $t('report.reportDescription.attachIfIsNecessary') }}
      </div>
      <div class="custom-divider"/>

      <div class="mt-5">
        <v-card
            v-for="(file, index) in getCasePayload.files"
            :key="index"
            class="mb-3 d-flex justify-start align-center pa-1"
            elevation="0"
        >
          <v-icon color="secondary">mdi-paperclip</v-icon>
          <v-card-title class="pl-5 pr-5 pt-1 pb-1 primary--text text-decoration-underline">
            {{ file.name }}
          </v-card-title>
          <div class="secondary--text mt-1">{{ fileSize(file.size) }}</div>
        </v-card>
      </div>
    </div>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex';
import {fileSize} from '@/utils/fileSize';

export default {
  name: 'FilesEnteredInfoCard',
  computed: {
    ...mapGetters('authCase', ['getCasePayload'])
  },
  methods: {
    fileSize,
  }
};
</script>

<style scoped>

</style>
