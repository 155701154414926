export const oralReportPage = {
    of: ' von ',
    title: 'Melden Sie Ihren Verdacht oder Ihr Wissen telefonisch - lesen Sie weitere Informationen darüber, wie wir ',
    link: 'Ihre Identität schützen.',
    stepProtectionOfficer: {
        title: 'Hvem ønsker du skal modtage din indberetning?',
        subtitle: ' - Klik på den person eller enhed, du ønsker skal modtage din indberetning.',
        oneProtectionOfficerTitle: 'Hvem vil modtage din indberetning?',
        oneProtectionOfficerSubtitle: '- Din indberetning vil blive sendt til følgende person',
    },
    stepWhistleblowerRelation: {
        title: 'Hvad er din relation til organisationen?',
        subtitle: 'Beskriv hvilken relation, du har til organisationen som din indberetning vedrører. F.eks. at du er' +
            ' ansat.',
        placeholder: 'Angiv relation...*',
    },
    stepSubsidiaryCompany: {
        title: 'Hvilket selskab vedrører indberetningen?',
        subtitle: ' - Angiv hvilket selskab i koncernen din indberetning vedrører.',
    },
    stepIdentity: {
        title: 'Vil du oplyse din identitet?',
        subtitle: ' - Det er frivilligt, om du vil oplyse din identitet.',
    },
    stepFollowReport: {
        title: 'Vælg kodeord til at logge ind på din indberetning',
        subtitle: ' - Sie müssen ein Passwort wählen, um sich einzuloggen und Ihre Meldung zu kommentieren, zu verifizieren oder zu genehmigen.',
        sendReportButton: 'Telefonnummer erhalten',
    },
};
