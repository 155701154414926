export const onlineFormPage = {
    of: ' von ',
    title: 'Melden Sie Ihren Verdacht online. Lesen Sie mehr darüber, wie wir ',
    link: ' Ihre Identität schützen',
    stepProtectionOfficer: {
        title: 'Wer sollte Ihre Meldung erhalten?',
        subtitle: ' - Klicken Sie auf die Person oder Abteilung, die Ihre Meldung erhalten soll.',
        oneProtectionOfficerTitle: 'Wer sollte Ihre Meldung erhalten?',
        oneProtectionOfficerSubtitle: ' - Ihre Meldung wird an die folgende Person geschickt',
    },
    stepWhistleblowerRelation: {
        title: 'In welcher Beziehung stehen Sie zu der Organisation?',
        subtitle: 'Beschreiben Sie Ihre Beziehung zu der Organisation, auf die sich Ihre Meldung bezieht - beispielsweise, dass Sie dort angestellt sind.',
        placeholder: 'Beziehung angeben...*',
    },
    stepDescribeProblem: {
        title: 'Was möchten Sie melden?',
        subtitle: ' - Beschreiben Sie bitte den Vorfall, den Sie melden möchten. Bitte geben Sie uns so viele Einzelheiten wie möglich.',
    },
    stepSubsidiaryCompany: {
        title: 'Auf welches Unternehmen bezieht sich die Meldung?',
        subtitle: ' - Bitte wählen Sie, auf welches Unternehmen in der Gruppe sich die Meldung bezieht.',
        placeholder:'Einheit wählen...*',
    },
    stepReportRelated: {
        title: 'Wen möchten Sie melden?',
        subtitle: ' - Hier geben Sie an, um wen es in der Meldung geht.',
    },
    stepDocumentUpload: {
        title: 'Verfügbare relevante Dokumentation anhängen',
        subtitle: ' - Sie können Dateien anhängen, die den gemeldeten Vorfall dokumentieren.',
    },
    stepIdentity: {
        title: 'Möchten Sie Ihre Identität offenlegen?',
        subtitle: ' - Es ist Ihnen überlassen, ob Sie Ihre Identität offenlegen möchten. Bitte wählen Sie unten.',
    },
    stepFollowReport: {
        title: 'Möchten Sie Ihren Hinweis verfolgen?',
        subtitle: ' - Bitte wählen Sie, ob Sie über den Fall informiert werden und mit weiteren Angaben beitragen möchten. Sie können den Fall auch dann verfolgen, wenn Sie anonym sind.',
        sendReportButton: 'Senden',
    },

    save: 'Gem'
};
