<template>
  <AuthLayout class="mt-n1">
    <v-card :key="updateComponent" elevation="2">
      <v-card elevation="1" color="primary" class="rounded-b-0 card-inner-padding pt-4 pb-4">
        <v-card-title class="white--text pa-0">{{ $t('allPrivatePages.yourInfo.title') }}</v-card-title>
      </v-card>

      <StepYourApplicationIdentity :identity="getCasePayload.whistleblower" @setValidIdentity="setValidIdentity"/>

      <div v-if="validIdentity.showError" class="d-flex justify-center">
        <span class="error--text font-size-16">{{ $t('allPrivatePages.yourInfo.errorMessage') }}</span>
      </div>

      <div class="d-flex justify-end your-info-action-buttons ml-2 pb-12">
        <div>
          <v-btn class="text-none ma-2" height="45" width="120" outlined color="primary" @click="reloadPage()">
            {{ $t('allPrivatePages.yourInfo.cancel') }}
          </v-btn>
          <v-btn class="text-none ma-2" height="45" width="100" color="primary" @click="updateIdentity">
            {{ $t('allPrivatePages.yourInfo.save') }}
          </v-btn>
        </div>
      </div>
    </v-card>

    <UpdatePassword/>
  </AuthLayout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import AuthLayout from '@/components/layout/AuthLayout';
import UpdatePassword from '@/components/card/infoCard/UpdatePassword';
import StepYourApplicationIdentity from '@/components/card/stepForm/StepYourApplicationIdentity';

export default {
  name: 'YourInfo',
  components: {UpdatePassword, StepYourApplicationIdentity, AuthLayout},
  data() {
    return {
      updateComponent: 5,
      whistleBlowerIdentity: {
        email: null,
        name: null,
        phone: null,
        notification: false
      },
      validIdentity: {
        showError: false,
        isValid: false,
      }
    };
  },
  computed: {...mapGetters('authCase', ['getCasePayload']),},
  beforeDestroy() {
    this.$root.$off();
  },
  mounted() {
    this.listenForIdentityChange();
  },
  methods: {
    ...mapActions('authCase', ['updateWhistleBlowerIdentity']),
    reloadPage() {
      this.updateComponent = ++this.updateComponent;
    },
    updateIdentity() {
      if (this.validIdentity.isValid) {
        this.updateWhistleBlowerIdentity({whistleBlowerDto: this.whistleBlowerIdentity});
      }else {
        this.validIdentity.showError = true;
      }
    },
    listenForIdentityChange() {
      this.$root.$on('AuthWhistleblowerIdentity', whistleBlowerIdentity => {
        this.whistleBlowerIdentity = whistleBlowerIdentity;
      });
    },
    setValidIdentity(isValid) {
      this.validIdentity.showError = false;
      this.validIdentity.isValid = isValid;
    }
  }
};
</script>

<style scoped>

</style>
