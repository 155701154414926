<template>
  <div>
    <v-card
        v-if="getCasePayload.pendingWhistleblowerAction"
        class="d-flex justify-space-between align-center mt-n14 mb-6"
        color="#FFE798"
        outlined
        width="100vw"
    >
      <div class="d-flex justify-space-between align-center pa-5" style="width: 100%;">
        <div>
          <span class="primary--text font-weight-bold font-size-18 pl-5">
            {{ $t('allPrivatePages.caseStatus.provideFeedback') }}
          </span>
        </div>
        <div class="c-point" @click="$router.push('/private/messages')">
          <span class="primary--text pr-3">{{ $t('allPrivatePages.caseStatus.goToMessages') }}</span>
          <v-icon color="icons" class="pr-2">mdi-arrow-right</v-icon>
        </div>
      </div>
    </v-card>

    <AuthLayout>
      <PrivateCustomHeaderTitle :title="$t('allPrivatePages.caseStatus.statusOfCaseNumber') + getCaseNumber"/>

      <StepCaseProgress/>
      <v-expansion-panels
          class="mt-8 border-all-1"
          :class="{'disable-container': !showConfirmation}"
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="pa-1" style="min-height: 30px">
            <template v-slot:actions>
              <v-icon size="30" color="icons" class="icon">$expand</v-icon>
            </template>
            <div class="d-flex justify-space-between pa-2">
              <div class="d-flex primary--text font-weight-bold align-center case-status-main-text">
                {{ $t('allPrivatePages.caseStatus.provideConfirmationDate') }}
              </div>
              <div v-if="showConfirmation"
                   class="d-flex primary--text font-weight-bold align-center case-status-main-text"
              >
                {{ getConfirmationDate() }}
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="pa-2 with-linebreak"> {{ getConfirmationDescription() }}</div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="mt-6">
        <v-expansion-panels
            class="border-all-1"
            :class="{'disable-container': !showFeedbackMessage}"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-1" style="min-height: 30px">
              <template v-slot:actions>
                <v-icon size="30" color="icons" class="icon">$expand</v-icon>
              </template>
              <div class="d-flex justify-space-between pa-2">
                <div class="d-flex primary--text font-weight-bold align-center case-status-main-text">
                  {{ $t('allPrivatePages.caseStatus.provideFeedbackTillDate') }}
                </div>
                <div v-if="showFeedbackMessage"
                     class="d-flex primary--text font-weight-bold align-center case-status-main-text"
                >
                  {{ getFeedbackDate() }}
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card elevation="0" min-height="90px" max-height="290px" class="overflow-y-scroll">
                <v-list>
                  <v-list-item v-for="(feedback, index) in getCaseFeedBackMessage" :key="index" two-line>
                    <v-card outlined min-height="50" width="100%" class="border-all-1 mb-4 pa-2">
                      <div class="font-size-16 primary--text">{{ feedback.message }}</div>
                      <div class="font-size-14 font-weight-light mt-2">{{ dateFormat(feedback.time) }}</div>
                    </v-card>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </AuthLayout>
  </div>
</template>

<script>
import PrivateCustomHeaderTitle from '@/components/headers/PrivateCustomHeaderTitle';
import StepCaseProgress from '@/components/progress/StepCaseProgress';
import AuthLayout from '@/components/layout/AuthLayout';
import {mapGetters} from 'vuex';
import {dateFormat, simpleDateFormat} from '@/utils/formating/dateFormat';

export default {
  name: 'CaseStatus',
  components: {AuthLayout, StepCaseProgress, PrivateCustomHeaderTitle},
  computed: {
    ...mapGetters('authCase', ['getCaseNumber', 'getCasePayload', 'getCaseFeedBackMessage',
      'getCaseConfirmationMessage','getCaseReceivedStatus']),
    showConfirmation() {
       return this.getCaseReceivedStatus?.length === 1;
    },
    showFeedbackMessage() {
      return this.getCaseFeedBackMessage.length > 0;
    },
  },
  methods: {
    getConfirmationDescription() {
      if (this.showConfirmation) {
        return this.getCaseConfirmationMessage[0]?.message;
      }
    },
    getConfirmationDate() {
      if (this.showConfirmation) {
        return simpleDateFormat(this.getCaseReceivedStatus[0]?.dateTime);
      }
    },
    getFeedbackDate() {
      if (this.showFeedbackMessage) {
        return simpleDateFormat(this.getCaseFeedBackMessage[0]?.time);
      }
    },
    dateFormat
  }
};
</script>

<style scoped>

</style>
