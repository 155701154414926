import {getCompanyInfoUsingGET, getProtectionOfficerLogoUsingGET} from '@/clients/wbBack/wbBack.api';
import router from '@/router';
import {companyNameHeader} from '@/clients/headers/commonHeaders';

class ProtectionOfficerService {
    async getCompanyInfo() {
        let companyInfo = await getCompanyInfoUsingGET({
            $config: companyNameHeader
        });

        if (companyInfo.status === 200) {
            return await this.getCompanyDataWithAddedPOLogo(companyInfo);
        }

        if (companyInfo?.response?.status === 404
            || companyInfo?.response?.status === 500) {
            // eslint-disable-next-line no-unused-vars
            await router.push('/something-went-wrong').catch(e => {
            });
        }

        if (companyInfo?.status === undefined && companyInfo?.response?.status === undefined) {
            // eslint-disable-next-line no-unused-vars
            await router.push('/something-went-wrong').catch(e => {});
        }
    }

    async getCompanyDataWithAddedPOLogo(companyInfo) {
        let companyInfoResponse = companyInfo?.data;
        for (const protectionOfficer of companyInfoResponse.protectionOfficers) {
            protectionOfficer.url = await this.getImage(protectionOfficer.principal);
        }
        return companyInfoResponse;
    }

    async getImage(principal) {
        let imageUrl = await getProtectionOfficerLogoUsingGET({
            poUuid: principal,
            $config: {
                responseType: 'blob',
                headers: companyNameHeader.headers
            }
        });
        return URL.createObjectURL(new Blob([imageUrl.data], {type: 'image/jpeg'}));
    }
}

export default new ProtectionOfficerService();
