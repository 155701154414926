<template>
  <v-navigation-drawer
      v-model="openDrawer"
      :mini-variant.sync="mini"
      right
      mini-variant-width="60"
      fixed
      permanent
  >
    <div class="pa-2 pt-4 pl-3">
      <v-list-item class="mt-4 mb-4 pa-0">
        <v-btn elevation="0" width="28" min-width="40" min-height="35" class="mb-4 mt-2" @click="mini = !mini">
          <v-icon color="icons">{{ mini ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
        </v-btn>
        <div class="ml-4 mb-2 font-weight-medium primary--text">
          {{ $t('allPrivatePages.messages.nav.sharedFiles') }}
        </div>
      </v-list-item>

      <v-divider class="ml-3 mr-3"/>

      <v-list>
        <v-list-item class="pa-0">
          <v-btn elevation="0" width="30" min-width="40" min-height="35" class="mb-4 mt-4">
            <v-icon color="icons">mdi-folder-open-outline</v-icon>
          </v-btn>

          <v-list-item-content class="ml-3">
            <v-list-item-title class="primary--text font-weight-medium">
              {{ $t('allPrivatePages.messages.nav.allFiles') }} {{ getCasePayload.files.length }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div class="text-left primary--text mb-4 mt-4 ml-1" style="font-size: 16px">
          {{ $t('allPrivatePages.messages.nav.filterFiles') }}
        </div>

        <v-expansion-panels v-model="panel" flat multiple>
          <v-expansion-panel
              v-for="category of Object.values(fileCategories)"
              :key="category.title"
          >
            <v-expansion-panel-header class="pa-0 pl-n2">
              <v-btn elevation="0" width="28" :min-width="!mini ? 50: 40" min-height="35" class="mb-4 mt-2">
                <v-icon color="icons">{{ category.icon }}</v-icon>
              </v-btn>

              <v-list-item-content class="ml-4">
                <v-list-item-title class="primary--text font-weight-medium d-flex justify-space-between">
                  <span class="pb-3">{{ category.title }}</span>
                </v-list-item-title>
                <v-list-item-subtitle class="mt-n2">
                  {{ category.files.fileCount }}, {{ $t('allPrivatePages.messages.nav.files') }},
                  {{ category.files.fileSize }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0 ma-0">
              <div v-for="(file, index) in category.files.filesPerCategory" :key="index">
                <div class="d-flex justify-space-between">
                  <span class="font-size-14 text-decoration-underline pl-2 pt-2">{{ file.name }}</span>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from 'vuex';
import {DOCUMENT, PICTURES, setFilesForCategory, VIDEO} from '@/utils/fileCategory';
import {fileCategories} from '@/model/fileCategory';

export default {
  name: 'MessageFilesNavDrawer',
  data() {
    return {
      openDrawer: false,
      mini: true,
      fileCategories,
      panel: [],
    };
  },
  computed: {
    ...mapGetters('authCase', ['getCasePayload']),
  },

  watch: {
    'mini': {
      handler() {
        this.emitChanges();
        if (this.mini) {
          this.panel = [];
        }
      },
      deep: true
    },
    'getCasePayload': {
      handler() {
        this.initFiles();
      },
      deep: true,
    }
  },
  mounted() {
    this.initFiles();
  },
  methods: {
    initFiles() {
      this.fileCategories.document.files = setFilesForCategory(DOCUMENT, this.getCasePayload.files);
      this.fileCategories.pictures.files = setFilesForCategory(PICTURES, this.getCasePayload.files);
      this.fileCategories.video.files = setFilesForCategory(VIDEO, this.getCasePayload.files);
      this.fileCategories.other.files = setFilesForCategory(null, this.getCasePayload.files);
    },
    emitChanges() {
      this.$emit('toggleFileDrawer', this.mini);
    },
  }
};
</script>

<style scoped>
</style>
