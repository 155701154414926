export const bookMeetingPage = {
    of: ' von ',
    title: 'Buchen Sie ein Meeting, um Ihren Verdacht oder Ihr Wissen zu melden - lesen Sie weitere Informationen darüber, wie wir ',
    link: 'Ihre Identität schützen',
    stepSubsidiaryCompany: {
        title: 'Hvilket selskab vedrører indberetningen?',
        subtitle: ' - Angiv hvilket selskab i koncernen din indberetning vedrører.',
    },
    stepWhistleblowerRelation: {
        title: 'Hvad er din relation til organisationen?',
        subtitle: 'Beskriv hvilken relation, du har til organisationen som din indberetning vedrører. F.eks. at du er' +
            ' ansat.',
        placeholder: 'Angiv relation...*',
    },
    stepProtectionOfficer: {
        title: 'Hvem skal modtage din indberetning på et fysisk møde?',
        subtitle: ' - Klik på den person eller enhed, du ønsker skal modtage din indberetning.',
    },
    stepIdentity: {
        title: 'Vil du oplyse din identitet?',
        subtitle: ' - Det er frivilligt om du vil oplyse din identitet.',
    },
    stepFollowReport: {
        title: 'Vælg kodeord for at følge op på din mødebooking',
        subtitle: '- Sie müssen ein Passwort erstellen, um sich einloggen und Ihre Reservierung des Meetings' +
            ' verfolgen zu können.',
        sendFormButton: 'Telefonnummer erhalten',
    },
};
