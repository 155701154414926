<template>
  <div :class="{'mt-15': marginTop}">
    <DataWillBeDiscarded
        :discard-users-dialog="discardChangesDialog"
        @cancelUserDiscard="cancelUserDiscard()"
        @confirmUserDiscard="confirmUserDiscard()"
    />
    <v-card
        id="stepFromIdentity"
        class="mx-auto"
        max-width="100%"
        rounded
        :elevation="elevation"
        :class="{'card-border-bottom': bottomBorder}"
    >
      <div class="card-inner-padding">
        <slot name="card-title"/>
        <v-row class="d-flex justify-space-between " :class="{'mt-5': marginTop}">
          <v-col cols="12" lg="6" xl="6" md="12" sm="12">
            <v-card
                outlined
                width="100%"
                min-height="126px"
                height="100%"
                :class="{'border-all-2 selected-option': (hasWhistleblowerChooseAnOption && isRevealIdentitySelected)}"
                elevation="0"
                @click="changeSelectedIdentity(true)"
            >
              <div class="ma-4">
                <div class="d-flex justify-center align-center">
                  <div class="pa-1">
                    <v-icon size="36" class="icon-select-option" color="icons">mdi-account-outline</v-icon>
                  </div>
                  <div class="text-select-option-title word-brake">
                    {{ $t('step.identity.yesRevealIdentity') }}
                  </div>
                </div>
                <div class="pa-2 text-select-option-subtitle word-brake">
                  {{ $t('step.identity.enterIdentity') }}
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6" xl="6" md="12" sm="12">
            <v-card
                outlined
                width="100%"
                height="100%"
                min-height="126px"
                :class="{'border-all-2 selected-option': (hasWhistleblowerChooseAnOption && !isRevealIdentitySelected)}"
                elevation="0"
                @click="changeSelectedIdentity(false)"
            >
              <div class="ma-4">
                <div class="d-flex justify-center align-center">
                  <div class="pa-1">
                    <v-icon size="36" class="icon-select-option" color="icons">mdi-account-outline</v-icon>
                  </div>
                  <div class="text-select-option-title word-brake">
                    {{ $t('step.identity.noDontRevealIdentity') }}
                  </div>
                </div>
                <div class="pa-2 text-select-option-subtitle word-brake">
                  {{ $t('step.identity.stayAnonym') }}
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <div v-if="isRevealIdentitySelected">
          <v-row class="d-flex justify-space-between mt-9">
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <v-text-field
                  v-model="whistleblowerIdentity.name"
                  :placeholder="$t('step.identity.firstName')"
                  :rules="rules.fieldRequiredRules"
                  :error="displayFromValidationError && isFalsy(whistleblowerIdentity.name)"
                  outlined
                  validate-on-blur
                  @input="setStepIdentityValues()"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <v-text-field
                  v-model="whistleblowerIdentity.email"
                  :placeholder="$t('step.identity.email')"
                  :rules="rules.emailRequiredRules"
                  :error="displayFromValidationError && isFalsy(whistleblowerIdentity.email)"
                  outlined
                  @input="setStepIdentityValues()"
              />
            </v-col>
          </v-row>

          <v-row class="d-flex justify-space-between mt-0">
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <vue-phone-number-input
                  v-model="phoneNr.text"
                  class="border-all-2 h-100-p"
                  style="height: 55px!important;"
                  :class="{'border-all-error-1': displayFromValidationError && !phoneNr.isValid}"
                  :color="phoneNr.color"
                  :valid-color="primaryColor"
                  :error-color="errorColor"
                  :default-country-code="getPhoneCodeMapping"
                  :translations="{
                    countrySelectorLabel: $t('step.identity.phoneNr'),
                    countrySelectorError: $t('rules.input.validPhoneNumber'),
                    phoneNumberLabel: $t('step.identity.phoneNr'),
                    example: $t('step.identity.examplePhoneNr'),
                  }"
                  @update="setPhoneNumberValidation"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <v-card outlined elevation="0" width="100%" min-height="55" class="border-all-2">
                <v-radio-group
                    v-model="whistleblowerIdentity.notification"
                    class="mt-3 ml-3 pb-0 mb-0"
                    hide-details
                    @change="toggleNotification()"
                >
                  <v-radio
                      :label="$t('step.identity.wantToReceive')"
                      :value="true"
                      @click="toggleNotification()"
                  />
                </v-radio-group>
              </v-card>
            </v-col>
          </v-row>
        </div>


        <div v-if="showErrorMessageMustSelectAnOption" class="pt-6 d-flex justify-center">
          <div>
            <span class="error--text">{{ $t('step.identity.youNeedToSelectAnOption') }}</span>
          </div>
        </div>

        <StepInformationFooter v-if="tooltip" :text="$t('tooltips.identity.text')"/>
      </div>
    </v-card>
  </div>
</template>

<script>
import StepInformationFooter from '@/components/tooltip/StepInformationTooltip';
import VuePhoneNumberInput from 'vue-phone-number-input';

import {emailRequiredRules, fieldPhoneNumber, fieldRequiredRules, isValidEmail,} from '@/utils/rules/inputRules';
import {isFalsy, isNotFalsy} from '@/utils/guards';
import DataWillBeDiscarded from '@/components/modal/DataWillBeDiscarded';
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'StepIdentity',
  props: {
    tooltip: {
      type: Boolean,
      default: true,
    },
    marginTop: {
      type: Boolean,
      default: true,
    },
    bottomBorder: {
      type: Boolean,
      default: true,
    },
    elevation: {
      type: Number,
      default: 2,
    },
  },
  components: {
    DataWillBeDiscarded,
    StepInformationFooter,
    VuePhoneNumberInput
  },
  data() {
    return {
      rules: {
        fieldRequiredRules,
        fieldPhoneNumber,
        emailRequiredRules,
      },
      phoneNr: {
        text: null,
        color: this.primaryColor,
        isValid: null,
      },
      discardChangesDialog: false,
      isRevealIdentitySelected: null,
      whistleblowerIdentity: {
        email: null,
        name: null,
        phone: null,
        phoneCountryCode: null,
        notification: false
      }
    };
  },
  computed: {
    ...mapGetters('lookAndFeel', ['getPhoneCodeMapping']),
    ...mapGetters('onlineForm', ['getShowFormValidationErrorDialog', 'getFormValidation']),
    displayFromValidationError() {
        if (this.getShowFormValidationErrorDialog) {
          return !this.getFormValidation.stepFromIdentity.isValid;
        }
        return false;
    },
    hasUserTypedIdentity() {
        return isNotFalsy(this.whistleblowerIdentity?.name)
            || isNotFalsy(this.whistleblowerIdentity?.email)
            || isNotFalsy(this.whistleblowerIdentity?.phone);

    },
    isValidIdentity() {
      if (isFalsy(this.whistleblowerIdentity?.phone)) {
        return isNotFalsy(this.whistleblowerIdentity?.name) && isValidEmail(this.whistleblowerIdentity?.email);
      } else {
        return isNotFalsy(this.whistleblowerIdentity?.name)
            && isValidEmail(this.whistleblowerIdentity?.email)
            && this.phoneNr.isValid;
      }
    },
    hasWhistleblowerChooseAnOption() {
      return this.isRevealIdentitySelected !== null;

    },
    showErrorMessageMustSelectAnOption() {
      return this.displayFromValidationError && this.isRevealIdentitySelected === null;
    },
    primaryColor() {
      return this.$vuetify.theme.defaults.light.primary;
    },
    errorColor() {
      return this.$vuetify.theme.defaults.light.error;
    },
  },
  watch: {
    'whistleblowerIdentity.phone': {
      handler() {
        this.phoneNr.color = this.phoneNr.isValid ? this.primaryColor : this.errorColor;
      },
    }
  },
  methods: {
    ...mapMutations('onlineForm', ['setValidFromStep']),

    changeSelectedIdentity(value) {
      this.isRevealIdentitySelected = value;
      if (this.isRevealIdentitySelected) {
        this.initWhistleblowerIdentity();
      } else {
        this.checkFromEnteredInformation();
      }

      this.validateFormIdentityStep();
    },

    checkFromEnteredInformation() {
      this.discardChangesDialog = (!this.isRevealIdentitySelected && this.hasUserTypedIdentity);

      if (!this.discardChangesDialog) {
        this.resetWhistleblowerIdentity();
        this.setStepIdentityValues();
      }
    },

    toggleNotification() {
      let {notification} = this.whistleblowerIdentity;
      this.whistleblowerIdentity.notification = !notification;
      this.setStepIdentityValues();
    },

    resetWhistleblowerIdentity() {
      this.whistleblowerIdentity = null;
    },

    initWhistleblowerIdentity() {
      this.whistleblowerIdentity = {email: null, name: null, phone: null, phoneCountryCode: null, notification: false};
    },

    setStepIdentityValues() {
      this.$root.$emit('WhistleblowerIdentity', this.whistleblowerIdentity);
      this.validateFormIdentityStep();
    },

    confirmUserDiscard() {
      this.resetWhistleblowerIdentity();
      this.setStepIdentityValues();
      this.discardChangesDialog = false;
    },

    cancelUserDiscard() {
      this.isRevealIdentitySelected = true;
      this.discardChangesDialog = false;
    },
    validateFormIdentityStep() {
      if (this.isRevealIdentitySelected) {
        this.setValidFromStep({validStep: 'stepFromIdentity', isValid: this.isValidIdentity});
      } else {
        this.setValidFromStep({validStep: 'stepFromIdentity', isValid: true});
      }
    },
    setPhoneNumberValidation(validation) {
      this.phoneNr.isValid = validation.isValid;
      this.whistleblowerIdentity.phone = validation.e164;
      this.whistleblowerIdentity.phoneCountryCode = validation.countryCode;

      this.setStepIdentityValues();
    },
    isFalsy,
    isValidEmail
  }
};
</script>

<style scoped lang="scss">

.input-tel__input {
  height: 100% !important;
  border: none !important;
}

.country-selector.has-value .country-selector__input {
  height: 100% !important;
  padding-top: 0 !important;
}
</style>
