import {getSiteLookAndFeelLogoUsingGET, getSiteLookAndFeelUsingGET} from '@/clients/wbBack/wbBack.api';
import {defaultLookAndFeel} from '@/model/language/defaultLookAndFeel';
import {companyNameHeader} from '@/clients/headers/commonHeaders';

import moment from 'moment';
import router from '@/router';


class SiteLookAndFeelService {

    async getSiteLookAndFeelWithCode(languageCode) {
        try {
            let lookAndFeel = await getSiteLookAndFeelUsingGET({
                languageCode,
                $config: companyNameHeader
            });

            if (lookAndFeel?.status === undefined && lookAndFeel?.response?.status === undefined) {
                await router.push('/something-went-wrong');
            }

            if (lookAndFeel?.status === 200) {
                moment.locale(lookAndFeel?.data?.languageCode);
                return lookAndFeel?.data;
            } else {
                return defaultLookAndFeel;
            }
        } catch {
            return defaultLookAndFeel;
        }
    }

    async getCanAccessPageWithOption(option) {
        let canAccessPage = await this.getSiteLookAndFeelWithCode('da');
        return canAccessPage?.siteFrontPage[option] ?? false;
    }

    async getLogo(logoFileName) {
        try {
            let lookAndFeel = await getSiteLookAndFeelLogoUsingGET({
                logoFileName,
                $config: {
                    responseType: 'blob',
                    headers: companyNameHeader.headers
                }
            });
            return URL.createObjectURL(new Blob([lookAndFeel.data], {type: 'image/jpeg'}));
        } catch {
            return null;
        }
    }
}

export default new SiteLookAndFeelService();
