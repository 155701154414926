export const tooltips = {
    whyIsInfoNeeded: {
        title: 'Hvorfor er denne information nødvendig?',
        tooltipInfo: 'Information',
    },
    subsidiary: {
        text: 'Vi har brug for at vide, hvilket selskab I koncernen din indberetning vedrører, så vi bedst muligt' +
            ' kan efterforske forholdet.'
    },
    whistleblowerRelation: {
        text: 'Whistleblowerordningen kan kun benyttes af den persongruppe, som er angivet i whistleblowerpolitikken.' +
            ' Det er derfor nødvendigt at kende din relation.'
    },
    describeProblem: {
        text: 'For at vi kan foretage en grundig undersøgelse af din indberetning, er det vigtigt, at vi har så mange ' +
            'og så detaljerede oplysninger som muligt. Du skal derfor beskrive, hvad du indberetter, hvornår det er ' +
            'sket, hvor det er sket, hvem der er involveret, om der er nogen, der kan bevidne hændelsen mv. Du skal ' +
            'også beskrive din relation – eksempelvis at du er en medarbejder, nær kollega eller tilsvarende.',
    },
    reportRelated: {
        text: 'Vores undersøgelser af din indberetning kan målrettes meget mere præcist, hvis vi kender identiteten ' +
            'på den eller dem, der foretager de indberettede handlinger. Hvis du ikke kender identiteten, men ' +
            'eksempelvis blot ved at din indberetning vedrører en bestemt afdeling, er dette også en viden af stor ' +
            'værdi for os, når vi skal planlægge vores undersøgelser.'
    },
    documentUpload: {
        text: 'Hvis du har materiale, der kan dokumentere din indberetning, har det stor værdi for os, når vi skal' +
            ' undersøge din indberetning. Det kan være billeder, kopier af dokumenter mv.',
    },
    identity: {
        text: 'Vores muligheder for at undersøge din indberetning er generelt bedst, hvis vi kender din identitet.' +
            ' Vi håber derfor, at du vil oplyse den, men det er frivilligt, og vi undersøger din indberetning uanset,' +
            ' om vi kender din identitet eller ej.',
    }

};
