<template>
  <div>
    <v-btn v-if="!isAuthenticated" color="primary" class="text-none pa-5 ml-2 elevation-1" @click="login()">
        {{ $t('misc.buttons.login') }}
    </v-btn>
    <v-btn v-else color="primary" class="text-none pa-5 ml-2 elevation-1" @click="logout()">
      {{ $t('misc.buttons.logout') }}
    </v-btn>
  </div>
</template>

<script>
import keyCloakService from '@/services/auth/KeyCloakService';

export default {
  name: 'LoginButton',
  asyncComputed: {
    isAuthenticated: {
      get() {
        return keyCloakService.isUserAuthenticated();
      }
    }
  },
  methods: {
    login() {
      keyCloakService.loginUser();
    },
    logout() {
      keyCloakService.logoutUser();
    },
  }
};
</script>

<style scoped>

</style>
