export const misc = {
    buttons: {
        login: 'Einloggen',
        logout: 'Ausloggen'
    },
    fileInput: {
        dropFilesHere: 'Dateien hier ablegen oder',
        browseFiles: 'Dateien durchsuchen',
    },
    caseStatus: {
        draft: 'Udkast',
        received: 'Erhalten',
        beingInvestigated: 'Follow-up',
        underCompletion: 'Fall in Bearbeitung',
        closed: 'Erledigt',
    },
    fieldIsRequired: '* Das Feld ist obligatorisch',

    errorPage: {
        404: {
            title: 'Upss, page not found',
            takeMe: 'Take me',
            home: 'home',
        },
        somethingWentWrong: {
            title: 'Upss, something went wrong',
            weAreWorkingOnIt: 'We are working on it',
            tryAgain: 'Try again ',
            later: 'later',
        },
    },
    language:'Language'
};
