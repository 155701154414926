export const askQuestionsPage = {
    stepMakeQuestion: {
        title: 'Zadzwoń jeżeli masz pytanie - rozmowy są zawsze traktowane poufnie',
        subtitleInfo1: 'Przygotowaliśmy również ',
        faq: 'FAQ',
        subtitleInfo2: ', (często zadawane pytania), w którym można znaleźć odpowiedzi na większość pytań' +
            ' dotyczących',
        subtitleInfo3: ' systemu informowania o nieprawidłowościach.',
    }
};
