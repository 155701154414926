import i18n from '@/i18n';

export const caseStatus = {
    RECEIVED: 'RECEIVED',
    BEING_INVESTIGATED: 'BEING_INVESTIGATED',
    BEING_CLOSED: 'BEING_CLOSED',
    CLOSED: 'CLOSED',
    PENDING_WHISTLEBLOWER_CONTACT: 'PENDING_WHISTLEBLOWER_CONTACT',
};

export const caseStatusEvents = [
    {
        status: caseStatus.PENDING_WHISTLEBLOWER_CONTACT,
        label: null,
        show: false,
        filled: 0
    },
    {
        status: caseStatus.RECEIVED,
        label: 'misc.caseStatus.received',
        show: true,
        filled: 5
    },
    {
        status: caseStatus.BEING_INVESTIGATED,
        label: 'misc.caseStatus.beingInvestigated',
        show: true,
        filled: 33
    },
    {
        status: caseStatus.BEING_CLOSED,
        label: 'misc.caseStatus.underCompletion',
        show: true,
        filled: 66
    },
    {
        status: caseStatus.CLOSED,
        label: 'misc.caseStatus.closed',
        show: true,
        filled: 100
    },
];
