export const step = {
    howYouWantToMeet: {
        phone: 'Telefonisk',
        appointment: 'Fortæl hvilke tidspunkter der passer dig et fysisk møde *',
    },
    protectionOfficer: {
        'Ekstern': 'Externa',
        'Intern': 'Interna',
    },
    describeProblem: {
        describe: 'Descripción*',
        choose: 'Elige... *',
        rulesViolated: '¿Qué normas se han infringido en tu opinión?',
        isProblemRepeated: '¿Ha ocurrido el incidente más de una vez?',
        howOftenIsRepeated: 'Please indicate in the description how often the incident has occurred', // TODO
        yes: 'Sí',
        no: 'No',
    },

    makeQuestions: {
        title: 'Plantea preguntas sobre el sistema de denuncia de irregularidades – Obtén más información' +
            ' sobre el ',
        whistleblowing: 'sistema de denuncia de irregularidades',
        phone: 'Telefonisk',
        online: 'Online',
        callThisNumber: 'Telefonen er åben alle hverdage i tidsrummet 08.00-17.00.',
        defaultPhoneNumber: '+45 88 91 92 45',
        defaultContactPerson: 'Søren Wolder, Advokat, DAHL Advokatpartnerselskab',
        defaultContactInfo: 'Telefonen er åben alle hverdage i tidsrummet 08.00-17.00.',
    },

    reportRelated: {
        selectPersonsInCompany: 'Personas designadas por nombre',
        company: 'Personas no designadas por nombre',
        youNeedToSelectAnOption: '*Field required. Select an option.',
        firstName: 'Nombre y apellido*',
        position: 'Cargo',
        phoneNrOrEmail: 'Número de teléfono / correo electrónico de la persona, si los hubiera',
        addNewPerson: 'Añadir nueva persona',
        unit: 'Departamento'
    },

    documentUpload: {
        max: 'Máx.',
        perFile: ' por archivo',
    },

    identity: {
        yesRevealIdentity: 'Sí, deseo revelar mi identidad',
        enterIdentity: 'Tu identidad se tratará de forma confidencial, haciéndose conocer únicamente a las personas' +
            ' que necesitan conocerla.',
        noDontRevealIdentity: 'No, no deseo revelar mi identidad',
        stayAnonym: 'Como norma general, tus datos son anónimos, pero tu denuncia podrá revelar tu posible identidad.' +
            ' En tal caso, la trataremos de forma confidencial.',
        firstName: 'Nombre y apellido*',
        email: 'Correo electrónico*',
        phoneNr: 'Teléfono móvil',
        examplePhoneNr: 'Ejemplo de teléfono móvil',
        wantToReceive: 'Deseo recibir un correo electrónico cuando haya alguna novedad con respecto a mi expediente',
        youNeedToSelectAnOption: 'Du skal angive om du vil oplyse din identitet eller ej.', //TODO
    },

    followReport: {
        yesFollowReport: 'Sí, deseo seguir la tramitación del expediente',
        yesFollowTheCaseWithPassword: 'Tendrás la posibilidad de contribuir con información adicional y de recibir' +
            ' comentarios a través del núm. del expediente y una contraseña de libre elección.',
        noDontFollowReport: 'No, no deseo mantenerme informado sobre la tramitación del expediente',
        noDontWantToHearFromCase: 'No recibirás comentarios ni información sobre el expediente después de haber' +
            ' presentado la denuncia.',
        enterPassword: 'Introduce una contraseña de libre elección*',
        confirmPassword: 'Repite la contraseña*',
        mustConfirmPassword: 'Debes confirmar la contraseña',
        iAcceptTheTerms: 'Estoy familiarizado con la política de ',
        iAcceptTheTerms1: 'denuncia de irregularidades',
        logInIn10Days: '¡Toma nota! ',
        logInIn10Days1: 'Por razones de seguridad deberás iniciar sesión dentro de un plazo de 10 días. En caso' +
            ' contrario, se borrará tu perfil de usuario.',
    }
};
