export const allPrivatePages = {
    caseStatus: {
        statusOfCaseNumber: 'Status Twojej sprawy #',
        caseWillBeInitiated: 'Sprawa zostanie wszczęta po skontaktowaniu się z odbiorcą pod numerem telefonu otrzymanym podczas tworzenia sprawy.',
        provideFeedback: 'Vi afventer din tilbagemelding',
        goToMessages: 'Gå til beskeder',
        provideFeedbackTillDate: 'Otrzymano informację zwrotną',
        provideConfirmationDate: 'Otrzymano potwierdzenie',
    },
    messages: {
        title: 'Wiadomości',
        nav: {
            sharedFiles: 'Udostępnione pliki',
            allFiles: 'Wszystkie pliki',
            filterFiles: 'Pliki',
            files: 'pliki',
            document: 'Dokumenty',
            pictures: 'Obrazy',
            video: 'Wideo',
            other: 'Inny',
        },
        chat: {
            writeCommentHere: 'Skriv din kommentar til sagen her...',
            addToCase: 'Tilfoj til sagen',
        }
    },
    seeReport: {
        submitComments: 'Prześlij uwagi',
        approve: 'Zatwierdź',

        commentsOnReport: {
            title: 'Uwagi do Twojego zgłoszenia',
            comment: 'Tutaj napisz swoje uwagi do zgłoszenia oraz pozostałe informacje...',
            send: 'Wyślij',
        },
        approveDialog: {
            title: 'Bekræft indholdet af din indberetning',
            description: 'Er indberetningen beskrevet korrekt? Du kan fortsat sende beskeder vedrørende sagen under "Beskeder"',
            cancel: 'Anuluj',
            confirm: 'Zatwierdź',
        }
    },
    yourInfo: {
        title: 'Jeśli wcześniej nie podałeś swojej tożsamości, możesz to zrobić na tej stronie',
        save: 'Zapisz',
        cancel: 'Anuluj',
        errorMessage: '',
    },
    changePassword: {
        title: 'Zmiana kodu do sprawy',
        description: 'Jeśli chcesz zmienić kod do sprawy, naciśnij „Zmień kod” poniżej:',
        changePassword: ' Zmień kod',
        dialog: {
            title: 'Zmiana kodu do sprawy',
            whatShouldYouDo: 'Musisz teraz wykonać następujące czynności:',
            step1: 'Wyloguj się za pomocą poniższego przycisku',
            step2: 'Zaloguj się do Twojej sprawy',
            step3: 'Podaj Twój nowy kod',
            descText1: 'Aby zmienić kod, ze względów bezpieczeństwa, należy się wylogować.',
            descText2: 'Za pomocą poniższego przycisku potwierdź, że chcesz zmienić kod, a zostaniesz automatycznie wylogowany.',
            btnApprove: 'Potwierdź i się wyloguj',
        }
    },

};
