<template>
  <v-card class="mt-10">
    <div class="pa-8">
      <div class="d-flex justify-space-between align-center">
        <div class="text-h6 primary--text">
          {{ $t('report.reportRelate.reportRelateTo') }}
        </div>
      </div>
      <div class="custom-divider"/>
      <div v-if="getCasePayload.reportedPersons.length > 0" class="mt-5">
        <div class="mt-n3 primary--text">
          {{ $t('report.reportRelate.personsInCompany') }}
        </div>
        <v-card v-for="(reportedUser, index) in getCasePayload.reportedPersons"
                :key="index"
                class="border-all-1 mb-5"
                elevation="0"
        >
          <div class="d-flex justify-space-between align-center">
            <v-card-title class="primary--text">
              {{ reportedUser.name }}
              {{ reportedUser.phoneOrEmail && ', ' + reportedUser.phoneOrEmail }}
              {{ reportedUser.jobTitle && ', ' + reportedUser.jobTitle }}
            </v-card-title>
          </div>
        </v-card>
      </div>

      <div v-else>
        <div class="mt-n3 primary--text">
          {{ $t('report.reportRelate.company') }}
        </div>
        {{ getCasePayload.reportedUnit }}
      </div>
    </div>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'UserReportedInfoCard',
  data() {
    return {
      addNewUser: false,
    };
  },
  computed: {
    ...mapGetters('authCase', ['getCasePayload'])
  },
};
</script>

<style scoped>

</style>
