<template>
  <div>
    <v-card v-if="getHasStatusPendingWhistleblower" color="warningCustom" width="100%" class="mt-4" elevation="0">
      <div class="pa-2">
        {{ $t('allPrivatePages.caseStatus.caseWillBeInitiated') }}
      </div>
    </v-card>
    <div v-if="!isMobile">
      <div class="mt-5" disabled="disabled">
        <v-progress-linear
            :value="filled"
            color="primary"
            class="rounded-pill disabled"
            height="20"
        />
      </div>

      <div class="d-flex justify-space-between">
        <div v-for="(status, index) in caseStatus" :key="index">
          <div
              class="primary--text mt-2 font-size-16"
              :class="{'disable-container': !hasStatusCase(index, status)}"
          >
            {{ $t(status.label) }}
          </div>
          <div v-if="hasStatusCase(index, status)">
            <div class="primary--text mt-2 font-size-14">
              {{ getDate(index, 'MM/DD/YYYY') }}
            </div>
            <div class="primary--text mt-1 font-size-14">
              {{ getDate(index, 'HH:mm') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="pa-2 d-flex justify-start pt-6">
        <div class="mProgressContain d-flex flex-column">
          <div class="totalProgressBar"/>
          <div class="verticalProgressBar" :style="fillVerticalBar"/>
        </div>
        <div class="pt-0 pb-0 mb-0 d-flex justify-space-between flex-column pl-4">
          <div
              v-for="(status, index) in caseStatus"
              :key="index"
          >
            <div
                class="primary--text mt-2 font-size-16"
                :class="{'disable-container': !hasStatusCase(index, status)}"
            >
              {{ $t(status.label) }}
            </div>
            <div class="d-flex justify-start">
              <div v-if="hasStatusCase(index, status)">
                <div class="primary--text font-size-14">
                  D. {{ getDate(index, 'MM/DD/YYYY') }}
                </div>
                <div class="primary--text pl-4 font-size-14">
                  Kl. {{ getDate(index, 'HH:mm') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {caseStatus, caseStatusEvents} from '@/model/caseStatusEvents';
import {mapGetters} from 'vuex';
import moment from 'moment';
import {observableToObject} from '@/utils/weirdities/observableToObject';

export default {
  name: 'StepCaseProgress',
  data() {
    return {
      filled: 0,
      events: [],
      date: new Date(),
      caseStatus: [],
    };
  },
  computed: {
    ...mapGetters('authCase', ['getCaseEvents', 'getHasStatusPendingWhistleblower']),
    isMobile: {
      get() {
        return this.$vuetify.breakpoint.width < 740;
      }
    },
    fillVerticalBar: {
      get() {
        if (this.filled === 5) {
          return {
            'height': '5%',
            'transform': 'translatey(-1890%)'
          };
        }
        if (this.filled === 33) {
          return {
            'height': '45.5%',
            'transform': 'translatey(-120%)'
          };
        }
        if (this.filled === 66) {
          return {
            'height': '73.5%',
            'transform': 'translatey(-36%)'
          };
        }
        else {
          return {
            'height': '100%',
            'transform': 'translatey(0)'
          };
        }
      }
    }
  },
  watch: {
    'getCaseEvents': {
      handler() {
        this.initCaseEvent();
      },
      immediate: true
    }
  },
  methods: {
    initCaseEvent() {
      this.caseStatus = observableToObject(caseStatusEvents)?.filter(el => el.show);
      this.events = this.getCaseEvents?.filter(el => el.status !== caseStatus.PENDING_WHISTLEBLOWER_CONTACT);
      this.filled = this.caseStatus[this.events?.length - 1]?.filled;
    },
    hasStatusCase(index, caseStatus) {
      let hasStatus = this.events && !!this.events[index];
      if (hasStatus) {
        return this.events[index].status === caseStatus.status;
      }
      return hasStatus;
    },
    getDate(index, format) {
      let event = this.events[index];
      return this.events[index] ? moment(event.dateTime).format(format) : '';
    },
  },
};
</script>

<style lang="scss" scoped>

.progress {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  transform: rotate(-90deg);
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 10px;
}

.progress .progress-bar {
  z-index: 1;
  position: relative;
}

.progress {
  background: transparent;
}


.mProgressContain {
  width: 25px;
  height: 300px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  text-align: start;
}

.verticalProgressBar {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  background: var(--v-primary-base);
  border-radius: 15px;
}


.totalProgressBar {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.37);;
  height: 100%;
  border-radius: 15px;
}


</style>

