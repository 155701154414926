export const misc = {
    buttons: {
        login: 'Iniciar sesión',
        logout: 'Cerrar sesión'
    },
    fileInput: {
        dropFilesHere: 'Soltar archivos aquí o',
        browseFiles: 'Explorar archivos',
    },
    caseStatus: {
        draft: 'Udkast',
        received: 'Modtaget',
        beingInvestigated: 'Under opfølgning',
        underCompletion: 'Under afslutning',
        closed: 'Lukket',
    },
    caseStatusDesc: {
        draft: 'Udkast',
        received: 'Bekræftelse modtaget',
        initialAssessment: 'Indledende vurdering',
        followUp: 'Opfølgning',
        underCompletion: 'Under afslutning',
        closed: 'Sagen er lukket',
    },
    fieldIsRequired: '* felt påkrævet',

    errorPage: {
        404: {
            title: 'Upss, page not found',
            takeMe: 'Take me',
            home: 'home',
        },
        somethingWentWrong: {
            title: 'Upss, something went wrong',
            weAreWorkingOnIt: 'We are working on it',
            tryAgain: 'Try again ',
            later: 'later',
        },
    },
    language:'Language'
};
