export const allPrivatePages = {
    caseStatus: {
        statusOfCaseNumber: 'Status Ihrer Fallnummer #',
        caseWillBeInitiated: 'Das Follow-up wird eingeleitet, wenn Sie die bei Abgabe Ihrer Meldung erhaltene Telefonnummer anrufen.',
        provideFeedback: 'Vi afventer din tilbagemelding',
        goToMessages: 'Gå til beskeder',
        provideFeedbackTillDate: 'Rückmeldung',
        provideConfirmationDate: 'Quittung',
    },
    messages: {
        title: 'Beskeder',
        nav: {
            sharedFiles: 'Geteilte Dateien',
            allFiles: 'Alle Dateien',
            filterFiles: 'Dateien',
            files: 'Dateien',
            document: 'Dokumente',
            pictures: 'Bilder',
            video: 'Videos',
            other: 'Sonstiges',
        },
        chat: {
            writeCommentHere: 'Schreiben Sie Ihren Kommentar hier...',
            addToCase: 'Tilfoj til sagen',
        }
    },
    seeReport: {
        submitComments: 'Kommentare abgeben',
        approve: 'Zustimmen',

        commentsOnReport: {
            title: 'Bemærkninger til din indberetning',
            comment: 'Skriv dine bemærkninger til indberetningen og øvrige oplysninger her…',
            send: 'Send',
        },
        approveDialog: {
            title: 'Bekræft indholdet af din indberetning',
            description: 'Er indberetningen beskrevet korrekt? Du kan fortsat sende beskeder vedrørende sagen under "Beskeder"',
            cancel: 'Annuller',
            confirm: 'Godkend',
        }
    },
    yourInfo: {
        title: 'Wenn Sie Ihre Identität noch nicht angegeben haben, haben Sie im Folgenden die Möglichkeit, dies zu tun',
        save: 'Speichern',
        cancel: 'Löschen',
        errorMessage: '',
    },
    changePassword: {
        title: 'Passwort ändern',
        description: 'Um Ihr Passwort zu ändern, klicken Sie bitte unten auf die Schaltfläche „Passwort ändern“:',
        changePassword: 'Passwort ändern',
        dialog: {
            title: 'Passwort für Ihren Fall ändern',
            whatShouldYouDo: 'Sie müssen nun Folgendes tun:',
            step1: 'Mit der Schaltfläche unten ausloggen',
            step2: 'In Ihren Fall einloggen',
            step3: 'Neues Passwort eingeben',
            descText1: 'Aus Sicherheitsgründen müssen Sie sich ausloggen, um Ihr Passwort zu ändern.',
            descText2: 'Bitte bestätigen Sie, dass Sie Ihr Passwort ändern möchten, und loggen Sie sich unten mit der Schaltfläche aus.',
            btnApprove: 'Bestätigen und ausloggen',
        }
    },

};
