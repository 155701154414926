<template>
  <div>
    <v-card elevation="0" color="transparent" class="d-flex justify-center">
      <v-img
          :src="getBackgroundImageSrc()"
          class="rounded-0"
      >
        <div class="d-flex justify-center mt-10 pt-10" style="height: 701px">
          <div class="page-wrapper">
            <div class="page-header">
              <div class="page-header-title primary--text">
                <span>{{ landingPageText.heading }}</span>
              </div>
              <div class="page-header-subtitle primary--text font-weight-bold">
                <span>{{ landingPageText.subHeading }}</span>
              </div>
              <div class="page-header-paragraph black--text with-linebreak">
                <span>{{ landingPageText.paragraph }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-center card-wrapper">
          <div class="page-wrapper">
            <div class="primary--text font-weight-bold text-h6 pb-2" style="position: relative;">
              {{ $t('landingPage.actionCards.title') }}
            </div>
            <v-row :justify="isTabletOrMobile ? 'center' : 'start'" no-gutters class="home-action-card-wrapper pt-5">
              <GoToCaseCard
                  :title="$t('landingPage.actionCards.onlineForm.title')"
                  :is-mobile="isTabletOrMobile"
                  path="online-form"
              >
                <template v-slot:default>
                  <Conversation/>
                </template>
              </GoToCaseCard>

              <GoToCaseCard
                  v-if="showOralPage"
                  :title="$t('landingPage.actionCards.oralReport.title')"
                  :is-mobile="isTabletOrMobile"
                  path="oral-report"
              >
                <template v-slot:default>
                  <Phone/>
                </template>
              </GoToCaseCard>

              <GoToCaseCard
                  v-if="showBookMeetingPage"
                  :title="$t('landingPage.actionCards.bookMeeting.title')"
                  :is-mobile="isTabletOrMobile"
                  path="book-meeting"
              >
                <template v-slot:default>
                  <Calendar/>
                </template>
              </GoToCaseCard>

              <GoToCaseCard
                  :title="$t('landingPage.actionCards.askQuestions.title')"
                  :is-mobile="isTabletOrMobile"
                  path="ask-questions"
              >
                <template v-slot:default>
                  <QuestionMessage/>
                </template>
              </GoToCaseCard>
            </v-row>
          </div>
        </div>
      </v-img>
    </v-card>
  </div>
</template>

<script>
import Conversation from '@/assets/svg/conversation.svg';
import Phone from '@/assets/svg/phone.svg';
import Calendar from '@/assets/svg/calendar.svg';
import QuestionMessage from '@/assets/svg/question-message.svg';
import GoToCaseCard from '@/components/card/GoToCaseCard';
import {mapGetters, mapState} from 'vuex';
import {isFalsy, isNotFalsy} from '@/utils/guards';
import {hexToRgb} from '@/utils/ui/hexToRgb';


export default {
  name: 'LandingPage',
  components: {
    Conversation,
    Phone,
    Calendar,
    QuestionMessage,
    GoToCaseCard,
  },
  computed: {
    ...mapGetters('lookAndFeel', ['getSiteFrontPage', 'getSiteColors']),
    ...mapState('lookAndFeel', ['lookAndFeel']),
    showOralPage() {
      return this.getSiteFrontPage?.showVerbalOption;
    },
    showBookMeetingPage() {
      return this.getSiteFrontPage?.showBookingOption;
    },
    landingPageText() {
      return isFalsy(this.getSiteFrontPage) ? {heading: '', paragraph: '', subHeading: '',} : this.getSiteFrontPage;
    },
    isTabletOrMobile() {
      return this.$vuetify.breakpoint.width < 1111;
    },
    getGradientFromColors() {
      if (isNotFalsy(this.getSiteColors?.secondaryColorCode)) {
        return `to top right, rgba(${hexToRgb(this.getSiteColors?.secondaryColorCode)},.33), rgba(${hexToRgb(this.getSiteColors?.primaryColorCode)},.85)`;
      }
      return '';
    },
    getBackGroundImageColor() {
      return this.getSiteFrontPage?.backgroundImageColor;
    }
  },
  methods: {
    getBackgroundImageSrc() {
      if (this.getBackGroundImageColor) {
        return require(`@/assets/png/${this.getBackGroundImageColor}.png`);
      }
    },
  }
};
</script>

<style lang="scss">

.card-wrapper {
  margin-top: -140px;
  margin-left: 15px;
}

.card-title {
  font-size: 20px;
  line-height: 23px;
}

.card-content {
  font-size: 15px;
  line-height: 25px;
}

.page-header {
  width: 832px;

  .page-header-title {
    padding: 0 12px;

    span {
      font-size: 45px;
      line-height: 60px;
      font-weight: bold;
    }
  }

  .page-header-subtitle {

    padding: 0 12px;

    span {
      font-size: 25px;
      line-height: 40px;
    }
  }

  .page-header-paragraph {
    margin-top: 20px;
    padding: 0 12px;

    span {
      font-size: 23px;
      line-height: 40px;
    }
  }
}

.home-action-card-wrapper {
  div:last-child {
    //margin-right: 0 !important;
  }
}
</style>
