export const publicNavPages = {
    home: {title: 'Hjem',},
    howToReport: {title: 'Sådan indberetter du',},
    aboutWhistleBlowing: {
        title: 'Whistleblowerordningen',
        subPage: {
            whistleblowerPolicy: 'Whistleblowerpolitik',
            yourProtection: 'Din beskyttelse',
            caseProcessing: 'Sagsbehandling',
        },
    },
    faq: {title: 'FAQ',}
};
