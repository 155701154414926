export const oralReportPage = {
    of: ' z ',
    title: 'Zgłoś swoje podejrzenie lub wiedzę telefonicznie - przeczytaj więcej o tym, jak chronimy ',
    link: 'Twoją tożsamość',
    stepProtectionOfficer: {
        title: 'Kliknij na osobę lub jednostkę, która ma otrzymać Twoje zgłoszenie?',
        subtitle: ' - Kliknij na osobę lub jednostkę, która ma otrzymać Twoje zgłoszenie',
        oneProtectionOfficerTitle: 'Kto otrzyma Twoje zgłoszenie?',
        oneProtectionOfficerSubtitle: '- Twoje zgłoszenie zostanie wysłane do następującej osoby',
    },
    stepSubsidiaryCompany: {
        title: 'Kogo dotyczy zgłoszenie?',
        subtitle: ' - - Wskaż, której firmy w grupie dotyczy Twój raport.',
    },
    stepIdentity: {
        title: 'Czy chcesz ujawnić swoją tożsamość?',
        subtitle: ' - Ujawnienie tożsamości jest dobrowolne. Wybierz poniżej.',
    },
    stepWhistleblowerRelation: {
        title: 'Jakie jest Twoje powiązanie z organizacją?',
        subtitle: ' - Opisz jakie masz powiązanie z organizacją, której Twoje zgłoszenie dotyczy. Np. że jesteś' +
            ' zatrudniony.',
        placeholder: 'Podaj powiązanie...*',
    },
    stepFollowReport: {
        title: 'Wybierz kod aby śledzić Twoją rezerwację spotkania',
        subtitle: ' - Musisz utworzyć kod, abyś mógł się zalogować i komentować, sprawdzić lub zatwierdzić swoje zgłoszenie.',
        sendReportButton: 'Uzyskaj numer telefonu',
    },
};
