export const tooltips = {
    whyIsInfoNeeded: {
        title: 'Why is this information necessary?',
        tooltipInfo: 'Information',
    },
    subsidiary: {
        text: 'We need to know which company in the group your report relates to, in order to investigate the matter in the best possible way.'
    },
    whistleblowerRelation: {
        text: 'The whistleblower scheme can only be used by the group of persons specified in the whistleblower policy. It is therefore necessary to know your relationship.'
    },
    describeProblem: {
        text: 'In order for us to conduct a thorough investigation of your report, it is important that we have as much and as detailed information as possible. You should therefore describe what you report, when it happened, where it happened, who is involved, whether there is anyone who can witness the incident, etc.',
    },
    reportRelated: {
        text: 'Our investigations into your report can be targeted much more accurately if we know the identity of the person or persons performing the reported actions. If you do not know the identity, but for example know that your report relates to a specific department, this is also a knowledge of great value to us when we have to plan our investigations'
    },
    documentUpload: {
        text: 'If you have any evidence in relation to your report, it is of great value to us and our investigations. Evidence can be photos, copies of documents, etc.',
    },
    identity: {
        text: 'Our possibilities to investigate your report are generally best if we know your identity. We hope that you will disclose your identi to us, but it is voluntary and we will investigate your report whether we know your identity or not.',
    }

};
