<template>
  <div>
    <PrivateNavigationDrawer/>
    <div class="ml-12" :class="{ 'nav-expanded': !isMini }">
      <transition name="fade" mode="out-in" appear>
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
import PrivateNavigationDrawer from '@/components/bars/private/PrivateNavigationDrawer';
import {mapActions} from 'vuex';
import KeyCloakService from '@/services/auth/KeyCloakService';

export default {
  name: 'AuthenticatedWhistleBlower',
  components: {PrivateNavigationDrawer},
  data() {
    return {
      expand: true,
    };
  },
  computed: {
    isMini() {
      return this.$vuetify.breakpoint.width < 1000;
    }
  },
  created() {
    this.getCaseWithAuthToken();
    this.updateToken();
  },
  methods: {
    ...mapActions('authCase', ['getCaseWithAuthToken', 'retrieveCaseMessages']),
    toggleContent(expand) {
      this.expand = !expand;
    },
    async updateToken() {
      setInterval(async () => {
        await KeyCloakService.refreshToken();
      }, (60 * 1000));
    }
  }
};
</script>

<style scoped>
.nav-expanded {
  padding-left: 200px;
}
</style>
