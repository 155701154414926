export const modal = {
    newCaseCreated: {
        whatShouldYouDo: 'You now need to do the following:',
        thankYouSubmitting: 'Thank you for submitting your report',
        caseNumber: 'Case number #',
        reportReceived: 'Din indberetning er modtaget',
        saveCaseNumberAndLogWithIt: 'Gem nedenstående sagsnummer for at logge ind og følge din indberetning. Du logger ind på forsiden.',
        readMoreHere: 'Read more about how we ',
        readMoreHere1: 'manage your report',
        weFollowUpWithin7Days: 'Vi følger op på din indberetning inden for 7 dage.',
        noNeedToDoMoreThings: 'It is ',
        noNeedToDoMoreThings1: 'important',
        noNeedToDoMoreThings2: ' that you save your case number and your chosen password.',
        caseWillBeHandledByCompanyWbUnit: 'You will need this information to log in and follow your case.',
        loginVerify: 'Log-in and verify your account',
        callUs: 'Call the provided phone number',
        logInAgainOn7Days: 'Log-in again in 7 days to see your confirmation',
        loginInContinuously: 'Log-in continuously to follow the case',
        arrangeTime: 'Arrange time and place',

        youHaveChooseToNotFollow: 'You have chosen not to follow your report.',
        weCanotContactYou: 'If you have not informed about your identity we can not contact you and you will not' +
            ' hear from us regarding the case management.',
        forOtherThingsYouShouldCreateANewReport: 'If you at a later point in time would like to supplement your' +
            ' report you need to make a new report.',
        saveTheNumberJustInCase: 'It is ',
        saveTheNumberJustInCase1: 'important',
        saveTheNumberJustInCase2: ' that you save your case number. This is your documentation of your report. ',

    },
    errorDialog: {
        unableToCreateCase: 'Can not create case. Complete the form and try again.',
        close: 'Close',
    },
    validationDialog: {
        title: 'Information missing',
        completeInformationMissing: 'Complete the form with all necessary information.',
        ok: 'Ok',
    },
    dataWillBeDiscardedDialog: {
        title: 'If you continue, the entered data will be discarded.',
        ok: 'Continue',
        close: 'Cancel',
    },
    caseNumber: 'Case no',
    toTheFront: 'To the frontpage',
    login: 'Log in',
};
