<template>
  <MainLayout>
    <div>
      <NewCaseCreated/>

      <CannotCreateCase/>

      <FormValidationError/>
    </div>

    <StepSubsidiaryCompany
        v-if="hasSubsidiaries"
        :header-text="$t('onlineFormPage.title')"
        :header-link="$t('onlineFormPage.link')"
    >
      <div slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('onlineFormPage.stepSubsidiaryCompany.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-8">
          <span class="font-weight-bold">{{ stepSubStartingFrom }} {{ $t('onlineFormPage.of') }} {{ maxStep }}</span>
          {{ $t('onlineFormPage.stepSubsidiaryCompany.subtitle') }}
        </v-card-subtitle>
      </div>
    </StepSubsidiaryCompany>

    <StepChooseProtectionOfficer
        class="mb-12"
        :show-top-bar-header="!hasSubsidiaries"
        :header-text="$t('onlineFormPage.title')"
        :header-link="$t('onlineFormPage.link')"
    >
      <div v-if="isOneProtectionOfficer" slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('onlineFormPage.stepProtectionOfficer.oneProtectionOfficerTitle') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-2">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 1 }} {{ $t('onlineFormPage.of') }} {{ maxStep }}
          </span>
          {{ $t('onlineFormPage.stepProtectionOfficer.oneProtectionOfficerSubtitle') }}
        </v-card-subtitle>
      </div>
      <div v-else slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('onlineFormPage.stepProtectionOfficer.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-2">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 1 }} {{ $t('onlineFormPage.of') }} {{ maxStep }}
          </span>
          {{ $t('onlineFormPage.stepProtectionOfficer.subtitle') }}
        </v-card-subtitle>
      </div>
    </StepChooseProtectionOfficer>

    <StepWhistleblowerRelation>
      <div slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('onlineFormPage.stepWhistleblowerRelation.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-2">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 2 }} {{ $t('onlineFormPage.of') }} {{ maxStep }}
          </span>
          {{ $t('onlineFormPage.stepWhistleblowerRelation.subtitle') }}
        </v-card-subtitle>
      </div>
    </StepWhistleblowerRelation>

    <StepDescribeProblem>
      <div slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('onlineFormPage.stepDescribeProblem.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-8">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 3 }} {{ $t('onlineFormPage.of') }} {{ maxStep }}
          </span>
          {{ $t('onlineFormPage.stepDescribeProblem.subtitle') }}
        </v-card-subtitle>
      </div>
    </StepDescribeProblem>

    <StepReportRelated>
      <div slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('onlineFormPage.stepReportRelated.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-8">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 4 }} {{ $t('onlineFormPage.of') }} {{ maxStep }}
          </span>
          {{ $t('onlineFormPage.stepReportRelated.subtitle') }}
        </v-card-subtitle>
      </div>
    </StepReportRelated>

    <StepDocumentUpload>
      <div slot="card-title">
        <v-card-title class="pa-0 ma-0 word-brake primary--text">
          {{ $t('onlineFormPage.stepDocumentUpload.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-8">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 5 }} {{ $t('onlineFormPage.of') }} {{ maxStep }}
          </span>
          {{ $t('onlineFormPage.stepDocumentUpload.subtitle') }}
        </v-card-subtitle>
      </div>
    </StepDocumentUpload>

    <StepIdentity>
      <div slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('onlineFormPage.stepIdentity.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-8">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 6 }} {{ $t('onlineFormPage.of') }} {{ maxStep }}
          </span>
          {{ $t('onlineFormPage.stepIdentity.subtitle') }}
        </v-card-subtitle>
      </div>
    </StepIdentity>

    <StepFollowReport @sendCase="sendCase()">
      <div slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('onlineFormPage.stepFollowReport.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-8">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 7 }} {{ $t('onlineFormPage.of') }} {{ maxStep }}
          </span>
          {{ $t('onlineFormPage.stepFollowReport.subtitle') }}
        </v-card-subtitle>
      </div>
      <div slot="action-button">
        <span class="pr-3">{{ $t('onlineFormPage.stepFollowReport.sendReportButton') }}</span>
        <v-icon>mdi-arrow-right</v-icon>
      </div>
    </StepFollowReport>
  </MainLayout>
</template>

<script>
import StepDescribeProblem from '@/components/card/stepForm/StepDescribeProblem';
import StepReportRelated from '@/components/card/stepForm/StepReportRelated';
import StepDocumentUpload from '@/components/card/stepForm/StepDocumentUpload';
import StepIdentity from '@/components/card/stepForm/StepIdentity';
import StepFollowReport from '@/components/card/stepForm/StepFollowReport';
import NewCaseCreated from '@/components/modal/NewCaseCreated';
import StepChooseProtectionOfficer from '@/components/card/stepForm/StepChooseProtectionOfficer';
import CannotCreateCase from '@/components/modal/CannotCreateCase';
import StepSubsidiaryCompany from '@/components/card/stepForm/StepSubsidiaryCompany';
import MainLayout from '@/components/layout/MainLayout';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {caseCreating} from '@/model/casePayload';
import FormValidationError from '@/components/modal/FormValidationError';
import {scrollTopId} from '@/utils/scrollUtils';
import StepWhistleblowerRelation from '@/components/card/stepForm/StepWhistleblowerRelation';

export default {
  name: 'OnlineForm',
  components: {
    StepWhistleblowerRelation,
    StepSubsidiaryCompany,
    FormValidationError,
    StepChooseProtectionOfficer,
    MainLayout,
    CannotCreateCase,
    NewCaseCreated,
    StepDescribeProblem,
    StepReportRelated,
    StepDocumentUpload,
    StepIdentity,
    StepFollowReport
  },
  data() {
    return {
      maxStep: 7,
      stepSubStartingFrom: 0,
    };
  },
  computed: {
    ...mapGetters('onlineForm', ['getShowFormValidationErrorDialog', 'getFormValidationIdToNavigate']),
    ...mapGetters('protectionOfficer', ['getAllProtectionOfficers', 'getCompanyInfo', 'hasSubsidiaries']),
    isOneProtectionOfficer() {
      return this.getAllProtectionOfficers.length === 1;
    },
  },

  watch: {
    'getCompanyInfo': {
      handler() {
        this.initNumberOfSteps();
      },
      deep: true
    }
  },
  mounted() {
    this.listenForOnlineFormChanges();
    this.initNumberOfSteps();
    this.setCaseCreatedFrom(caseCreating.ONLINE_FORM);
  },


  beforeDestroy() {
    // this cleans up all the events before destroy,
    this.$root.$off();
  },


  beforeRouteLeave(to, from, next) {
    this.resetFromValidation();
    this.resetFormDialogData();
    this.resetCasePayLoadState();
    next();
  },

  methods: {
    ...mapMutations('onlineForm', [
      'setWhistleblowerRelation',
      'setWhistleblowerAccountFollow',
      'setWhistleblowerAccountPassword',
      'setWhistleblowerIdentity',
      'setSelectedChiefOfficer',
      'setFiles',
      'setReportedPersons',
      'setProblemDescription',
      'setCaseCreatedFrom',
      'setReportedUnit',
      'setSubsidiaryCompany',
    ]),
    ...mapActions('onlineForm', ['uploadCase', 'validateonlineForm']),
    ...mapMutations('onlineForm', ['resetFromValidation', 'resetFormDialogData', 'resetCasePayLoadState']),
    listenForOnlineFormChanges() {
      this.$root.$on('WhistleblowerRelation', this.setWhistleblowerRelation);
      this.$root.$on('SelectedChiefOfficer', this.setSelectedChiefOfficer);
      this.$root.$on('ProblemDescription', this.setProblemDescription);
      this.$root.$on('ReportedPersons', this.setReportedPersons);
      this.$root.$on('ReportedUnit', this.setReportedUnit);
      this.$root.$on('StepSubsidiaryCompany', this.setSubsidiaryCompany);
      this.$root.$on('FileUploaded', this.setFiles);
      this.$root.$on('WhistleblowerIdentity', this.setWhistleblowerIdentity);
      this.$root.$on('WhistleblowerAccountFollow', data => {
        this.setWhistleblowerAccountFollow(data.follow);
        this.setWhistleblowerAccountPassword(data.password);
      });
    },
    sendCase() {
      this.validateonlineForm();
      if (this.getShowFormValidationErrorDialog) {
        this.$nextTick(() => scrollTopId(this.getFormValidationIdToNavigate));
      } else {
        this.uploadCase();
      }
    },
    initNumberOfSteps() {
      if (this.hasSubsidiaries) {
        this.maxStep = 8;
        this.stepSubStartingFrom = 1;
      }
    }
  }
};
</script>

<style lang="scss">

</style>
