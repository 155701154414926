export const privateNavPages = {
    status: {
        title: 'Estado de tu expediente',
    },
    messages: {
        title: 'Mensajes',
    },
    yourReport: {
        title: 'Ver tu denuncia',
    },
    yourIdentity: {
        title: 'Tus datos',
    },
};
