export const step = {
    describeProblem: {
        describe: 'Beschreibung eingeben*',
        choose: 'Bitte wählen...*',
        rulesViolated: 'Gegen welche Vorschriften wurde Ihrer Meinung nach verstoßen?',
        isProblemRepeated: 'Hat sich der Vorfall mehrmals ereignet?',
        howOftenIsRepeated: 'Bitte geben Sie in der Beschreibung an, wie oft sich der Vorfall ereignet hat',
        yes: 'Ja',
        no: 'Nein',
    },

    protectionOfficer: {
        'Ekstern': 'Extern',
        'Intern': 'Intern',
    },

    documentUpload: {
        max: 'Max.',
        perFile: ' pro Datei',
    },

    followReport: {
        yesFollowReport: 'Ja, ich möchte den Fall verfolgen',
        yesFollowTheCaseWithPassword: 'Mit Ihrer Fallnummer und Ihrem selbstgewählten Passwort können Sie weitere Informationen ergänzen und Rückmeldungen erhalten.',
        noDontFollowReport: 'Nein, ich möchte den Fall nicht verfolgen.',
        noDontWantToHearFromCase: 'Wenn Sie Ihre Meldung gemacht haben, werden Sie keine Rückmeldungen und Angaben über den Fall erhalten.',
        enterPassword: 'Passwort wählen*',
        confirmPassword: 'Passwort erneut eingeben*',
        mustConfirmPassword: 'Bitte bestätigen Sie Ihr Passwort',
        iAcceptTheTerms: 'Ich habe die ',
        iAcceptTheTerms1: ' Whistleblower-Bestimmungen gelesen',
        logInIn10Days: 'Achtung! ',
        logInIn10Days1: 'Aus Sicherheitsgründen müssen Sie sich innerhalb von 10 Tagen einloggen. Andernfalls wird' +
            ' Ihr Benutzerkonto gelöscht.',
    },

    identity: {
        yesRevealIdentity: 'Ja, ich möchte meine Identität offenlegen',
        enterIdentity: 'Ihre Identität wird vertraulich behandelt und nur den Personen mitgeteilt, denen sie mitgeteilt werden muss.',
        noDontRevealIdentity: 'Nein, ich möchte meine Identität nicht offenlegen',
        stayAnonym: 'Sie sind grundsätzlich anonym, aber Ihr Hinweis kann Ihre mögliche Identität offenlegen. In' +
            ' diesem Fall werden wir dies vertraulich behandeln.',
        firstName: 'Vor- und Nachname*',
        email: 'E-Mail-Adresse*',
        phoneNr: 'Telefonnummer',
        examplePhoneNr: 'Beispiel Telefonnummer',
        wantToReceive: 'Ich möchte E-Mail-Benachrichtigungen erhalten',
        youNeedToSelectAnOption: '* Das Feld ist obligatorisch. Wählen Sie eine Option.',
    },

    makeQuestions: {
        title: 'Stellen Sie Fragen über die Verwendung des Hinweisgebersystems – lesen Sie weitere Informationen über das ',
        whistleblowing: 'Hinweisgebersystem',
        phone: 'Telefonisk',
        online: 'Online',
        callThisNumber: 'Telefonen er åben alle hverdage i tidsrummet 08.00-17.00.',
        defaultPhoneNumber: '+45 88 91 92 45',
        defaultContactPerson: 'Søren Wolder, Advokat, DAHL Advokatpartnerselskab',
        defaultContactInfo: 'Telefonen er åben alle hverdage i tidsrummet 08.00-17.00.',
    },

    reportRelated: {
        selectPersonsInCompany: 'Benannte Personen',
        company: 'Keine benannten Personen',
        youNeedToSelectAnOption: '* Das Feld ist obligatorisch.',
        firstName: 'Vor- und Nachname*',
        position: 'Jobbezeichnung',
        phoneNrOrEmail: 'Telefonnummer/E-Mail-Adresse',
        addNewPerson: 'Weitere Personen hinzufügen',
        unit: 'Abteilung'
    },
};
