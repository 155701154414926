export const tooltips = {
    whyIsInfoNeeded: {
        title: '¿Por qué es necesaria esta información?',
        tooltipInfo: 'Información',
    },
    subsidiary: {
        text: 'We need to know which company in the group your report relates to, in order to investigate the matter' +
            '' +
            ' in the best possible way.'
    },
    whistleblowerRelation: {
        text: 'Podrá hacer uso del sistema de denuncia de irregularidades únicamente el círculo de personas ' +
            'mencionado en la política de denuncia de irregularidades. Por lo tanto, es necesario conocer tu relación ' +
            'con la empresa.'
    },
    describeProblem: {
        text: 'Para poder analizar detenidamente la denuncia, es importante que tengamos el mayor número posible de ' +
            'datos y detalles. Por lo tanto, deberás describir el objeto de tu denuncia, el momento y el lugar del ' +
            'incidente, las partes involucradas, si hay testigos del incidente, etc. Deberás describir también tu ' +
            'relación con la empresa –por ejemplo, que eres un empleado, un colega íntimo o relación parecida.',
    },
    reportRelated: {
        text: 'Podremos analizar tu denuncia mucho mejor si conocemos la identidad de la(s) persona(s) que comete(n) ' +
            'los hechos denunciados. Si no conoces su identidad, pero sabes, por ejemplo, que tu denuncia concierne a' +
            ' un departamento determinado, estos datos son también muy valiosos para nosotros en relación con la' +
            ' planificación de nuestras investigaciones.'
    },
    documentUpload: {
        text: 'Será de gran ayuda para nuestro examen si dispones de documentación que sustente tu denuncia. Pueden' +
            ' ser fotos, copias de documentos, etc.',
    },
    identity: {
        text: 'En general, nuestras posibilidades de examinar tu denuncia son mejores si conocemos tu identidad. Por' +
            ' lo tanto, esperamos que desees revelarla. Aportar tus datos es voluntario, y examinaremos tu denuncia' +
            ' con independencia de que conozcamos tu identidad o no.',
    }

};
