import Home from '@/views/public/Home';
import OnlineForm from '@/views/public/subpages/OnlineForm';
import Faq from '@/views/public/Faq';
import OralFromReport from '@/views/public/subpages/OralFromReport';
import BookMeeting from '@/views/public/subpages/BookMeeting';
import AskQuestions from '@/views/public/subpages/AskQuestions';
import NotFound from '@/views/public/NotFound';
import LandingPage from '@/views/public/subpages/LandingPage';
import PersonalData from '@/views/public/about-whistleblowing/PersonalData';
import Anonymity from '@/views/public/about-whistleblowing/Anonymity';
import CaseProcess from '@/views/public/about-whistleblowing/CaseProcess';
import HowToReport from '@/views/public/HowToReport';
import SomethingWentWrong from '@/views/public/SomethingWentWrong';
import SiteLookAndFeelService from '@/services/SiteLookAndFeelService';

export default [
    {
        path: '',
        name: 'Home',
        component: Home,
        meta: {
            authenticated: false
        },
        children: [
            {
                path: '',
                name: 'LandingPage',
                component: LandingPage,
                meta: {
                    authenticated: false
                }
            },
            {
                path: '/online-form',
                name: 'OnlineForm',
                component: OnlineForm,
                meta: {
                    authenticated: false
                }
            },
            {
                path: '/oral-report',
                name: 'OralReport',
                component: OralFromReport,
                meta: {
                    authenticated: false
                },
                beforeEnter: async (to, from, next) => {
                    let canAccessPage = await SiteLookAndFeelService.getCanAccessPageWithOption('showVerbalOption');
                    await continueOrGoBack(next, from, canAccessPage);
                }

            },
            {
                path: '/book-meeting',
                name: 'BookMeeting',
                component: BookMeeting,
                meta: {authenticated: false},
                beforeEnter: async (to, from, next) => {
                    let canAccessPage = await SiteLookAndFeelService.getCanAccessPageWithOption('showBookingOption');
                    await continueOrGoBack(next, from, canAccessPage);
                }
            },
            {
                path: '/ask-questions',
                name: 'AskQuestions',
                component: AskQuestions,
                meta: {
                    authenticated: false
                }
            },
            {
                path: '/faq',
                name: 'FAQ',
                component: Faq,
                meta: {
                    authenticated: false
                }
            },
            {
                path: '/how-to-report',
                name: 'HowToReport',
                component: HowToReport,
                meta: {
                    authenticated: false
                }
            },
            {
                path: '/about-whistleblowing/personal-data',
                name: 'PersonalData',
                component: PersonalData,
                meta: {
                    authenticated: false
                }
            },
            {
                path: '/about-whistleblowing/anonymity',
                name: 'Anonymity',
                component: Anonymity,
                meta: {
                    authenticated: false
                }
            },
            {
                path: '/about-whistleblowing/case-process',
                name: 'CaseProcess',
                component: CaseProcess,
                meta: {
                    authenticated: false
                }
            },
        ]
    },
    {
        path: '/something-went-wrong',
        name: 'SomethingWentWrong',
        component: SomethingWentWrong,
        meta: {
            authenticated: false
        }
    },
    {

        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound,
        meta: {
            authenticated: false
        }
    }
];


const continueOrGoBack = async (next, from, canAccessPage) => {
    if (canAccessPage) {
        next();
    } else {
        next(from.path);
    }
};
