<template>
  <div class="mb-12">
    <div id="stepSubsidiaryCompany">
      <v-card
          v-if="showDescriptionBar"
          elevation="0"
          min-height="70"
          color="primary"
          class="rounded-b-0 card-inner-padding pt-2 pb-2 d-flex align-center"
      >
        <div>
          <span class="white--text card-header-title">{{ headerText }}</span>
          <span class="white--text card-header-title text-decoration-underline c-point" @click="openInNewTab">
            {{ headerLink }}
          </span>
        </div>
      </v-card>

      <v-card
          class="mx-auto card-border-bottom card-inner-padding"
          max-height="650"
          max-width="100%"
          rounded
          elevation="2"
      >
        <div>
          <div>
            <slot name="card-title"/>
          </div>

          <v-row class="d-flex justify-space-between">
            <v-select
                v-model="subsidiaryCompanySelected"
                :items="getCompanyInfo.subsidiaries"
                :placeholder="$t('onlineFormPage.stepSubsidiaryCompany.placeholder')"
                class="pl-3 pr-3 pt-3"
                outlined
                :error="displayFromValidationError"
                @change="emitSelectedSubsidiaryCompany()"
            />
          </v-row>

          <StepInformationFooter :text="$t('tooltips.subsidiary.text')"/>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import StepInformationFooter from '@/components/tooltip/StepInformationTooltip';
import {mapGetters, mapMutations} from 'vuex';
import {isNotFalsy} from '@/utils/guards';

export default {
  name: 'StepSubsidiaryCompany',
  components: {StepInformationFooter},
  props: {
    headerText: {
      type: String,
      default: null,
    },
    headerLink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      subsidiaryCompanySelected: null,
    };
  },
  computed: {
    ...mapGetters('protectionOfficer', ['getCompanyInfo']),
    ...mapGetters('onlineForm', ['getShowFormValidationErrorDialog', 'getFormValidation']),
    showDescriptionBar() {
      return this.headerText !== null;
    },
    displayFromValidationError() {
      if (this.getShowFormValidationErrorDialog) {
        return !this.getFormValidation.stepSubsidiaryCompany.isValid;
      }
      return false;
    },
  },
  watch: {
    'getCompanyInfo': {
      handler() {
        this.setSubsidiaryCompanyValidated(false);
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations('onlineForm', ['setValidFromStep']),

    emitSelectedSubsidiaryCompany() {
      this.setSubsidiaryCompanyValidated(isNotFalsy(this.subsidiaryCompanySelected));
      this.$root.$emit('StepSubsidiaryCompany', this.subsidiaryCompanySelected);
    },

    setSubsidiaryCompanyValidated(isValid) {
      this.setValidFromStep({
        validStep: 'stepSubsidiaryCompany',
        isValid
      });
    },
    openInNewTab() {
      let routeData = this.$router.resolve({name: 'PersonalData'});
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style scoped></style>
