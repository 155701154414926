export const rules = {
    input: {
        fieldIsRequired: '* Obligatorisk',
        validPhoneNumber: '* indtast gyldigt telefonnummer i format +45xxxxxxxx',
        validPhoneNumberOrEmail: 'Indtastede tekst skal være en e-mail eller et telefonnummer med format +45xxxxxxxx',
        email: 'E-posten må være gyldig',
        min4Char: '* Passordet må inneholde minst 4 tegn',
        contentShouldNotBeLonger: 'Description must be 200 characters or less',
    }
};
