export const privateNavPages = {
    status: {
        title: 'Ärendestatus',
    },
    messages: {
        title: 'Meddelanden',
    },
    yourReport: {
        title: 'Se din rapport',
    },
    yourIdentity: {
        title: 'Dina kontaktuppgifter',
    },
};
