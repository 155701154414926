<template>
  <v-row class="d-flex justify-space-between">
    <v-col cols="12" :lg="hasEnteredFiles() ? 6 : 12" :xl="hasEnteredFiles() ? 6 : 12" md="12" sm="12">
      <v-card
          outlined
          width="100%"
          height="351"
          :class="{ 'elevation-14': dragover , 'elevation-0': !dragover }"
          class="elevation-24 border-all-1 upload-card-gradient "
          @drop.prevent="onDrop($event)"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
      >
        <div class="d-flex flex-column align-center" style="margin-top: 60px">
          <div class="mt-2">
            <v-icon size="60" color="icons">mdi-cloud-upload-outline</v-icon>
          </div>
          <div style="width: 100px; text-align: center" class="mt-2 drop-files-here-text">
            {{ $t('misc.fileInput.dropFilesHere') }}
          </div>
          <div class="mt-2">
            <input
                ref="uploader"
                class="d-none"
                type="file"
                :accept="allowedExtensions()"
                multiple
                @change="onFileChanged"
                @click="resetImageUploader"
            >
            <v-btn
                width="196"
                height="55"
                color="primary"
                class="text-none"
                style="font-weight: lighter"
                @click="openFileInput()"
            >
              <span class="font-weight-regular">

                {{ $t('misc.fileInput.browseFiles') }}
              </span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" xl="6" md="12" sm="12" :class="getFileDescriptionCardClass()">
      <v-card width="100%" max-height="351" elevation="0" class="overflow-y-auto overflow-x-hidden">
        <FileDescriptionCard
            v-for="(file, index) in uploadedFiles"
            :key="index"
            :index="index"
            :file-data="file"
            @removeFile="removeFile"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FileDescriptionCard from '@/components/card/fileCards/FileDescriptionCard';
import {isNotFalsy} from '@/utils/guards';
import {ALLOWED_MIME_TYPES, allowedExtensions} from '@/utils/fileCategory';

const FILE_UPLOAD_LIMIT = 5;

export default {
  name: 'FileUpload',
  props: {
    files: {
      type: Array,
      default: null
    }
  },
  components: {
    FileDescriptionCard,
  },
  data() {
    return {
      dragover: false,
      uploadedFiles: []
    };
  },
  watch: {
    'files': {
      handler() {
        this.initFiles();
      },
      deep: true
    }
  },
  mounted() {
    this.initFiles();
  },
  methods: {
    initFiles() {
      if (isNotFalsy(this.files)) {
        this.uploadedFiles = this.files;
      }
    },
    hasEnteredFiles() {
      return this.uploadedFiles.length > 0;
    },
    openFileInput() {
      window.addEventListener('focus', () => {
        this.isSelecting = false;
      }, {once: false});

      this.$refs.uploader.click();
    },
    resetImageUploader() {
      this.$refs.uploader.value = null;
    },
    allowedExtensions,
    onDrop(e) {
      this.dragover = false;
      this.addFiles(e.dataTransfer.files ?? [], this.canAddFile);
    },
    onFileChanged(e) {
      this.addFiles(e.target.files ?? [], null);
    },
    addFiles(fileList, canAddFileCheck) {
      for (const file of fileList) {
        let canAllowFile = canAddFileCheck ? canAddFileCheck(file) : true;

        if (canAllowFile && this.uploadedFiles.length < FILE_UPLOAD_LIMIT) {
          this.uploadedFiles.push(file);
        }
      }
      this.setFileUploaded();
    },
    removeFile(index) {
      this.uploadedFiles.splice(index, 1);
      this.setFileUploaded();
    },
    getFileDescriptionCardClass() {
      return !this.hasEnteredFiles() ? 'd-none' : 'd-flex';
    },
    setFileUploaded() {
      this.$root.$emit('FileUploaded', this.uploadedFiles);
    },
    canAddFile: (file) => ALLOWED_MIME_TYPES.includes(file.type)
  }
};
</script>


<style lang="css" scoped>

.upload-card-gradient {
  background: transparent linear-gradient(63deg, #F5F5F5 0%, #EEF6FD 52%, #E5F1FC 100%) 0 0 no-repeat padding-box;
}

</style>
