export const privateNavPages = {
    status: {
        title: 'Status Twojej sprawy',
    },
    messages: {
        title: 'Wiadomości',
    },
    yourReport: {
        title: 'Zobacz Twoje zgłoszenie',
    },
    yourIdentity: {
        title: 'Twoje informacje',
    },
};
