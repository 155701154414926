<template>
  <div>
    <div class="custom-header-title">{{ headerName }}</div>
    <div class="custom-divider"/>
  </div>
</template>

<script>
export default {
  name: 'PublicPageTitle',
  props: {
    headerName: {
      type: String
    }
  }
};
</script>

<style scoped>
.custom-header-title {
  font-size: 30px;
  line-height: 34px;
  color: var(--v-primary-base);
}

</style>
