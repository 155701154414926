export const publicNavPages = {
    home: {title: 'Hjem',},
    howToReport: {title: 'Hvordan rapportere',},
    aboutWhistleBlowing: {
        title: 'Varslerordningen',
        subPage: {
            whistleblowerPolicy: 'Retningslinjer for varsling',
            yourProtection: 'Din beskyttelse',
            caseProcessing: 'Saksbehandling',
        },
    },
    faq: {title: 'FAQ',}
};
