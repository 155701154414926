export const onlineFormPage = {
    of: ' af ',
    title: 'Indberet din mistanke via online formular - læs mere om, hvordan vi ',
    link: ' beskytter din identitet',
    stepProtectionOfficer: {
        title: 'Hvem skal modtage din indberetning?',
        subtitle: ' - Klik på den person eller enhed, du ønsker skal modtage din indberetning.',
        oneProtectionOfficerTitle: 'Hvem vil modtage din indberetning?',
        oneProtectionOfficerSubtitle: ' - Din indberetning vil blive sendt til følgende person',
    },
    stepWhistleblowerRelation: {
        title: 'Hvad er din relation til organisationen?',
        subtitle: 'Beskriv hvilken relation, du har til organisationen som din indberetning vedrører. F.eks. at du er' +
          ' ansat.',
        placeholder: 'Angiv relation...*',
    },
    stepDescribeProblem: {
        title: 'Hvad vil du gerne indberette?',
        subtitle: ' - Du skal herunder beskrive det forhold, du ønsker at indberette. Giv så mange detaljer som' +
            ' muligt.',
    },
    stepSubsidiaryCompany: {
        title: 'Hvilket selskab vedrører indberetningen?',
        subtitle: ' - Angiv hvilket selskab i koncernen din indberetning vedrører.',
        placeholder:'Vælg selskab...*',
    },
    stepReportRelated: {
        title: 'Hvem vedrører indberetningen?',
        subtitle: ' - Du skal nu angive, hvem indberetningen omhandler.',
    },
    stepDocumentUpload: {
        title: 'Vedhæft evt. dokumentation i relation til hændelsen',
        subtitle: ' - Her har du mulighed for at vedhæfte filer, der dokumenterer den beskrevne hændelse',
    },
    stepIdentity: {
        title: 'Vil du oplyse din identitet?',
        subtitle: ' - Det er frivilligt, om du vil oplyse din identitet. Vælg herunder.',
    },
    stepFollowReport: {
        title: 'Vil du følge din indberetning?',
        subtitle: ' - Vælg om du vil orienteres om sagen og evt. bidrage med yderligere oplysninger. Du kan følge sagen, selvom du er anonym.',
        sendReportButton: 'Send',
    },

    save: 'Gem'
};
