export const report = {
    reportDescription: {
        caseNumber: 'Expediente número',
        received: 'Recibido',
        incidentReported: 'Descripción del incidente',
        rulesViolated: 'Normas presuntamente infringidas',
        isIncidentRepeated: 'El incidente ha ocurrido más de una vez',
        yes: 'Sí',
        no: 'No',
        attachIfIsNecessary: 'Documentación adjunta',
    },
    reportRelate: {
        reportRelateTo: 'Personas / unidades comprendidas por la denuncia',
        personsInCompany: 'Navngivne personer i virksomheden:',
        company: 'Virksomheden',
    }
};
