export const allPrivatePages = {
    caseStatus: {
        statusOfCaseNumber: 'Status på din sag #',
        caseWillBeInitiated: 'Sagen vil blive igangsat når du har kontaktet modtagerpersonen på det telefonnummer du modtog ved oprettelsen.',
        provideFeedback: 'Vi afventer din tilbagemelding',
        goToMessages: 'Gå til beskeder',
        provideFeedbackTillDate: 'Feedback modtaget',
        provideConfirmationDate: 'Bekræftelse modtaget',
    },
    messages: {
        title: 'Beskeder',
        nav: {
            sharedFiles: 'Delte Filer',
            allFiles: 'Alle filer',
            filterFiles: 'Filer',
            files: 'filer',
            document: 'Dokumenter',
            pictures: 'Billeder',
            video: 'Video',
            other: 'Andet',
        },
        chat: {
            writeCommentHere: 'Skriv din kommentar til sagen her...',
            addToCase: 'Tilfoj til sagen',
        }
    },
    seeReport: {
        submitComments: 'Indsend bemærkninger',
        approve: 'Godkend',

        commentsOnReport: {
            title: 'Bemærkninger til din indberetning',
            comment: 'Skriv dine bemærkninger til indberetningen og øvrige oplysninger her…',
            send: 'Send',
        },
        approveDialog: {
            title: 'Bekræft indholdet af din indberetning',
            description: 'Er indberetningen beskrevet korrekt? Du kan fortsat sende beskeder vedrørende sagen under "Beskeder"',
            cancel: 'Annuller',
            confirm: 'Godkend',
        }
    },
    yourInfo: {
        title: 'Hvis du ikke tidligere har oplyst din identitet, har du mulighed for at gøre det på denne side',
        save: 'Gem',
        cancel: 'Annuller',
        errorMessage: '',
    },
    changePassword: {
        title: 'Ændre dit kodeord til sagen',
        description: 'Hvis du ønsker at ændre dit kodeord til sagen, skal du trykke ‘Skift kodeord’ nedenfor:',
        changePassword: 'Skift kodeord',
        dialog: {
            title: 'Ændre dit kodeord til sagen',
            whatShouldYouDo: 'Du skal nu gøre følgende:',
            step1: 'Log ud via knappen nedenfor',
            step2: 'Log ind på din sag',
            step3: 'Angiv dit nye kodeord',
            descText1: 'For at skifte dit kodeord skal du af sikkerhedsmæssige årsager logge ud for at ændre dit kodeord.',
            descText2: 'Bekræft at du ønsker at ændre dit kodeord via knappen nedenfor, og du vil blive logget ud automatisk.',
            btnApprove: 'Bekræft og log ud',
        }
    },

};
