export const modal = {
    newCaseCreated: {
        whatShouldYouDo: 'A continuación, deberás seguir los siguientes pasos:',
        thankYouSubmitting: 'Gracias por tu denuncia',
        caseNumber: 'Expediente número # ',
        reportReceived: 'Din indberetning er modtaget',
        saveCaseNumberAndLogWithIt: 'Gem nedenstående sagsnummer for at logge ind og følge din indberetning. Du logger ind på forsiden.',
        readMoreHere: 'Obtén más información de cómo ',
        readMoreHere1: 'tramitamos tu denuncia',
        weFollowUpWithin7Days: 'Vi følger op på din indberetning inden for 7 dage.',
        noNeedToDoMoreThings: 'es ',
        noNeedToDoMoreThings1: 'importante',
        noNeedToDoMoreThings2: ' que guardes tu número de expediente y tu contraseña.',
        caseWillBeHandledByCompanyWbUnit: 'Necesitarás estos datos para poder iniciar sesión y mantenerte informado sobre tu expediente.',
        loginVerify: 'Inicia sesión y verifica tu cuenta',
        callUs: 'Llama al número de teléfono que se te ha facilitado',
        logInAgainOn7Days: 'Vuelve a iniciar sesión dentro de 7 días para ver tu confirmación',
        loginInContinuously: 'Inicia sesión con frecuencia para mantenerte informado sobre el expediente',
        arrangeTime: 'Acuerda fecha, hora y lugar',

        youHaveChooseToNotFollow: 'Du har valgt ikke at følge din indberetning.',
        weCanotContactYou: 'Hvis du ikke har oplyst din identitet, kan vi ikke kontakte dig, og du vil ikke høre fra os i forhold til sagens behandling.',
        forOtherThingsYouShouldCreateANewReport: 'Hvis du på et senere tidspunkt vil supplere din indberetning, skal du foretage en ny indberetning.',
        saveTheNumberJustInCase: 'Det er ',
        saveTheNumberJustInCase1: 'vigtigt',
        saveTheNumberJustInCase2: ', at du gemmer dit sagsnummer. Dette er din dokumentation for din indberetning. ',

    },
    errorDialog: {
        unableToCreateCase: 'Kan ikke oprette indberetningen. Udfyld de nødvendige oplysinger og prøv igen.',
        close: 'Luk',
    },
    validationDialog: {
        title: 'Oplysninger mangler',
        completeInformationMissing: 'Udfyld formularen med alle nødvendige oplysninger.',
        ok: 'Ok',
    },
    dataWillBeDiscardedDialog: {
        title: 'Hvis du fortsætter, vil de indtastede oplysninger blive kasseret',
        ok: 'Fortsæt',
        close: 'Fortryd',
    },
    caseNumber: 'Expediente número',
    toTheFront: 'Til forsiden',
    login: 'Iniciar sesión',
};
