<template>
  <div>
    <v-card
        class="mx-auto"
        min-height="230"
        max-width="100%"
        rounded
        elevation="0"
    >
      <div class="card-inner-padding">
        <slot name="card-title"/>
        <div>
          <v-row class="d-flex justify-space-between mt-2">
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <v-text-field
                  v-model="whistleblowerIdentity.name"
                  :placeholder="$t('step.identity.firstName')"
                  :rules="rules.fieldRequiredRules"
                  outlined
                  :disabled="canModifyName"
                  validate-on-blur
                  @input="setStepIdentityValues()"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <v-text-field
                  v-model="whistleblowerIdentity.email"
                  :placeholder="$t('step.identity.email')"
                  :rules="rules.emailRequiredRules"
                  outlined
                  validate-on-blur
                  @input="setStepIdentityValues()"
              />
            </v-col>
          </v-row>

          <v-row class="d-flex justify-space-between mt-0">
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <vue-phone-number-input
                  v-model="phoneNr.text"
                  class="border-all-2 h-100-p"
                  :class="{'border-all-error-1': error.show && !phoneNr.isValid}"
                  :color="phoneNr.color"
                  :valid-color="primaryColor"
                  :error-color="errorColor"
                  :default-country-code="whistleblowerIdentity.phoneCountryCode"
                  :translations="{
                    countrySelectorLabel: $t('step.identity.phoneNr'),
                    countrySelectorError: $t('rules.input.validPhoneNumber'),
                    phoneNumberLabel: $t('step.identity.phoneNr'),
                    example: $t('step.identity.examplePhoneNr'),
                  }"
                  @update="setStepPhoneNumber"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <v-card outlined elevation="0" width="100%" min-height="62" class="border-all-2">
                <v-radio-group
                    v-model="whistleblowerIdentity.notification"
                    class="mt-3 ml-3 pb-0 mb-0"
                    hide-details
                    @change="toggleNotification()"
                >
                  <v-radio
                      :label="$t('step.identity.wantToReceive')"
                      :value="true"
                      @click="toggleNotification()"
                  />
                </v-radio-group>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {emailRequiredRules, fieldRequiredRules, isValidEmail} from '@/utils/rules/inputRules';
import {isNotFalsy} from '@/utils/guards';
import {mapGetters, mapMutations} from 'vuex';
import {observableToObject} from '@/utils/weirdities/observableToObject';
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  name: 'StepYourApplicationIdentity',
  props: {
    identity: {
      type: Object,
      default: null,
    },
  },
  components: {VuePhoneNumberInput},
  data() {
    return {
      rules: {
        fieldRequiredRules,
        emailRequiredRules,
      },
      error: {
        show: false
      },
      phoneNr: {
        text: null,
        color: this.primaryColor,
        isValid: null,
      },
      whistleblowerIdentity: {
        email: null,
        name: null,
        phone: null,
        phoneCountryCode: null,
        notification: false
      }
    };
  },
  computed: {
    canModifyName() {
      return isNotFalsy(this.identity?.name);
    },
    hasEnteredName() {
      return isNotFalsy(this.whistleblowerIdentity.name);
    },
    primaryColor() {
      return this.$vuetify.theme.defaults.light.primary;
    },
    errorColor() {
      return this.$vuetify.theme.defaults.light.error;
    }
  },
  mounted() {
    this.initIdentityStepFrom();
  },
  watch: {
    'whistleblowerIdentity.phone': {
      handler() {
        this.phoneNr.color = this.phoneNr.isValid ? this.primaryColor : this.errorColor;
      },
    }
  },
  methods: {
    ...mapGetters('lookAndFeel', ['getPhoneCodeMapping']),
    ...mapMutations('onlineForm', ['setValidFromStep']),
    initIdentityStepFrom() {
      if (isNotFalsy(this.identity)) {
        this.whistleblowerIdentity = observableToObject(this.identity);
        this.phoneNr.text = this.whistleblowerIdentity?.phone;
      }
    },
    toggleNotification() {
      let {notification} = this.whistleblowerIdentity;
      this.whistleblowerIdentity.notification = !notification;
      this.setStepIdentityValues();
    },

    setStepPhoneNumber(validation) {
      this.phoneNr.isValid = validation.isValid;
      this.whistleblowerIdentity.phoneCountryCode = validation.countryCode;
      this.whistleblowerIdentity.phone = validation.e164;
      this.setStepIdentityValues();
    },
    setStepIdentityValues() {
      let isValidIdentity = false;
      if (isNotFalsy(this.phoneNr.text) && isNotFalsy(this.whistleblowerIdentity.email)) {
        isValidIdentity = this.phoneNr.isValid && isValidEmail(this.whistleblowerIdentity.email) && this.hasEnteredName;
      } else if (isNotFalsy(this.whistleblowerIdentity.phone)) {
        isValidIdentity = this.phoneNr.isValid && this.hasEnteredName;
      } else if (isNotFalsy(this.whistleblowerIdentity.email)) {
        isValidIdentity = this.hasEnteredName && isValidEmail(this.whistleblowerIdentity.email);
      } else {
        isValidIdentity = this.hasEnteredName;
      }

      this.$emit('setValidIdentity', isValidIdentity);
      this.$root.$emit('AuthWhistleblowerIdentity', this.whistleblowerIdentity);
    },
  }
};
</script>

<style scoped>
</style>
