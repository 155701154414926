export const tooltips = {
    whyIsInfoNeeded: {
        title: 'Varför är denna information nödvändig?',
        tooltipInfo: 'Information',
    },
    subsidiary: {
        text: 'Vi behöver veta vilket företag i koncernen din anmälan avser, för att utreda ärendet på bästa möjliga sätt.',
    },
    whistleblowerRelation: {
        text: 'Visselblåsarsystemet kan endast användas av den grupp personer som anges i visselblåsarpolicyn. Det är därför nödvändigt att få information om du omfattas av denna grupp av personer. ',
    },
    describeProblem: {
        text: 'För att vi ska kunna göra en grundlig utredning av din rapport är det viktigt att vi har så mycket och så detaljerad information som möjligt. Du bör därför beskriva vad du rapporterar, när det hände, var det hände, vem som är inblandad, om det finns någon som kan bevittna händelsen etc. Du bör också beskriva vilken relation du har till de inblandade personerna - till exempel att du är anställd, nära kollega eller liknande.',
    },
    reportRelated: {
        text: 'Våra undersökningar av din rapportering kan utföras på ett mer precist och ändamålsenligt sätt om vi känner till identiteten på den eller de personer som rapporten avser. Om du inte känner till identiteten, men till exempel vet att din rapport avser en specifik avdelning, har också denna kunskap stort värde för oss när vi planerar våra utredningar.',
    },
    documentUpload: {
        text: 'Om du har material, som stödjer din rapport, har detta ett stort värde för oss när vi ska undersöka din rapport. Det kan röra sig om foton, kopior av dokument etc.',
    },
    identity: {
        text: 'Våra möjligheter att undersöka din rapport är i allmänhet bäst om vi känner till din identitet. Vi hoppas därför att du vill informera oss om din identitet, även om det är frivilligt. Vi kommer att undersöka din rapport oavsett om du har avslöjat din identitet eller inte.  ',
    }
};
