export const oralReportPage = {
    of: ' of ',
    title: 'Report your suspicion or knowledge by phone - read more about how we ',
    link: 'protect your identity',
    stepProtectionOfficer: {
        title: 'Who should receive your report?',
        subtitle: ' - Click on the person or unit you want to receive your report.',
        oneProtectionOfficerTitle: 'Who should receive your report?',
        oneProtectionOfficerSubtitle: ' - Your report will  be sent to the following person',
    },
    stepWhistleblowerRelation: {
        title: 'What is your relationship to the organisation?',
        subtitle: ' - Describe your relationship to the organisation to whom your report relates. For example, that you are employed.',
        placeholder: 'Enter relation...*',
    },
    stepSubsidiaryCompany: {
        title: 'Which company does the report relate to?',
        subtitle: ' - Please choose which company in the group your report relates to.',
    },
    stepIdentity: {
        title: 'Do you want to reveal your identity?',
        subtitle: ' - It is optional if you want to reveal your identity. Please select your choice below.',
    },
    stepFollowReport: {
        title: 'Select password to log in to see your report and to follow the case',
        subtitle: ' - You need to select a password, to login and comment, verify or approve your report.',
        sendReportButton: 'Get telephone number',
    },
};
