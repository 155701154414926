<template>
  <v-card
      id="stepFromProtectionOfficers"
      class="mx-auto card-border-bottom"
      min-height="130"
      max-width="100%"
      rounded
      elevation="2"
  >
    <v-card
        v-if="showTopBarHeader"
        elevation="0"
        min-height="70"
        color="primary"
        class="rounded-b-0 pt-2 pb-2 d-flex align-center card-inner-padding"
    >
      <div class="pa-2 pl-0">
        <span class="white--text card-header-title">{{ headerText }}</span>
        <span class="white--text card-header-title text-decoration-underline c-point"
              @click="openInNewTab"
        >{{ headerLink }}</span>
      </div>
    </v-card>
    <v-card elevation="0" class="card-inner-padding ">
      <div class="mb-0">
        <slot name="card-title"/>
      </div>
      <div class="pt-0" style="overflow-x:auto; white-space: nowrap;">
        <v-card
            v-for="(officer, index) in getAllProtectionOfficers"
            :key="index"
            outlined
            elevation="0"
            class="mt-6 border-all-1 protection-officer-company-card"
            :class="{
              'selected-option': index === selectedProtectionOfficer.index,
              'border-all-2': index === selectedProtectionOfficer.index,
              'border-all-error-1': displayFromValidationError,
            }"
            @click="setSelectedChiefOfficer(officer, index)"
        >
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex justify-start align-center">
              <img
                  class="chief-company-logo"
                  width="94"
                  height="33"
                  :src="officer.url"
                  alt="Logo"
              >
              <div class="pl-4">
                <v-card-subtitle class="pa-0 ma-0 word-brake">
                  {{ $t('step.protectionOfficer.' + officer.customerRelation) }}
                </v-card-subtitle>
                <v-card-title class="pa-0 ma-0 word-brake chief-name">
                  {{ officer.name }}, {{ officer.jobTitle }}, {{ officer.company }}
                </v-card-title>
              </div>
            </div>
            <div v-if="index === selectedProtectionOfficer.index">
              <v-icon x-large color="icons">mdi-check</v-icon>
            </div>
          </div>
        </v-card>
      </div>
      <div v-if="displayFromValidationError" class="error--text card-inner-padding pt-0 pb-0 pr-0">
        {{ $t('misc.fieldIsRequired') }}
      </div>
    </v-card>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'StepChooseProtectionOfficer',
  props: {
    protectionOfficer: {
      type: Object,
      default: null,
    },
    showTopBarHeader: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default: '',
    },
    headerLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedProtectionOfficer: {
        index: -1,
        officer: {},
      },
    };
  },
  computed: {
    ...mapGetters('protectionOfficer', ['getAllProtectionOfficers']),
    ...mapGetters('onlineForm', ['getShowFormValidationErrorDialog', 'getFormValidation']),

    displayFromValidationError() {
      if (this.getShowFormValidationErrorDialog) {
        return !this.getFormValidation.stepFromProtectionOfficers.isValid;
      }
      return false;
    },
  },
  watch: {
    'getAllProtectionOfficers': {
      handler() {
        if (this.getAllProtectionOfficers.length === 1) {
          this.setSelectedChiefOfficer(this.getAllProtectionOfficers[0], 0);
        }
      },
      deep: true
    }
  },
  mounted() {
    this.initProtectionOfficer();
    this.retrieveCompanyInfo();
  },
  methods: {
    ...mapActions('protectionOfficer', ['retrieveCompanyInfo']),
    ...mapMutations('onlineForm', ['setValidFromStep']),
    initProtectionOfficer() {
      if (this.protectionOfficer != null) {
        this.selectedProtectionOfficer.index = this.getAllProtectionOfficers
            .findIndex(el =>
                el.name === this.protectionOfficer.name &&
                el.phone === this.protectionOfficer.phone
            );
      }
    },
    setSelectedChiefOfficer(protectionOfficer, index) {
      this.selectedProtectionOfficer.index = index;
      this.selectedProtectionOfficer.officer = protectionOfficer;
      this.setValidFromStep({validStep: 'stepFromProtectionOfficers', isValid: true});
      this.$root.$emit('SelectedChiefOfficer', protectionOfficer);
    },
    openInNewTab() {
      let routeData = this.$router.resolve({name: 'PersonalData'});
      window.open(routeData.href, '_blank');
    }
  }
};
</script>
