<template>
  <v-app>
    <v-overlay :value="getIsLoading">
      <v-progress-circular
          indeterminate
          size="64"
      />
    </v-overlay>

    <router-view/>

    <h1 class="d-none">Version: {{ getPackageVersion }}</h1>
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import applicationVersionStorage from '@/provider/ApplicationVersionStorage';
import LanguageStorageProvider from '@/provider/LanguageStorageProvider';

export default {
  computed: {
    ...mapGetters('event', ['getIsLoading']),
    ...mapGetters('lookAndFeel', ['getSiteFrontPage', 'getSiteColors']),
    ...mapGetters('protectionOfficer', ['getCompanyInfo']),
    isTabletOrMobile() {
      return this.$vuetify.breakpoint.width < 1111;
    },
    getPackageVersion() {
      return applicationVersionStorage.getAppVersion();
    }
  },
  watch: {
    'getSiteFrontPage': {
      handler() {
        this.$vuetify.theme.setTheme('light', this.updateColorThemeWithColors(this.getSiteColors));
      },
      deep: true
    },
    'getCompanyInfo': {
      handler() {
        let languageCode = LanguageStorageProvider.getLanguage(this.getCompanyInfo);
        this.retrieveSiteLookAndFeelWithCode({languageCode});
      },
    },
  },
  created() {
    this.retrieveCompanyInfo();
  },
  mounted() {
    document.title = this.createTitleFromUrl();
  },
  methods: {
    ...mapActions('lookAndFeel', ['retrieveSiteLookAndFeelWithCode']),
    ...mapActions('protectionOfficer', ['retrieveCompanyInfo']),
    updateColorThemeWithColors(siteColors) {
      return {
        primary: siteColors.primaryColorCode,
        lightPrimary: '#E5F1FC',
        accent: '#82B1FF',
        gray: '#EBF0F4',
        error: '#A30404',
        icons: siteColors.infoColorCode,
        success: '#4CAF50',
        warning: '#FFC107',
        background: siteColors.secondaryColorCode,
      };
    },
    createTitleFromUrl: () => location.hostname.split('.').splice(1, 2).join('.'),
  }
};
</script>


