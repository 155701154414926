import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {da} from '@/language/da/da';
import {en} from '@/language/en/en';
import {se} from '@/language/se/se';
import {pl} from '@/language/pl/pl';
import {es} from '@/language/es/es';
import {de} from '@/language/de/de';
import {no} from '@/language/no/no';

Vue.use(VueI18n);

export const appLangCode = 'app_lang_code';

export default new VueI18n({
    locale: localStorage.getItem(appLangCode),
    fallbackLocale: localStorage.getItem(appLangCode),
    messages: {da, en, se, pl, es, de, no}
});
