<template>
  <v-dialog
      v-model="approveChangePasswordDialog"
      transition="dialog-top-transition"
      content-class="elevation-0 overflow-x-hidden"
      :retain-focus="false"
      :max-width="isMobile ? '80%' : '69%'"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
  >
    <v-card flat class="pb-10" height="100%">
      <div>
        <div class="report-received-wrapper">
          <v-card color="primary" height="65" elevation="0" class="rounded-b-0">
            <div class="d-flex justify-end pa-2 pb-0 align-center c-point" @click="closeDialog()"><v-icon color="white" size="20">mdi-close</v-icon></div>
            <div class="d-flex justify-center align-center" :class="{'mt-1': isMobile, 'mt-n2': !isMobile}">
              <span class="white--text font-weight-bold dialog-title">
                {{ $t('allPrivatePages.changePassword.dialog.title') }}
              </span>
            </div>
          </v-card>
        </div>
      </div>

      <div class="pa-4">
        <div class="d-flex justify-center mb-2">
          <div class="report-received text-center" style="width: 65%">
            <span class="primary--text font-weight-bold dialog-title">
              {{ $t('allPrivatePages.changePassword.dialog.whatShouldYouDo') }}
            </span>
          </div>
        </div>

        <div class="d-flex justify-center">
          <div class="d-flex ma-8 flex-wrap justify-space-around" style="width: 80%">
            <div class="pt-4 pb-4" style="max-width: 160px">
              <div class="d-flex justify-center">
                <v-icon color="icons" size="65">mdi-logout-variant</v-icon>
              </div>
              <div class="d-flex justify-center mt-2">
                <span class="text-center primary--text">{{ $t('allPrivatePages.changePassword.dialog.step1') }}</span>
              </div>
            </div>
            <v-divider v-if="!isMobile" class="primary mt-12"/>
            <div class="pt-4 pb-4" style="max-width: 180px">
              <div class="d-flex justify-center">
                <v-icon color="icons" size="65">mdi-lock-open-outline</v-icon>
              </div>
              <div class="d-flex justify-center mt-2">
                <span class="text-center primary--text">{{ $t('allPrivatePages.changePassword.dialog.step2') }}</span>
              </div>
            </div>
            <v-divider v-if="!isMobile" class="primary mt-12"/>
            <div class="pt-4 pb-4" style="max-width: 170px">
              <div class="d-flex justify-center">
                <v-icon color="icons" size="65">mdi-form-textbox-password</v-icon>
              </div>
              <div class="d-flex justify-center mt-2">
                <span class="text-center primary--text">
                  {{ $t('allPrivatePages.changePassword.dialog.step3') }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-center">
          <div class="follow-case-by-clicking">
            <span>{{ $t('allPrivatePages.changePassword.dialog.descText1') }}</span>
          </div>
        </div>

        <div class="d-flex justify-center mt-8 mb-8">
          <div class="follow-case-by-clicking">
            <span>{{ $t('allPrivatePages.changePassword.dialog.descText2') }}</span>
          </div>
        </div>
      </div>
    </v-card>
    <div class="d-flex justify-center align-center">
      <v-btn
          color="primary"
          height="51"
          elevation="0"
          width="260"
          class="close-button text-none mt-n6"
          @click="requestPasswordUpdate"
      >
        <span class="font-weight-bold font-size-16">{{ $t('allPrivatePages.changePassword.dialog.btnApprove') }}</span>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'ApproveChangePasswordDialog',
  data() {
    return {
      approveChangePasswordDialog: false
    };
  },
  computed: {
    isMobile: {
      get() {
        return this.$vuetify.breakpoint.width < 1111;
      }
    }
  },
  methods: {
    ...mapActions('authCase', ['requestPasswordUpdate']),
    closeDialog() {
      this.approveChangePasswordDialog = false;
    },
    openDialog() {
      this.approveChangePasswordDialog = true;
    },
  }
};
</script>

<style scoped>

</style>
