export const publicNavPages = {
    home: {title: 'Startseite',},
    howToReport: {title: 'So meldet man einen Hinweis',},
    aboutWhistleBlowing: {
        title: 'Hinweisgebersystem',
        subPage: {
            whistleblowerPolicy: 'Hinweisgeberrichtlinie',
            yourProtection: 'Hinweisgeberschutz',
            caseProcessing: 'Fallbearbeitung',
        },
    },
    faq: {title: 'FAQ',}
};
