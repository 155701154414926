<template>
  <div class="mt-12">
    <ApproveChangePasswordDialog ref="approvePasswordChange"/>
    <div>
      <v-card elevation="2">
        <v-card elevation="1" color="primary" class="rounded-b-0 card-inner-padding pt-4 pb-4">
          <v-card-title class="white--text pa-0">{{ $t('allPrivatePages.changePassword.title') }}</v-card-title>
        </v-card>
        <div class="card-inner-padding">
          <div class="mb-10">
            <v-card-title class="font-weight-regular pa-0" style="font-size: 18px">
              {{ $t('allPrivatePages.changePassword.description') }}
            </v-card-title>
          </div>
          <div class="d-flex justify-space-between align-center">
            <v-btn class="text-none" color="primary" width="14rem" @click="openPasswordChangeDialog">
              <span class="font-size-16">{{ $t('allPrivatePages.changePassword.changePassword') }}</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import ApproveChangePasswordDialog from '@/components/modal/authCase/ApproveChangePasswordDialog';

export default {
  name: 'UpdatePassword',
  components: {ApproveChangePasswordDialog},
  methods: {
    openPasswordChangeDialog() {
      this.$refs.approvePasswordChange.openDialog();
    },
  }
};
</script>

<style scoped>

</style>
