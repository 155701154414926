<template>
  <MainLayout>
    <div>
      <CannotCreateCase/>

      <NewCaseCreatedOral/>

      <FormValidationError/>
    </div>

    <StepSubsidiaryCompany
        v-if="hasSubsidiaries"
        :header-text="$t('oralReportPage.title')"
        :header-link="$t('oralReportPage.link')"
    >
      <div slot="card-title" class="pt-4">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('oralReportPage.stepSubsidiaryCompany.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-8">
          <span class="font-weight-bold">{{ stepSubStartingFrom }} {{ $t('oralReportPage.of') }} {{ maxStep }}</span>
          {{ $t('oralReportPage.stepSubsidiaryCompany.subtitle') }}
        </v-card-subtitle>
      </div>
    </StepSubsidiaryCompany>

    <StepChooseProtectionOfficer
        :show-top-bar-header="!hasSubsidiaries"
        :header-text="$t('oralReportPage.title')"
        :header-link="$t('oralReportPage.link')"
    >
      <div v-if="isOneProtectionOfficer" slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('oralReportPage.stepProtectionOfficer.oneProtectionOfficerTitle') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-2">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 1 }} {{ $t('oralReportPage.of') }} {{ maxStep }}
          </span>
          {{ $t('oralReportPage.stepProtectionOfficer.oneProtectionOfficerSubtitle') }}
        </v-card-subtitle>
      </div>
      <div v-else slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('oralReportPage.stepProtectionOfficer.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-2">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 1 }} {{ $t('oralReportPage.of') }} {{ maxStep }}
          </span>
          {{ $t('oralReportPage.stepProtectionOfficer.subtitle') }}
        </v-card-subtitle>
      </div>
    </StepChooseProtectionOfficer>

    <StepWhistleblowerRelation>
      <div slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('oralReportPage.stepWhistleblowerRelation.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-2">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 2 }} {{ $t('oralReportPage.of') }} {{ maxStep }}
          </span>
          {{ $t('oralReportPage.stepWhistleblowerRelation.subtitle') }}
        </v-card-subtitle>
      </div>
    </StepWhistleblowerRelation>

    <StepIdentity>
      <div slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('oralReportPage.stepIdentity.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-8">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 3 }} {{ $t('oralReportPage.of') }} {{ maxStep }}
          </span>
          {{ $t('oralReportPage.stepIdentity.subtitle') }}
        </v-card-subtitle>
      </div>
    </StepIdentity>

    <StepFollowReport :has-choose-follow-case-code="false" @sendCase="sendCase()">
      <div slot="card-title">
        <v-card-title class="word-brake pa-0 ma-0 primary--text">
          {{ $t('oralReportPage.stepFollowReport.title') }}
        </v-card-title>
        <v-card-subtitle class="word-brake ma-0 pa-0 mt-1 mb-8">
          <span class="font-weight-bold">
            {{ stepSubStartingFrom + 4 }} {{ $t('oralReportPage.of') }} {{ maxStep }}
          </span>
          {{ $t('oralReportPage.stepFollowReport.subtitle') }}
        </v-card-subtitle>
      </div>
      <div slot="action-button">
        <v-icon large>mdi-phone-in-talk-outline</v-icon>
        <span class="pl-2">{{ $t('oralReportPage.stepFollowReport.sendReportButton') }}</span>
      </div>
    </StepFollowReport>
  </MainLayout>
</template>

<script>
import NewCaseCreatedOral from '@/components/modal/NewCaseCreatedOral';
import MainLayout from '@/components/layout/MainLayout';
import CannotCreateCase from '@/components/modal/CannotCreateCase';
import StepChooseProtectionOfficer from '@/components/card/stepForm/StepChooseProtectionOfficer';
import StepFollowReport from '@/components/card/stepForm/StepFollowReport';
import StepIdentity from '@/components/card/stepForm/StepIdentity';
import FormValidationError from '@/components/modal/FormValidationError';
import StepSubsidiaryCompany from '@/components/card/stepForm/StepSubsidiaryCompany';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {caseCreating} from '@/model/casePayload';
import {scrollTopId} from '@/utils/scrollUtils';
import StepWhistleblowerRelation from '@/components/card/stepForm/StepWhistleblowerRelation';

export default {
  name: 'OralReport',
  components: {
    StepWhistleblowerRelation,
    NewCaseCreatedOral,
    StepSubsidiaryCompany,
    FormValidationError,
    StepIdentity,
    StepFollowReport,
    MainLayout,
    CannotCreateCase,
    StepChooseProtectionOfficer,
  },
  computed: {
    ...mapGetters('onlineForm', ['getShowFormValidationErrorDialog', 'getFormValidationIdToNavigate']),
    ...mapGetters('protectionOfficer', ['getAllProtectionOfficers', 'getCompanyInfo', 'hasSubsidiaries']),
    isOneProtectionOfficer() {
      return this.getAllProtectionOfficers.length === 1;
    },
  },
  data() {
    return {
      maxStep: 4,
      stepSubStartingFrom: 0,
    };
  },
  watch: {
    'getCompanyInfo': {
      handler() {
        this.initNumberOfSteps();
      },
      deep: true
    }
  },

  beforeDestroy() {
    // this cleans up all the events before destroy,
    this.$root.$off();
  },

  beforeRouteLeave(to, from, next) {
    this.resetFromValidation();
    this.resetFormDialogData();
    this.resetCasePayLoadState();
    next();
  },
  mounted() {
    this.listenForOralFormChanges();
    this.initNumberOfSteps();
    this.setCaseCreatedFrom(caseCreating.PHONE);
  },
  methods: {
    ...mapMutations('onlineForm', [
      'setWhistleblowerRelation',
      'setWhistleblowerIdentity',
      'setWhistleblowerAccountFollow',
      'setWhistleblowerAccountPassword',
      'setSelectedChiefOfficer',
      'setCaseCreatedFrom',
      'setSubsidiaryCompany',
    ]),
    ...mapActions('onlineForm', ['uploadOralFormCase', 'validateOtherForms']),
    ...mapMutations('onlineForm', ['resetFromValidation', 'resetFormDialogData', 'resetCasePayLoadState']),
    listenForOralFormChanges() {
      this.$root.$on('WhistleblowerRelation', this.setWhistleblowerRelation);
      this.$root.$on('WhistleblowerIdentity', this.setWhistleblowerIdentity);
      this.$root.$on('SelectedChiefOfficer', this.setSelectedChiefOfficer);
      this.$root.$on('StepSubsidiaryCompany', this.setSubsidiaryCompany);
      this.$root.$on('WhistleblowerAccountFollow', data => {
        this.setWhistleblowerAccountFollow(data.follow);
        this.setWhistleblowerAccountPassword(data.password);
      });
    },
    sendCase() {
      this.validateOtherForms();
      if (this.getShowFormValidationErrorDialog) {
        this.$nextTick(() => scrollTopId(this.getFormValidationIdToNavigate));
      } else {
        this.uploadOralFormCase();
      }
    },
    initNumberOfSteps() {
      this.maxStep = this.hasSubsidiaries ? 5 : 4;
      this.stepSubStartingFrom = this.hasSubsidiaries ? 1 : 0;
    }
  }
};
</script>

<style scoped>

</style>
