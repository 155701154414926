<template>
  <v-card class="mt-10">
    <div class="pa-8">
      <div class="text-h6 primary--text">
        {{ $t('identity.yourIdentity.revealIdentity') }}
      </div>
      <div class="custom-divider"/>
      <v-card elevation="0">
        <v-card v-if="isIdentityReveal" class="border-all-2 mt-2" elevation="0">
          <v-card-title class="primary--text"> {{ getMyIdentity }}</v-card-title>
        </v-card>

        <div class="d-flex justify-start">
          <v-icon :color="isIdentityReveal ? 'icons' : 'error'">
            {{ isIdentityReveal ? 'mdi-check-bold' : 'mdi-close' }}
          </v-icon>
          <v-card-title class="pl-5 primary--text">
            {{
              isIdentityReveal
                ? $t('identity.yourIdentity.yesRevealIdentity')
                : $t('identity.yourIdentity.noDontRevealIdentity')
            }}
          </v-card-title>
        </div>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex';
import {isFalsy, isNotFalsy} from '@/utils/guards';

export default {
  name: 'YourIIdentityInfoCard',
  computed: {
    ...mapGetters('authCase', ['getCasePayload']),
    isIdentityReveal: {
      get() {
        let whistleblower = this.getCasePayload?.whistleblower;
        return !isFalsy(whistleblower);
      }
    },
    getMyIdentity: {
      get() {
        if (this.isIdentityReveal) {
          let {name, email, phone} = this.getCasePayload?.whistleblower;
          return name && name + this.addComaForNotNullValues(email) + this.addComaForNotNullValues(phone);
        }
        return null;
      }
    }
  },
  methods: {
    addComaForNotNullValues(prop) {
      return isNotFalsy(prop) ? ', ' + prop : '';
    }
  }
};
</script>

<style scoped></style>
