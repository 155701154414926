export const bookMeetingPage = {
    of: ' z ',
    title: 'Umów się na spotkanie, aby zgłosić swoje podejrzenia lub wiedzę - przeczytaj więcej o tym, jak ',
    link: 'chronimy Twoją tożsamość',
    stepSubsidiaryCompany: {
        title: 'Kogo dotyczy zgłoszenie?',
        subtitle: ' - Wskaż, której firmy w grupie dotyczy Twój raport.',
    },
    stepProtectionOfficer: {
        title: 'Kto powinien otrzymać raport na spotkaniu fizycznym?',
        subtitle: ' - Kliknij na osobę lub jednostkę, która ma otrzymać Twoje zgłoszenie',
    },
    stepIdentity: {
        title: 'Czy chcesz ujawnić swoją tożsamość?',
        subtitle: ' - Ujawnienie tożsamości jest dobrowolne. Wybierz poniżej.',
    },
    stepWhistleblowerRelation: {
        title: 'Jakie jest Twoje powiązanie z organizacją?',
        subtitle: ' - Opisz jakie masz powiązanie z organizacją, której Twoje zgłoszenie dotyczy. Np. że jesteś' +
            ' zatrudniony.',
        placeholder: 'Podaj powiązanie...*',
    },
    stepFollowReport: {
        title: 'Wybierz kod do zalogowania się na Twoje zgłoszenie',
        subtitle: '- Musisz utworzyć kod, abyś mógł się zalogować i śledzić Twoją rezerwację spotkania.',
        sendFormButton: 'Uzyskaj numer telefonu',
    },
};
