export const formValidation = {
    showError: false,
    showDialogError: false,
    idToNavigate: '',
    stepFromProtectionOfficers: {
        isValid: false,
        id: 'stepFromProtectionOfficers',
    },
    stepWhistleblowerRelation: {
      isValid: false,
      id: 'stepWhistleblowerRelation',
    },
    stepFromDescribeProblem: {
        isValid: false,
        id: 'stepFromDescribeProblem',
    },
    stepFromReportRelated: {
        isValid: false,
        id: 'stepFromReportRelated',
    },
    stepFromIdentity: {
        isValid: false,
        id: 'stepFromIdentity',
    },
    stepSubsidiaryCompany: {
        isValid: true,
        id: 'stepSubsidiaryCompany',
    },
};
