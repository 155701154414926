export const rules = {
    input: {
        fieldIsRequired: 'Obligatoriskt fält',
        validPhoneNumber: '* ange giltigt telefonnummer i formatet +46xxxxxxxxx',
        validPhoneNumberOrEmail: 'Ange e-post eller telefonnummer med formatet +46xxxxxxxxx',
        email: 'E-postadressen måste vara giltig',
        min4Char: '* Ditt lösenord måste innehålla minst 4 siffror',
        contentShouldNotBeLonger: 'Beskrivningen får omfatta maximalt 200 bokstäver.',
    }
};
