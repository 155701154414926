<template>
  <div class="mt-15">
    <DataWillBeDiscarded
        :discard-users-dialog="discardChangesDialog"
        @cancelUserDiscard="cancelUserDiscard()"
        @confirmUserDiscard="confirmUserDiscard()"
    />

    <v-card
        id="stepFromFollowReport"
        class="mx-auto card-border-bottom card-inner-padding"
        min-height="310"
        max-width="100%"
        rounded
        elevation="2"
    >
      <div>
        <div>
          <slot name="card-title"/>
        </div>

        <v-row v-if="hasChooseFollowCaseCode" class="d-flex justify-space-between mt-5">
          <v-col cols="12" lg="6" xl="6" md="12" sm="12" class="d-flex">
            <v-card
                outlined
                width="100%"
                min-height="126px"
                class="d-flex align-stretch flex-1"
                :class=" {'border-all-2 selected-option': (hasWhistleblowerChooseAnOption && caseAccount.follow)}"
                elevation="0"
                @click="setFollowCase(true)"
            >
              <div class="ma-4">
                <div class="d-flex justify-center align-center">
                  <div class="pa-1">
                    <v-icon size="36" class="icon-select-option" color="icons">mdi-magnify</v-icon>
                  </div>
                  <div class="text-select-option-title word-brake">
                    {{ $t('step.followReport.yesFollowReport') }}
                  </div>
                </div>
                <div class="pa-2 text-select-option-subtitle word-brake">
                  {{ $t('step.followReport.yesFollowTheCaseWithPassword') }}
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" lg="6" xl="6" md="12" sm="12" class="d-flex">
            <v-card
                outlined
                width="100%"
                min-height="126px"
                :disabled="hasUserSelectedNotification"
                class="d-flex align-stretch flex-1"
                :class=" {'border-all-2 selected-option': (hasWhistleblowerChooseAnOption && !caseAccount.follow)}"
                elevation="0"
                @click="setFollowCase(false)"
            >
              <div class="ma-4">
                <div class="d-flex justify-center align-center">
                  <div class="pa-1">
                    <v-icon size="36" class="icon-select-option" color="icons">mdi-window-close</v-icon>
                  </div>
                  <div class="text-select-option-title word-brake">
                    {{ $t('step.followReport.noDontFollowReport') }}
                  </div>
                </div>
                <div class="pa-2 text-select-option-subtitle word-brake">
                  {{ $t('step.followReport.noDontWantToHearFromCase') }}
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>


        <div v-if="hasWhistleblowerChooseAnOption" class="mt-6">
          <v-row v-if="caseAccount.follow" class="mt-0" justify="space-between">
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <v-text-field
                  v-model="caseAccount.password"
                  :rules="rules.passwordRules"
                  :placeholder="$t('step.followReport.enterPassword')"
                  outlined
                  type="password"
                  @update:error="hasErrorHappen"
                  @input="setStepFollowCase()"
              />
            </v-col>
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <v-text-field
                  v-model="caseAccount.confirmPassword"
                  :placeholder="$t('step.followReport.confirmPassword')"
                  outlined
                  :disabled="!caseAccount.isPasswordCorrect"
                  :error="caseAccount.passwordAndConfirmPasswordAreNotTheSame"
                  :error-messages="caseAccount.passwordAndConfirmPasswordAreNotTheSame ? $t('step.followReport.mustConfirmPassword') : null"
                  type="password"
                  @input="writeConfirmPassword()"
              />
            </v-col>
          </v-row>

          <v-row class="d-flex justify-space-between mt-2">
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <v-card
                  outlined
                  elevation="0"
                  width="100%"
                  min-height="55"
                  height="100%"
                  class="border-all-2 d-flex align-center pb-01"
                  :class="{'selected-option': agreeTheTermsAndConditions}"
              >
                <v-radio-group
                    v-model="agreeTheTermsAndConditions"
                    class="mt-2 ml-3 mb-2 pb-0"
                    hide-details
                    @change="toggleTermsAndConditions()"
                >
                  <div class="d-flex">
                    <v-radio
                        color="primary"
                        :value="true"
                        @click="toggleTermsAndConditions()"
                    />
                    <div class="mr-1">
                      <span>{{ $t('step.followReport.iAcceptTheTerms') }}</span>
                      <span class="text-decoration-underline c-point pl-1"
                            @click="openInNewTab"
                      >
                        {{ $t('step.followReport.iAcceptTheTerms1') }}
                      </span>
                    </div>
                  </div>
                </v-radio-group>
              </v-card>
            </v-col>
            <v-col cols="12" lg="6" xl="6" md="12" sm="12">
              <v-btn
                  color="primary"
                  class="text-none d-flex justify-start"
                  width="100%"
                  height="55px"
                  :disabled="shouldEnableSendCaseButton()"
                  @click="$emit('sendCase')"
              >
                <div class="mt-1 d-flex justify-center align-center w-100-p">
                  <slot name="action-button"/>
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <v-card v-if="caseAccount.follow" min-height="50" color="warningCustom"
                class="d-flex justify-start align-center mt-10" elevation="0"
        >
          <div class="pa-3">
            <span class="primary--text font-weight-bold">{{ $t('step.followReport.logInIn10Days') }}</span>
            <span class="primary--text font-weight-regular">{{ $t('step.followReport.logInIn10Days1') }}</span>
          </div>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
import {passwordRules} from '@/utils/rules/inputRules';

import {isNotFalsy} from '@/utils/guards';
import DataWillBeDiscarded from '@/components/modal/DataWillBeDiscarded';
import {mapGetters} from 'vuex';

export default {
  name: 'StepFollowReport',
  components: {DataWillBeDiscarded},
  props: {
    hasChooseFollowCaseCode: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rules: {passwordRules},

      discardChangesDialog: false,
      caseAccount: {
        follow: null,
        password: null,
        showPassword: true,
        showConfirmPassword: true,
        confirmPassword: '',
        isPasswordCorrect: false,
        passwordAndConfirmPasswordAreNotTheSame: false,
      },
      agreeTheTermsAndConditions: false,
    };
  },

  computed: {
    ...mapGetters('onlineForm', ['getWhistleBlowerIdentity']),
    hasWhistleblowerChooseAnOption() {
      if (!this.hasChooseFollowCaseCode) {
        return true;
      } else {
        return this.caseAccount.follow !== null && this.hasChooseFollowCaseCode;
      }
    },

    hasUserSelectedNotification() {
      return this.getWhistleBlowerIdentity?.notification ?? false;
    }
  },

  mounted() {
    if (!this.hasChooseFollowCaseCode) {
      this.caseAccount.follow = true;
    }
  },

  watch: {
    getWhistleBlowerIdentity: {
      handler() {
        if (this.hasUserSelectedNotification) {
          this.caseAccount.follow = true;
        }
      },
      deep: true
    }
  },

  methods: {
    toggleTermsAndConditions() {
      this.agreeTheTermsAndConditions = !this.agreeTheTermsAndConditions;
      this.setStepFollowCase();
    },

    setFollowCase(value) {
      this.caseAccount.follow = value;
      this.setStepFollowCase();
      if (!this.caseAccount.follow) {
        this.checkFromEnteredInformation();
      }
    },

    checkFromEnteredInformation() {
      this.discardChangesDialog = isNotFalsy(this.caseAccount.password);
    },

    resetWhistleBlowerAccount() {
      this.caseAccount = {
        follow: null,
        password: null,
        confirmPassword: '',
        isPasswordCorrect: false,
        passwordAndConfirmPasswordAreNotTheSame: false,
      };
    },
    setStepFollowCase() {
      this.$root.$emit('WhistleblowerAccountFollow', this.caseAccount);
    },
    isConfirmPasswordCorrect() {
      return this.caseAccount.confirmPassword === this.caseAccount.password;
    },
    shouldEnableSendCaseButton() {
      return this.hasUserAgreedAndEnteredPassword();
    },
    hasUserAgreedAndEnteredPassword() {
      if (this.caseAccount.follow) {
        return !(this.agreeTheTermsAndConditions && isNotFalsy(this.caseAccount.password) && this.isConfirmPasswordCorrect());
      } else {
        return !this.agreeTheTermsAndConditions;
      }
    },
    hasErrorHappen(hasError) {
      this.caseAccount.isPasswordCorrect = !hasError;
    },
    writeConfirmPassword() {
      this.caseAccount.passwordAndConfirmPasswordAreNotTheSame = !this.isConfirmPasswordCorrect();
    },
    cancelUserDiscard() {
      this.setFollowCase(true);
      this.discardChangesDialog = false;
    },
    confirmUserDiscard() {
      this.resetWhistleBlowerAccount();
      this.discardChangesDialog = false;
    },
    openInNewTab() {
      let routeData = this.$router.resolve({name: 'PersonalData'});
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style scoped>
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}

.pb-01 {
  padding-bottom: 1px !important;
}
</style>
