export const step = {
    describeProblem: {
        describe: 'Opis*',
        choose: 'Wybierz... *',
        rulesViolated: 'Jakie przepisy zostały Twoim zdaniem przekroczone?',
        isProblemRepeated: 'Czy wydarzyło się to wielokrotnie?',
        howOftenIsRepeated: 'Podaj w opisie jak często to się wydarzyło',
        yes: 'Tak',
        no: 'Nie',
    },
    protectionOfficer: {
        'Ekstern': 'Firma zewnętrzna',
        'Intern': 'Wewnętrzny',
    },
    documentUpload: {
        max: 'Maksymalna wielkość plików',
        perFile: '',
    },

    followReport: {
        yesFollowReport: 'Tak, chcę śledzić tę sprawę',
        yesFollowTheCaseWithPassword: 'Będziesz miał możliwość podania dodatkowych informacji oraz otrzymywać' +
            ' odpowiedzi za pomocą numeru sprawy i wybranego przez Ciebie kodu.',
        noDontFollowReport: 'Nie, nie chcę śledzić tej sprawy',
        noDontWantToHearFromCase: 'Po przesłaniu zgłoszenia nie dostaniesz odpowiedzi ani informacji o sprawie.',
        enterPassword: 'Podaj wybrany przez siebie kod*',
        confirmPassword: 'Powtórz kod*',
        mustConfirmPassword: 'Du skal bekræfte adgangskoden', //TODO
        iAcceptTheTerms: 'Znam politykę systemu informowania o nieprawidłowościach',
        iAcceptTheTerms1: '',
        logInIn10Days: 'Uwaga: ',
        logInIn10Days1: ' Ze względów bezpieczeństwa musisz się zalogować w ciągu 10 dni, w przeciwnym wypadku Twój' +
            ' profil zostanie usunięty.',
    },

    howYouWantToMeet: {
        phone: 'Telefonisk',
        appointment: 'Fortael hvilke tidspunkter der passer dig et fysisk møde *',
    },

    identity: {
        yesRevealIdentity: 'Tak, chcę ujawnić moją tożsamość',
        enterIdentity: 'Twoja tożsamość jest traktowana poufnie i udostępniana tylko tym, którzy mają ' +
            'potrzebę ją poznać.',
        noDontRevealIdentity: 'Nie, nie chcę ujawniać mojej tożsamości',
        stayAnonym: 'Z zasady jesteś anonimowy, ale Twoje zgłoszenie może ujawnić Twoją tożsamość. ' +
            'W takim przypadku traktujemy ją poufnie',
        firstName: 'Imię i nazwisko*',
        email: 'Adres e-mail*',
        phoneNr: 'Numer komórki',
        examplePhoneNr: 'Numer komórki',
        wantToReceive: 'Chcę otrzymać e-mail o nowym statusie sprawy',
        youNeedToSelectAnOption: '*pole wymagane',
    },

    makeQuestions: {
        title: 'Zadaj pytanie o użyciu systemu informowania o nieprawidłowościach – przeczytaj więcej na temat ',
        whistleblowing: ' systemu informowania o nieprawidłowościach',
        phone: 'Telefonisk',
        online: 'Online',
        callThisNumber: 'Telefonen er åben alle hverdage i tidsrummet 08.00-17.00.',
        defaultPhoneNumber: '+45 88 91 92 45',
        defaultContactPerson: 'Søren Wolder, Advokat, DAHL Advokatpartnerselskab',
        defaultContactInfo: 'Telefonen er åben alle hverdage i tidsrummet 08.00-17.00.',
    },

    reportRelated: {
        selectPersonsInCompany: 'Osoby znane z imienia i nazwiska',
        company: 'Osoby nieznane',
        youNeedToSelectAnOption: '*pole wymagane',
        firstName: 'Imię i nazwisko*',
        position: 'Zajmowane stanowisko',
        phoneNrOrEmail: 'Ewentualnie numer telefonu/e-mail danej osoby',
        addNewPerson: 'Dodaj nową osobę',
        unit:'Wydział'
    }
};
