<template>
  <div>
    <div>
      <NavigationBar v-if="isMobile"/>
      <AppBar v-else/>
    </div>
    <div style="margin-top: 83px!important">
      <transition name="fade" mode="out-in" appear>
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/bars/public/AppBar';
import NavigationBar from '@/components/bars/public/NavigationBar';
import {mapActions, mapMutations} from 'vuex';

export default {
  name: 'Home',
  components: {
    NavigationBar,
    AppBar,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 1111;
    }
  },
  watch: {
    '$route': {
      handler() {
        this.resetCasePayLoadState();
      }
    }
  },
  methods: {
    ...mapActions('protectionOfficer', ['retrieveCompanyInfo']),
    ...mapMutations('onlineForm', ['resetCasePayLoadState'])
  },
  mounted() {
    this.retrieveCompanyInfo();
  }
};
</script>

<style lang="scss"></style>
