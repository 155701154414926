import ConfigurationService from '@/services/ConfigurationService';
import {setDomain} from '@/clients/wbBack/wbBack.api';
import {axiosAuthenticationInterceptor, axiosAuthorizationInterceptor} from '@/interceptors/auth.interceptor';
import {registerKeyCloak} from '@/plugins/registerKeycloak';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import store from '@/store';
import router from '@/router';
import i18n from '@/i18n';
import WhistleBlowerClient from '@/WhistleBlowerClient';

ConfigurationService.getBackendUrlFromConfiguration().then(backendUrl => {
    setDomain(backendUrl);
    axiosAuthenticationInterceptor();
    axiosAuthorizationInterceptor();

    ConfigurationService.getIdFromConfiguration().then(initOptions => {
        let keycloakInstance = registerKeyCloak(initOptions);

        keycloakInstance.init({checkLoginIframe: false}).then(() => {
            new Vue({
                vuetify,
                store,
                router,
                i18n,
                render: h => h(WhistleBlowerClient)
            }).$mount('#app');
        }).catch(() => {
            new Vue({
                vuetify,
                store,
                router,
                i18n,
                render: h => h(WhistleBlowerClient)
            }).$mount('#app');
        });
    });
});




