import {casePayload} from '@/model/casePayload';
import {formValidation} from '@/model/formValidation';
import {isNotFalsy} from '@/utils/guards';
import caseService from '@/services/CaseService';
import router from '@/router';
import {observableToObject} from '@/utils/weirdities/observableToObject';

const state = {
    formDialogs: {
        successDialog: false,
        successDialogData: {},
        failureDialog: false,
    },
    casePayload,
    files: [],
    caseCreatingFrom: null,
    formValidation,
};

const getters = {
    getCaseAccountFollow: state => state.casePayload.caseAccount.follow,
    getCaseProtectionOfficer: state => state.casePayload.protectionOfficer,

    getWhistleblowerRelation: state => state.casePayload.whistleblowerRelation,

    getSuccessDialog: state => state.formDialogs.successDialog,
    getSuccessDialogData: state => state.formDialogs.successDialogData,
    getFailureDialog: state => state.formDialogs.failureDialog,

    getShowFormValidationErrorDialog: state => state.formValidation.showError,
    getValidationErrorDialog: state => state.formValidation.showDialogError,
    getFormValidationIdToNavigate: state => state.formValidation.idToNavigate,
    getFormValidation: state => state.formValidation,

    getWhistleBlowerIdentity: state => state.casePayload.whistleblower,
};

const actions = {
    async uploadCase({commit, state, dispatch}) {
        dispatch('event/startLoading', {}, {root: true});

        let newCase = await caseService.uploadOnlineFormCase(state.casePayload, state.files);
        dispatch('event/stopLoading', {}, {root: true});
        commit('resetFormDialogData');
        if (newCase != null) {
            commit('setSuccessDialogState', true);
            commit('setSuccessDialogData', newCase);
        } else {
            commit('failureDialog', true);
        }
    },

    async uploadBookMeetingFormCase({commit, state, dispatch}) {
        dispatch('event/startLoading', {}, {root: true});
        let newCase = await caseService.uploadBookMeetingFormCase(state.casePayload);

        dispatch('event/stopLoading', {}, {root: true});
        commit('resetFormDialogData');
        if (newCase != null) {
            commit('setSuccessDialogState', true);
            commit('setSuccessDialogData', newCase);
        } else {
            commit('failureDialog', true);
        }
    },

    async uploadOralFormCase({commit, state, dispatch}) {
        dispatch('event/startLoading', {}, {root: true});

        let newCase = await caseService.uploadOralFormCase(state.casePayload);

        dispatch('event/stopLoading', {}, {root: true});
        commit('resetFormDialogData');
        if (newCase != null) {
            commit('setSuccessDialogState', true);
            commit('setSuccessDialogData', newCase);
        } else {
            commit('failureDialog', true);
        }
    },

    async closeSuccessDialog({commit}) {
        commit('setSuccessDialogState', false);
        commit('resetCasePayLoadState');
        await router.push('/');
    },

    async closeFormValidationError({commit}) {
        commit('setFormDialogValidationError', false);
    },

    validateonlineForm({commit, state}) {
        let {
            stepSubsidiaryCompany,
            stepFromProtectionOfficers,
            stepWhistleblowerRelation,
            stepFromDescribeProblem,
            stepFromReportRelated,
            stepFromIdentity,
        } = state.formValidation;

        let isValid = stepFromProtectionOfficers.isValid
            && stepWhistleblowerRelation.isValid
            && stepFromDescribeProblem.isValid
            && stepFromReportRelated.isValid
            && stepSubsidiaryCompany.isValid
            && stepFromIdentity.isValid;
        commit('setFormValidationError', !isValid);

        let unfilledFromId = [
            stepSubsidiaryCompany,
            stepFromProtectionOfficers,
            stepWhistleblowerRelation,
            stepFromDescribeProblem,
            stepFromReportRelated,
            stepFromIdentity
        ].find(el => !el.isValid);


        let hasNotFilledAllForm = isNotFalsy(unfilledFromId?.id);
        if (hasNotFilledAllForm) {
            commit('setIdToNavigateTo', unfilledFromId.id);
        }
    },

    validateOtherForms({commit}) {
        let {
            stepFromProtectionOfficers,
            stepWhistleblowerRelation,
            stepFromIdentity,
            stepSubsidiaryCompany
        } = state.formValidation;

        let isValid = stepFromProtectionOfficers.isValid
            && stepWhistleblowerRelation.isValid
            && stepFromIdentity.isValid
            && stepSubsidiaryCompany.isValid;
        commit('setFormValidationError', !isValid);

        let unfilledFromId = [
            stepSubsidiaryCompany,
            stepFromProtectionOfficers,
            stepWhistleblowerRelation,
            stepFromIdentity
        ].find(el => !el.isValid);
        let hasNotFilledAllForm = isNotFalsy(unfilledFromId?.id);
        if (hasNotFilledAllForm) {
            commit('setIdToNavigateTo', unfilledFromId?.id);
        }
    },
};

const mutations = {
    resetCasePayLoadState(state) {
        state.casePayload = observableToObject(casePayload);
        state.formValidation = observableToObject(formValidation);
        state.files = [];
        state.caseCreatingFrom = null;
    },
    resetFormDialogData(state) {
        state.formDialogs = {
            successDialog: false,
            successDialogData: {},
            failureDialog: false,
        };
    },

    setFormValidationError(state, showError) {
        state.formValidation.showError = showError;
        state.formValidation.showDialogError = showError;
    },
    resetFromValidation: (state) => state.formValidation = formValidation,
    setCaseCreatedFrom: (state, createdFrom) => state.caseCreatingFrom = createdFrom,

    setProblemDescription(state, problemDescription) {
        state.casePayload = {
            ...state.casePayload,
            ...problemDescription,
        };
    },
    setWhistleblowerRelation: (state, relation) => state.casePayload.whistleblowerRelation = relation,
    setReportedPersons: (state, reportedPersons) => state.casePayload.reportedPersons = reportedPersons,
    setReportedUnit: (state, reportedUnit) => state.casePayload.reportedUnit = reportedUnit,
    setWhistleblowerAccountFollow: (state, follow) => state.casePayload.caseAccount.follow = follow,
    setWhistleblowerAccountPassword: (state, password) => state.casePayload.caseAccount.password = password,
    setWhistleblowerIdentity: (state, whistleblower) => state.casePayload.whistleblower = whistleblower,
    setSubsidiaryCompany: (state, subsidiary) => state.casePayload.subsidiary = subsidiary,
    setSelectedChiefOfficer: (state, protectionOfficer) => state.casePayload.protectionOfficer = protectionOfficer,
    setFiles: (state, files) => state.files = files,
    setSuccessDialogState: (state, value) => state.formDialogs.successDialog = value,
    setSuccessDialogData: (state, data) => state.formDialogs.successDialogData = data,
    failureDialog: (state, value) => state.formDialogs.failureDialog = value,

    setValidFromStep: (state, {validStep, isValid}) => state.formValidation[validStep].isValid = isValid,
    setFormDialogValidationError: (state, showError) => state.formValidation.showDialogError = showError,
    setIdToNavigateTo: (state, id) => state.formValidation.idToNavigate = id
};

export const onlineForm = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

