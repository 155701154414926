export const onlineFormPage = {
    of: ' de ',
    title: 'Denuncia tu sospecha a través del formulario en línea – Obtén más información de cómo ',
    link: ' protegemos tu identidad',
    stepSubsidiaryCompany: {
        title: '¿A qué sociedad se refiere la denuncia?',
        subtitle: ' - Indica la sociedad del Grupo a la que se refiere tu denuncia.',
        placeholder:'Elige la sociedad...*',
    },
    stepProtectionOfficer: {
        title: '¿A quién va dirigida la denuncia?',
        subtitle: ' - Haz clic en la persona o unidad que deseas que reciba tu denuncia.',
        oneProtectionOfficerTitle: '¿A quién va dirigida la denuncia?',
        oneProtectionOfficerSubtitle: ' - Haz clic en la persona o unidad que deseas que reciba tu denuncia.',
    },
    stepWhistleblowerRelation: {
        title: '¿Cuál es tu relación con la organización?',
        subtitle: 'Describe la relación que tienes con la organización a la que se refiere tu denuncia. Por ejemplo,' +
            ' si eres empleado.',
        placeholder: 'Indica la relación...*',
    },
    stepDescribeProblem: {
        title: '¿Qué deseas denunciar?',
        subtitle: ' - A continuación, deberás describir el incidente que deseas denunciar. Intenta proporcionar el' +
            ' mayor número posible de detalles.',
    },
    stepReportRelated: {
        title: '¿A quién concierne la denuncia?',
        subtitle: ' - Ahora deberás indicar a quién concierne la denuncia.',
    },
    stepDocumentUpload: {
        title: 'Aquí podrás añadir documentación relativa al incidente',
        subtitle: ' - Aquí podrás añadir archivos que documenten el incidente descrito.',
    },
    stepIdentity: {
        title: '¿Deseas dar a conocer tu identidad?',
        subtitle: ' - La revelación de tu identidad es voluntaria. Selecciona a continuación:',
    },
    stepFollowReport: {
        title: '¿Deseas mantenerte informado sobre tu denuncia?',
        subtitle: ' - Elige si deseas estar informado sobre el expediente y, en su caso, contribuir con información' +
            ' adicional. Podrás mantenerte informado sobre el expediente aunque tus datos sean anónimos.',
        sendReportButton: 'Enviar ',
    },

    save: 'Gem'
};
