export const step = {
    describeProblem: {
        describe: 'Beskrivelse*',
        choose: 'Vælg... *',
        rulesViolated: 'Hvilke regler formoder du overtrådt?',
        isProblemRepeated: 'Er hændelsen sket flere gange?',
        howOftenIsRepeated: 'Angiv i beskrivelsen hvor ofte hændelsen er sket',
        yes: 'Ja',
        no: 'Nej',
    },

    protectionOfficer: {
        'Ekstern': 'Ekstern',
        'Intern': 'Intern',
    },

    documentUpload: {
        max: 'Max',
        perFile: ' pr. fil',
    },

    followReport: {
        yesFollowReport: 'Ja, jeg vil gerne følge sagen',
        yesFollowTheCaseWithPassword: 'Du får mulighed for at bidrage med yderligere information samt modtage feedback via sagsnr. og selvvalgt kodeord',
        noDontFollowReport: 'Nej, jeg ønsker ikke at følge sagen',
        noDontWantToHearFromCase: 'Du vil ikke modtage feedback og informationer om sagen efter du har indsendt din indberetning',
        enterPassword: 'Indtast selvvalgt kodeord*',
        confirmPassword: 'Gentag kodeord*',
        mustConfirmPassword: 'Du skal bekræfte adgangskoden',
        iAcceptTheTerms: 'Jeg er bekendt med ',
        iAcceptTheTerms1: 'whistleblowerpolitikken',
        logInIn10Days: 'OBS: ',
        logInIn10Days1: 'Af sikkerhedsmæssige årsager skal du logge ind inden for 10 dage, inden din bruger ellers' +
            ' slettes',
    },

    howYouWantToMeet: {
        phone: 'Telefonisk',
        appointment: 'Fortæl hvilke tidspunkter der passer dig et fysisk møde *',
    },

    identity: {
        yesRevealIdentity: 'Ja, jeg vil gerne oplyse min identitet',
        enterIdentity: 'Din identitet behandles fortroligt og gøres kun bekendt for de personer, der har behov for at kende den.',
        noDontRevealIdentity: 'Nej, jeg vil ikke oplyse min identitet',
        stayAnonym: 'Du er som udgangspunkt anonym, men din indberetning kan afsløre din mulige identitet. I så fald behandler vi den fortroligt',
        firstName: 'For- og efternavn*',
        email: 'Emailadresse*',
        phoneNr: 'Mobilnummer',
        examplePhoneNr: 'Eksempel mobilnummer',
        wantToReceive: 'Jeg ønsker at modtage en e-mail ved ny status i sagen',
        youNeedToSelectAnOption: '*Field required. Select an option.',
    },

    makeQuestions: {
        title: 'Stil spørgsmål om brug af whistleblowerordning - læs mere om ',
        whistleblowing: 'whistleblowerordning',
        phone: 'Telefonisk',
        online: 'Online',
        callThisNumber: 'Telefonen er åben alle hverdage i tidsrummet 08.00-17.00.',
        defaultPhoneNumber: '+45 88 91 92 45',
        defaultContactPerson: 'Søren Wolder, Advokat, DAHL Advokatpartnerselskab',
        defaultContactInfo: 'Telefonen er åben alle hverdage i tidsrummet 08.00-17.00.',
    },

    reportRelated: {
        selectPersonsInCompany: 'Navngivne personer',
        company: 'Ikke navngivne personer',
        youNeedToSelectAnOption: '*Felt påkrævet. Du skal angive om sagen drejer sig om en person eller en afdeling.',
        firstName: 'For- og efternavn*',
        position: 'Stillingsbetegnelse',
        phoneNrOrEmail: 'Evt. telefonnummer/email på vedkommende',
        addNewPerson: 'Tilføj ny person',
        unit: 'Afdeling'
    },
};
