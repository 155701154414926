export const landingPage = {
    actionCards: {
        title: 'Hvordan ønsker du at indberette?',
        onlineForm: {
            title: 'Indberet online',
        },
        oralReport: {
            title: 'Indberet telefonisk',
        },
        bookMeeting: {
            title: 'Indberet på et møde',
        },
        askQuestions: {
            title: 'Stil et spørgsmål',
        },
    }
};
