import { render, staticRenderFns } from "./SomethingWentWrong.vue?vue&type=template&id=4b126ef0&scoped=true"
import script from "./SomethingWentWrong.vue?vue&type=script&lang=js"
export * from "./SomethingWentWrong.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b126ef0",
  null
  
)

export default component.exports