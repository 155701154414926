<template>
  <div id="suggest-changes">
    <v-dialog
        v-model="openApprove"
        content-class="elevation-0"
        transition="dialog-top-transition"
        max-width="816"
        @click:outside="closeApproveDialog()"
        @keydown.esc="closeApproveDialog()"
    >
      <v-card
          flat
          max-width="816"

          height="100%"
      >
        <div class="d-inline">
          <v-card elevation="0" color="primary" height="73" class="rounded-b-0">
            <div class="pa-4">
              <div class="d-flex justify-end" style="height: 18px">
                <v-icon class="c-point" color="white" @click="closeApproveDialog()">mdi-close</v-icon>
              </div>
              <div class="d-flex justify-center align-start mt-n2">
                <span class="white--text font-size-20">
                  {{ $t('allPrivatePages.seeReport.approveDialog.title') }}
                </span>
              </div>
            </div>
          </v-card>
        </div>
        <div class="d-flex justify-space-between flex-column" style="height: 150px">
          <div class="mt-4">
            <span class="font-size-16 pa-4 font-weight-medium primary--text">{{ $t('allPrivatePages.seeReport.approveDialog.description') }}</span>
          </div>

          <div class="d-flex justify-end pa-6">
            <div>
              <v-btn
                  outlined
                  color="primary"
                  elevation="0"
                  class="text-none mr-4"
                  @click="closeApproveDialog"
              >
                <span class="font-size-14">{{ $t('allPrivatePages.seeReport.approveDialog.cancel') }}</span>
              </v-btn>

              <v-btn
                  color="primary"
                  elevation="0"
                  class="text-none ml-4"
                  @click="approveAndClose"
              >
                <span class="font-size-14">{{ $t('allPrivatePages.seeReport.approveDialog.confirm') }}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'ApproveCaseDialog',
  data() {
    return {
      openApprove: false,
    };
  },
  methods: {
    ...mapActions('authCase', ['approveChanges']),
    closeApproveDialog() {
      this.openApprove = false;
    },

    openApproveDialog() {
      this.openApprove = true;
    },

    async approveAndClose() {
      await this.approveChanges();
      this.closeApproveDialog();
    }
  }
};
</script>

<style scoped>


</style>
