<template>
  <v-select
      :value="getLanguageCode"
      class="language-select ma-0 pa-0"
      :items="[
          {'languageCode': 'da', 'languageName': 'Dansk'},
         {'languageCode': 'no', 'languageName': 'Norsk'},
      {'languageCode': 'se', 'languageName': 'Svenska'},
      {'languageCode': 'en', 'languageName': 'English'},]"
      item-value="languageCode"
      item-text="languageName"
      :placeholder="$t('misc.language')"
      hide-details
      outlined
      dense
      @change="changeLookAndFeel"
  />
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import LanguageStorageProvider from '@/provider/LanguageStorageProvider';

export default {
  name: 'LanguageSelectForPrivatePages',
  computed: {
    ...mapGetters('lookAndFeel', ['getSupportedLanguages', 'getLanguageCode'])
  },
  methods: {
    ...mapActions('lookAndFeel', ['retrieveSiteLookAndFeelWithCode']),
    changeLookAndFeel(languageCode) {
      LanguageStorageProvider.setLanguage(languageCode);
      this.retrieveSiteLookAndFeelWithCode({languageCode});
    }
  }
};
</script>

<style scoped>
</style>
