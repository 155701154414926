import AuthenticatedWhistleBlower from '@/views/private/AuthenticatedWhistleBlower';
import CaseStatus from '@/views/private/subpages/CaseStatus';
import Messages from '@/views/private/subpages/Messages';
import SeeReport from '@/views/private/subpages/SeeReport';
import YourInfo from '@/views/private/subpages/YourInfo';
import {validatePrivateRoute} from '@/router/routerGuard/auth.guard';

export default [
    {
        path: '/private',
        name: 'AuthenticatedWhistleBlower',
        component: AuthenticatedWhistleBlower,
        meta: {
            authenticated: true
        },
        children: [
            {
                path: 'status',
                name: 'CaseStatus',
                component: CaseStatus,
                meta: {
                    authenticated: true
                },
                beforeEnter: validatePrivateRoute,
            },
            {
                path: 'messages',
                name: 'Messages',
                component: Messages,
                meta: {
                    authenticated: true
                },
                beforeEnter: validatePrivateRoute,

            },
            {
                path: 'report',
                name: 'SeeReport',
                component: SeeReport,
                meta: {
                    authenticated: true
                },
                beforeEnter: validatePrivateRoute,
            },
            {
                path: 'info',
                name: 'YourInfo',
                component: YourInfo,
                meta: {
                    authenticated: true
                },
                beforeEnter: validatePrivateRoute,
            }
        ]
    }
];
