export const allPrivatePages = {
    caseStatus: {
        statusOfCaseNumber: 'Status on your case ID #',
        caseWillBeInitiated: 'Follow-up wil be initiated when you make contact via the phone number provided to you during the submission.',
        provideFeedback: 'Vi afventer din tilbagemelding',
        goToMessages: 'Go to messages',
        provideFeedbackTillDate: 'Feedback',
        provideConfirmationDate: 'Receipt',
    },
    messages: {
        title: 'Messages',
        nav: {
            sharedFiles: 'Shared files',
            allFiles: 'All files',
            filterFiles: 'Files',
            files: 'files',
            document: 'Documents',
            pictures: 'Images',
            video: 'Video',
            other: 'Other',
        },
        chat: {
            writeCommentHere: 'Write your comment here...',
            addToCase: 'Add to case',
        }
    },
    seeReport: {
        submitComments: 'Submit comments',
        approve: 'Agree',

        commentsOnReport: {
            title: 'Bemærkninger til din indberetning',
            comment: 'Skriv dine bemærkninger til indberetningen og øvrige oplysninger her…',
            send: 'Send',
        },
        approveDialog: {
            title: 'Approve the content of your case',
            description: 'Is the report described correctly? You can still send messages regarding the case under "Messages"',
            cancel: 'Cancel',
            confirm: 'Approve',
        }
    },
    yourInfo: {
        title: 'If you have not previously provided your identity, you have the opportunity to do this below',
        save: 'Save',
        cancel: 'Cancel',
        errorMessage: 'Given identity is not correct',
    },
    changePassword: {
        title: 'Change password',
        description: 'If you want to change your password, please press the ‘Change password’ button below:',
        changePassword: 'Change password',
        dialog: {
            title: 'Change your password to your case',
            whatShouldYouDo: 'You now need to do the following:',
            step1: 'Log out with the button below',
            step2: 'Log in to your case',
            step3: 'Enter your new password',
            descText1: 'For security reasons you need to log out to change your password.',
            descText2: 'Confirm that you wish to change your password and log out with the button below.',
            btnApprove: 'Confirm and log out',
        }
    },

};
