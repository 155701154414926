export const tooltips = {
    whyIsInfoNeeded: {
        title: 'Dlaczego ta informacja jest konieczna?',
        tooltipInfo: 'Information',
    },
    subsidiary: {
        text: 'Musimy wiedzieć, której firmy w grupie dotyczy Twoje zgłoszenie, abyśmy mogli jak najlepiej zbadać sprawę.'
    },
    whistleblowerRelation: {
        text: 'Z systemu sygnalistów może skorzystać wyłącznie grupa osób określona w polityce sygnalistów. Dlatego' +
            ' konieczne jest ustalenie swojego powiązania.'
    },
    describeProblem: {
        text: 'Abyśmy mogli dokładnie zbadać Twoje zgłoszenie, ważne jest, abyśmy mieli jak najwięcej i jak' +
            ' najbardziej szczegółowych informacji. Musisz zatem opisać, co zgłaszasz, kiedy to się stało, gdzie to' +
            ' się stało, kto jest zaangażowany, czy jest ktoś, kto może być świadkiem zdarzenia itp. Musisz również' +
            ' opisać swoją relację - na przykład, że jesteś pracownikiem, bliskim współpracownikiem lub podobnym.',
    },
    reportRelated: {
        text: 'Nasze dochodzenia w sprawie Twoich zgłoszeń mogą być ukierunkowane znacznie dokładniej, jeśli znamy' +
            ' tożsamość osoby lub osób wykonujących zgłoszone działania. Jeśli nie znasz tożsamości, przykładowo po' +
            ' prostu dlatego, że Twoje zgłoszenie dotyczy konkretnego działu, jest to również bardzo cenna dla nas' +
            ' wiedza do planowania naszego dochodzenia.'
    },
    documentUpload: {
        text: 'Jeśli posiadasz jakiekolwiek dowody w związku ze swoim zgłoszeniem, jest to bardzo cenne dla nas,' +
            ' gdy będziemy musieli zbadać Twoje zgłoszenie. Mogą to być zdjęcia, kopie dokumentów itp.',
    },
    identity: {
        text: 'Nasze możliwości zbadania Twojego zgłoszenia są zazwyczaj najlepsze, jeśli znamy Twoją tożsamość.' +
            ' Mamy nadzieję, że ujawnisz nam swoją tożsamość, lecz jest to dobrowolne i zbadamy Twoje zgłoszenie,' +
            ' niezależnie od tego, czy znamy Twoją tożsamość, czy nie.',
    }

};
