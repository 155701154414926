import {landingPage} from '@/language/de/publicPages/landingPage';
import {bookMeetingPage} from '@/language/de/publicPages/bookMeetingPage';
import {onlineFormPage} from '@/language/de/publicPages/onlineFormPage';
import {oralReportPage} from '@/language/de/publicPages/oralReportPage';
import {askQuestionsPage} from '@/language/de/publicPages/askQuestionsPage';
import {publicNavPages} from '@/language/de/components/publicNavPages';
import {misc} from '@/language/de/misc/misc';
import {report} from '@/language/de/components/report';
import {identity} from '@/language/de/components/identity';
import {step} from '@/language/de/components/stepForm';
import {privateNavPages} from '@/language/de/components/privateNavPages';
import {modal} from '@/language/de/misc/modal';
import {rules} from '@/language/de/misc/rules';
import {rulesViolated} from '@/language/de/model/rulesViolated';
import {allPrivatePages} from '@/language/de/privatePages/allPages';
import {tooltips} from '@/language/de/tooltips/tooltips';

export const de = {
    landingPage,
    bookMeetingPage,
    onlineFormPage,
    oralReportPage,
    askQuestionsPage,
    publicNavPages,
    misc,
    report,
    identity,
    modal,
    step,
    privateNavPages,
    rules,
    rulesViolated,
    allPrivatePages,
    tooltips,
};
