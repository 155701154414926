import i18n from '@/i18n';

export const fieldRequiredRules = [v => !!v || i18n.t('rules.input.fieldIsRequired')];

export const emailRequiredRules = [v => isValidEmail(v) || i18n.t('rules.input.email')];

export const fieldPhoneNumber = [v => isValidPhoneNumber(v) || i18n.t('rules.input.validPhoneNumber')];

export const contentShouldNotBeLonger = [v => (v || '').length <= 200 || i18n.t('rules.input.contentShouldNotBeLonger')];

export const passwordRules = [v => (v && v.length >= 4) || i18n.t('rules.input.min4Char')];

export const fieldPhoneNumberOrEmail = [
    v => (isValidPhoneNumber(v) || isValidEmail(v)) || i18n.t('rules.input.validPhoneNumberOrEmail'),
];


export const isValidPhoneNumber = (phoneNr) => !!phoneNr && phoneNr.length === 11 && phoneNr.startsWith('+45') && Number.isInteger(parseInt(phoneNr.slice(-8)));

export const isValidEmail = (email) => !!email && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

export const isValidEmailOrPhoneNr = (string) => isValidPhoneNumber(string) || isValidEmail(string);
