export const report = {
    reportDescription: {
        caseNumber: 'Sak nummer',
        attachIfIsNecessary: 'Vedlagt dokumentasjon',
        received: 'Mottatt',
        incidentReported: 'Beskrivelse av den rapporterte hendelsen',
        rulesViolated: 'Regler som er antatt brutt',
        isIncidentRepeated: ' Hendelsen er vedvarende eller gjentakende',
        yes: 'Ja',
        no: 'Nei',
    },
    reportRelate: {
        reportRelateTo: 'Personer/avdelinger tilknyttet rapporten',
        personsInCompany: 'Navngivne personer i virksomheden:',
        company: 'Avdeling',
    }
};
