export const misc = {
    buttons: {
        login: 'Logga in',
        logout: 'Logga ut',
    },
    fileInput: {
        dropFilesHere: 'Dra filen hit eller',
        browseFiles: 'Sök filer',
    },
    caseStatus: {
        draft: 'Utkast',
        received: 'Mottagen',
        beingInvestigated: 'Uppföljning',
        underCompletion: 'Avslutande skede',
        closed: 'Stängd',
    },
    caseStatusDesc: {
        draft: 'Utkast',
        received: 'Bekräftelse mottagen',
        initialAssessment: 'Inledande bedömning',
        followUp: 'Uppföljning',
        underCompletion: 'Avslutande skede',
        closed: 'Ärendet är avslutat',
    },
    fieldIsRequired: 'Obligatoriskt fält',

    errorPage: {
        404: {
            title: 'Sidan kunde inte hittas',
            takeMe: 'Till',
            home: 'startsidan',
        },
        somethingWentWrong: {
            title: 'Något gick fel',
            weAreWorkingOnIt: 'Vi arbetar med det',
            tryAgain: ' Försök igen ',
            later: 'senare ',
        },
    },
    language: 'Sprog',
};
