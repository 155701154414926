export const misc = {
    buttons: {
        login: 'Logg inn',
        logout: 'Logg ut'
    },
    fileInput: {
        dropFilesHere: 'Dropp filer her eller',
        browseFiles: 'Bla gjennom filer',
    },
    caseStatus: {
        received: 'Mottatt',
        beingInvestigated: 'Oppfølging',
        underCompletion: 'Sak fullført',
        closed: 'Lukket',
    },
    caseStatusDesc: {
        draft: 'Udkast',
        received: 'Bekræftelse modtaget',
        initialAssessment: 'Indledende vurdering',
        followUp: 'Opfølgning',
        underCompletion: 'Under afslutning',
        closed: 'Sagen er lukket',
    },
    fieldIsRequired: '* Obligatorisk',

    errorPage: {
        404: {
            title: 'Upss, page not found',
            takeMe: 'Take me',
            home: 'hjem',
        },
        somethingWentWrong: {
            title: 'Upss, something went wrong',
            weAreWorkingOnIt: 'We are working on it',
            tryAgain: 'Try again ',
            later: 'later',
        },
    },
    language: 'Language'
};
