export const privateNavPages = {
    status: {
        title: 'Status på din sag',
    },
    messages: {
        title: 'Beskeder',
    },
    yourReport: {
        title: 'Se din indberetning',
    },
    yourIdentity: {
        title: 'Dine oplysninger',
    },
};
