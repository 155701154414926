export const tooltips = {
    whyIsInfoNeeded: {
        title: 'Warum ist diese Angabe notwendig?',
        tooltipInfo: 'Information',
    },
    subsidiary: {
        text: 'Wir müssen wissen, auf welches Unternehmen der Gruppe sich Ihre Meldung bezieht, um die Angelegenheit bestmöglich prüfen zu können.'
    },
    whistleblowerRelation: {
        text: 'Das Hinweisgebersystem kann nur von dem in der Hinweisgeberrichtlinie genannten Personenkreis in ' +
            'Anspruch genommen werden. Daher ist es notwendig, Ihre Beziehung zu kennen.'
    },
    describeProblem: {
        text: 'Damit wir Ihrer Meldung sorgfältig nachgehen können, benötigen wir so viele und so detaillierte ' +
            'Informationen wie möglich. Sie sollten daher beschreiben, was Sie melden, wann es passiert ist, wo es ' +
            'passiert ist, wer beteiligt ist, ob es jemanden gibt, der den Vorfall bezeugen kann, usw.',
    },
    reportRelated: {
        text: 'Unsere Ermittlungen bezüglich Ihrer Meldung können viel gezielter durchgeführt werden, wenn wir die ' +
            'Identität der Person(en) kennen, die die gemeldeten Handlungen vorgenommen hat/haben. Wenn Sie die ' +
            'Identität nicht kennen, aber z. B. wissen, dass Ihre Meldung eine bestimmte Abteilung betrifft, ist auch' +
            ' dieses Wissen bei der Planung unserer Ermittlungen von großem Wert für uns.'
    },
    documentUpload: {
        text: 'Wenn Sie Beweise für Ihre Meldung haben, sind diese für uns und unsere Ermittlungen von großem Wert.' +
            ' Beweise können Fotos, Kopien von Dokumenten usw. sein.',
    },
    identity: {
        text: 'Unsere Möglichkeiten, Ihrer Meldung nachzugehen, sind im Allgemeinen am besten, wenn wir Ihre Identität' +
            ' kennen. Wir hoffen, dass Sie uns Ihre Identität mitteilen. Dies ist aber freiwillig, und wir werden Ihrer' +
            ' Meldung nachgehen, ganz gleich, ob wir Ihre Identität kennen oder nicht.',
    }

};
