<template>
  <div class="make-question-wrapper">
    <v-card
        class="mx-auto card-border-bottom"
        min-height="130"
        max-width="100%"
        rounded
        elevation="2"
    >
      <v-card
          elevation="0"
          min-height="70"
          color="primary"
          class="rounded-b-0 pt-2 pb-2 d-flex align-center card-inner-padding"
      >
        <div class="pa-2 pl-0">
          <span class="white--text font-size-20">{{ $t('step.makeQuestions.title') }}</span>
          <span class="white--text text-decoration-underline c-point font-size-20" @click="openInNewTab">
            {{ $t('step.makeQuestions.whistleblowing') }}
          </span>
        </div>
      </v-card>


      <div class="card-inner-padding">
        <div class="pb-4">
          <slot name="card-title"/>
        </div>
        <div class="mt-10">
          <v-card class="border-all-2" elevation="0">
            <div class="d-flex justify-center mt-n7">
              <v-card height="50" width="50" elevation="0" color="primary" rounded="pill"
                      class="d-flex justify-center align-center"
              >
                <v-icon color="white" size="30">mdi-phone-in-talk-outline</v-icon>
              </v-card>
            </div>
            <div class="ma-12 mb-16">
              <div class="primary--text text-decoration-underline text-center text-h4 mt-6 font-weight-black">
                {{ getContactPhoneText() }}
              </div>
              <div class="primary--text text-center mt-7 font-size-24">
                {{ getContactPersonText() }}
              </div>
              <div class="primary--text text-center font-size-18 mt-7">
                {{ getContactDetailsText() }}
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {isFalsy} from '@/utils/guards';

export default {
  name: 'StepMakeQuestion',
  computed: {
    ...mapGetters('lookAndFeel',['getContactPhone','getContactPerson','getContactDetails']),
  },
  methods: {
    openInNewTab() {
      let routeData = this.$router.resolve({name: 'PersonalData'});
      window.open(routeData.href, '_blank');
    },
    getContactPhoneText(){
      return isFalsy(this.getContactPhone) ? this.$t('step.makeQuestions.defaultPhoneNumber') : this.getContactPhone;
    },
    getContactPersonText(){
      return isFalsy(this.getContactPerson) ? this.$t('step.makeQuestions.defaultContactPerson') : this.getContactPerson;
    },
    getContactDetailsText() {
      return isFalsy(this.getContactDetails) ? this.$t('step.makeQuestions.defaultContactInfo') : this.getContactDetails;
    }
  }
};
</script>

<style scoped>
</style>
